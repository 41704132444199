import React from 'react';
import { Modal } from 'antd';
import { images_urls } from './NewRegister.utils';

import { MainNewRegister, OptionsSection, Option } from './NewRegister.style';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrrentDashboardPage, userSelector } from '../../../store/user/user.slice';

const NewRegister = ({ isVisible, setIsVisible, setViewWidget, viewWidget, setRegisterKind, setKindVisible }) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const handleRegisterKind = (medium) => {
    if(!user.curp || !user.phone) {
      Modal.error({
        title: 'Aún no tenemos tu información completa.',
        content: "Dirígete a la sección Perfil y actualiza tu CURP y/o teléfono",
        onOk() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage ('profile'));
        },
        onCancel() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage('profile'));
        }
      });
      return;
    }
    switch (medium) {
      case 'partner':
      case 'credit':
      case 'debit':
      case 'direct_debit':
      case 'unique_contribution':
        setRegisterKind(medium);
        setKindVisible(prevSate => ({
          ...prevSate,
          [medium]: true
        }));
        setIsVisible(false);
        break;
      default:
        setViewWidget(true);
        setIsVisible(false);
        break;
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    setViewWidget(false);
  };

  return (
    <MainNewRegister>
      <Modal
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <h2>Otros mecanismos de ahorro</h2>
        <OptionsSection >
          <Option
            onClick={() => handleRegisterKind('partner')}
          >
            <img alt="Registro tarjeta de socio" src={images_urls.tarjetasocio}/>
            <span>
              <h4>Tarjeta de socio</h4>
            </span>
          </Option>
          <Option
            onClick={() => handleRegisterKind('paybook')}
          >
            <img alt="Registro cuenta bancaria" src={images_urls.cuentabancaria}/>
            <span>
              <h4>Cuenta bancaria</h4>
            </span>
          </Option>
          <Option
            onClick={() => handleRegisterKind('direct_debit')}
          >
            <img alt="Registro domiciliación" src={images_urls.periodica}/>
            <span>
              <h4>Domiciliación</h4>
            </span>
          </Option>
          <Option
            onClick={() => handleRegisterKind('unique_contribution')}
          >
            <img alt="Registro aportación única" src={images_urls.unica}/>
            <span>
              <h4>Aportación única</h4>
            </span>
          </Option>
        </OptionsSection>
      </Modal>
    </MainNewRegister>
  );
};

export default NewRegister;
