import React, { useState, useEffect } from 'react';

// Local components
import { MainAuth, AuthPart, AuthPartTwo, Par } from './Auth.style';
import Login from './Login/Login';
import Signup from './Signup/Signup';

//External components
import { Button} from 'antd';
import LandingMenu from '../LandingPage/LandingMenu/LandingMenu';
import Buttons from '../Constants/Buttons';
export default function Auth(props) {
  const { landing } = props;
  const [page, setPage] = useState(false);

  const goToForm = () => setPage(!page);

  const checkPage = page ? <Signup goToForm={goToForm}/> : <Login />;

  useEffect(() => {
  }, [page]);

  return (
    <MainAuth landing={landing}>
    {
      page ?
        <>
          <AuthPart>
            <h1>Regístrate</h1>
            <div>
              <p>¿Ya tienes una cuenta?</p>
              <a onClick={goToForm}>INICIA SESIÓN</a>
            </div>
          </AuthPart>
        </>
        :
        <AuthPart>
          <h1>Inicia sesión</h1>
          <div>
            <p>¿Aún no tienes cuenta?</p>
            <a onClick={goToForm}>REGÍSTRATE</a>

          </div>
        </AuthPart>
      }
        <AuthPartTwo>
          { checkPage }
        </AuthPartTwo>
        <>
          <Par>o</Par>
          <Buttons />
        </>
    </MainAuth>

  );
};
