export const urls = {
  dashboard: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-inactive-dashboard.svg',
  ahorro: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-inactive-ahorro.svg',
  proyeccion: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-inactive-proyeccion.svg',
  perfil: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-inactive-perfil.svg',
  // next image will be changed to have the same format as the previous images
  temporary: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-inactive-statement.svg',
  logout: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon.logout.svg',
  selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon_selector.png',
  hamburger: 'images/hamburger_menu.png'
};
