import React, { useState } from 'react';
import { Input, InputNumber } from 'antd';
import { DarkBlue } from '../../../../../utilities/Colors';
import { ConfirmationContainer } from './PaymentConfirmation.style';

const PaymentConfirmation = ({ isSaveAmountDifferent, period, infoAboutSave, setAmountToPay }) => {

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return (
    <ConfirmationContainer>
      <p style={{fontSize: '1.35em'}}>Estás apunto de realizar el pago de tu ahorro correspondiente al mes de {period}. La cantidad que se destinará a tu ahorro para el retiro son <b>{formatter.format(infoAboutSave.amount)} MXN</b></p>
      {
        isSaveAmountDifferent &&
          <InputNumber
            placeholder="$0.00"
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={setAmountToPay}
          />
      }
    </ConfirmationContainer>
  );
};

export default PaymentConfirmation;
