import styled from "styled-components"
import { device } from '../utilities/media_queries'
import {DarkBlue, DarkGray} from '../utilities/Colors'

const ContactUsS = styled.section`
  font-family: 'IBM Plex Sans', sans-serif;
  width: 100%;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5%;
  padding: 4% 0 0 0;
  z-index:0;
  &>h1{
    color:${DarkBlue};
    width: 50%;
    text-align:center;
    font-weight: 700;
    font-size:3rem; 
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
        font-size:1.5rem;
      }
    }
  &>h3{
    color:${DarkBlue};
    width: 80%;
    text-align:center;
    font-weight:400;
  }
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
    margin-bottom: 6%;
    padding-top: 55px; 
    margin-top: -55px;
    &>div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin:1%;
    font-size: 1rem;
    padding: 2%;
    width: 80%;
    &>img{
      width:25%;
      margin: 0 2.5%;
    }
    &>form{
      width: 90%;
      margin: 0 5%;
      &>p{
        font-size: 0.9rem;
        margin: 7% 0%;
      }
      &>input{
        font-size: 0.9rem;
        width: 100%;
      }
      &>textarea{
        width:100%;
      }
    }
    }
  }
  @media ${device.laptopL}{
    margin-bottom: 6%;
    padding-top: 65px; 
    margin-top: -35px;
    &>div{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
      font-size: 1rem;
      &>img{
        width:20%;
        margin: 0 2.5%;
      }
      &>form{
        width: 50%;
        &>p{
          font-size: 0.7rem;
          margin: 0%;
          color:${DarkBlue};
          font-weight:400;
          opacity: 0.7;
          margin: 1% 0;
        }
  
        &>input{
          font-size: 0.9rem;
          width: 100%;
        }
  
        &>textarea{
          width:100%;
        }
  
        &>input:nth-child(11){
          color: white;
          font-weight: bold;
          margin-top: 5%;
          padding: 2%;
          border-style: none;
          background-color: #E63200;
          letter-spacing: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }

@media ${device.tablet},
  ${device.laptop}{
  &>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin:1%;
    font-size: 1rem;
    &>img{
      width:20%;
      margin: 0 2.5%;
    }
    &>form{
      width: 50%;
      &>p{
        font-size: 0.7rem;
        margin: 0%;
        color:${DarkBlue};
        font-weight:400;
        opacity: 0.7;
        margin: 1% 0;
      }

      &>input{
        font-size: 0.9rem;
        width: 100%;
      }

      &>textarea{
        width:100%;
      }

      &>input:nth-child(11){
        color: white;
        font-weight: bold;
        margin-top: 5%;
        padding: 2%;
        border-style: none;
        background-color: #E63200;
        letter-spacing: 0.5rem;
        cursor: pointer;
      }
    }
  }
}


`

export { ContactUsS }