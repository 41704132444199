import React, { useEffect } from 'react';
import { LandingGral, WhatSection, WellnessContainer, Disclaimer } from './LandingPage.style';
import Why from './WhyAurum/Why';
import HowWorks from './LandingMenu/HowItWorks/HowWorks';
import LandingAuth from './AuthSec/AuthSec';
import ContactUs from '../temp/ContactUs';
import LogInto from './LandingMenu/LogInto/LogInto';

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LandingGral>
        <LandingAuth/>
        <WellnessContainer>
          <WhatSection>
            <h1>QUÉ ES INKOM</h1>
            <p>Inkom es un servicio digital de aportaciones automáticas a la cuenta
              de retiro a través de compras con tarjetas bancarias departamentales.
              Ahorra fácil sin afectar tu presupuesto, con beneficios tangibles en un sistema seguro.</p>
          </WhatSection>
        </WellnessContainer>
        <LogInto/>
        <>
          <Why/>
        </>
        <>
          <HowWorks />
        </>
        <>
          <ContactUs />
        </>
      </LandingGral>
    </>
  );
};

export default LandingPage;