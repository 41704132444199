import axios from 'axios';
import qs from 'qs';
import { cifrarAES, decifrarAES } from './Encryptor';

export const generateEncryptedPaymentLink = async ({
  id,
  ownerId,
  referenceDate,
  mitOperationId,
  paymentId,
  amount,
  mail,
  phone,
  statement,
  userId
}) => {
  const { period } = statement;

  const makeId = (contributionId) => {
    let normalizedId = contributionId.replaceAll('-', '');
    return normalizedId;
  };
  
  const xmlTextTemplate = `<?xml version="1.0" encoding="UTF-8"?>
  <P>
  <business>
  <id_company>${process.env.REACT_APP_MIT_COMPANY}</id_company>
  <id_branch>${process.env.REACT_APP_MIT_BRANCH}</id_branch>
  <user>${process.env.REACT_APP_MIT_USER}</user>
  <pwd>${process.env.REACT_APP_MIT_PASS}</pwd>
  </business>
  <url>
  <reference display="false">${makeId(id)}</reference>
  <amount>${Number(amount).toFixed(2)}</amount>
  <moneda>MXN</moneda>
  <canal>W</canal>
  <omitir_notif_default>0</omitir_notif_default>
  <st_correo>1</st_correo>
  <mail_cliente>${mail}</mail_cliente>
  <st_cr>M</st_cr>
  <data3ds>
  <ml>${mail}</ml>
  <cl>${phone}</cl>
  </data3ds>
  <datos_adicionales>
  <data id="1" display="false">
  <label>PRINCIPAL</label>
  <value>${userId}</value>
  </data>
  <data id="2" display="true">
  <label>Periodo</label>
  <value>${period}</value>
  </data>
  <data id="3" display="true">
  <label>Concepto</label>
  <value>Ahorro para mi retiro</value>
  </data>
  <data id="4" display="false">
  <label>contributionId</label>
  <value>${id}</value>
  </data>
  <data id="5" display="false">
  <label>mitOperationId</label>
  <value>${mitOperationId}</value>
  </data>
  <data id="6" display="false">
  <label>paymentId</label>
  <value>${paymentId}</value>
  </data>
  </datos_adicionales>
  <version>IntegraWPP</version>
  </url>
  </P>`;
  
  const cifrado = cifrarAES(xmlTextTemplate, process.env.REACT_APP_MIT_KEY)

  const data = qs.stringify({
    'xml': `<pgs><data0>${process.env.REACT_APP_MIT_DATA0}</data0><data>${cifrado}</data></pgs>`
  });

  let parser = new DOMParser();
  const url = 'https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/create-mit-paymentlink';

  return axios.post(url, JSON.stringify({ cifrado: data }))
  .then(response => {
    const decifrado = decifrarAES(response.data, process.env.REACT_APP_MIT_KEY);
    const xmlResponse = parser.parseFromString(decifrado, 'text/xml');
    const paymentURL = xmlResponse.getElementsByTagName("nb_url")[0].childNodes[0].nodeValue;
    return {
      rawDataRequest: cifrado,
      url: paymentURL.toString()
    };
  })
  .catch(err => console.log({err}))
};
