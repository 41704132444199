// External imports
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import { EditOutlined, CreditCardOutlined, FilePdfOutlined, BankOutlined, FileDoneOutlined, RollbackOutlined } from '@ant-design/icons';
import axios from 'axios';
import { saveAs } from 'file-saver';
// Local imports
import { NextPayment, InfoNext } from './NextPayments.style';
import { returnMonthsNameByDate } from '../../../../utilities/datesHandler';
import AccountStatement from '../AccountStatement/AccountStatement';
import { generateEncryptedPaymentLink } from '../../../../../API/MIT';
import PaymentConfirmation from './PaymentConfirmation';
import { setAppLoading, setAppNotLoading } from '../../../../../store/app/app.actions';
import { DarkBlue, Yellow } from '../../../../utilities/Colors';
import { createContribution, updateContribution } from '../../../../../store/contributions/contributions.slice';
import { createMitOperation, updateMitOperation } from '../../../../../store/mitOperations/mitOperations.slice';
import { getPaymentById, updatePayment } from '../../../../../store/payments/payments.slice';
import PaymentDetails from '../PaymentDetails.js';

const NextPayments = ({ user, payments, setAppIsLoading, setAppIsNotLoading }) => {
  const dispatch = useDispatch();
  const [ visible, setVisible ] = useState(false);
  const [ timestamps, setTimestamps ] = useState(null);
  const [ generalStatements, setGeneralStatements ] = useState([]);
  const [ infoAboutSave, setInfoAboutSave ] = useState({});
  const [ amountToPay, setAmountToPay ] = useState(null);
  const [ amountConfirmed, setAmountConfirmed ] = useState(false);
  const [ modalData, setModalData ] = useState(null);
  const [ todayDateInfo, setTodayDateInfo ] = useState(null);
  const [ modalContent, setModalContent ] = useState();
  const [ isSaveAmountDifferent, setIsSaveAmountDifferent ] = useState(false);

  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModalLink, setPaymentModalLink] = useState(null);

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    if(!timestamps){
      setTimestamps(new Date());
      setTodayDateInfo({
        day: new Date().getDate(),
        month: new Date().getMonth(), //october-9
        year: new Date().getFullYear()
      })
    }else if(userInfo.flowCompleted) {
      let dataGet = async () => {
      };
      dataGet();
    } else {
      return;
    }
  }, [timestamps]);

  useEffect(() => {
    setGeneralStatements(() => payments.sort((a,b) => a.period - b.period))
  }, [payments])

  const handleCancelPayment = async () => {
    setModalContent('statement')
    setAmountToPay(null)
    setAmountConfirmed(null)
    setPaymentModalLink(null)
    openPaymentModal(false);
  };

  const viewPaymentDetails = async () => {
    setModalContent('paymentDetails');
  };

  const confirmStatementPayment = async () => {
    setAppIsLoading();
    let lastAmountConfirmed = amountToPay ? amountToPay : infoAboutSave.amount;
    let input = {
      goal: 'retiro',
      scheduled: false,
      periodicity: 'NA',
      referenceDate: {
        kind: (new Date()).toISOString(),
        dateData: (new Date()).toISOString(),
      },
      ammount: lastAmountConfirmed,
      type: "buyings",
      ownerId: user.id,
    };
    let createdContribution = await dispatch(createContribution(input))
    await dispatch(updatePayment({
      id: modalData.id,
      contributionId: createdContribution.payload.id
    }))
    let mitOperationInput = {
      ownerId: user.id,
      contributionId: createdContribution.payload.id,
      mitOperationStatus: '1',
      paymentId: modalData.id,
      reference: createdContribution.payload.id.replaceAll('-', '')
    }
    let createdMitOperation = await dispatch(createMitOperation(mitOperationInput))
    try {
      let paymentLinkToFrame = await generateEncryptedPaymentLink({
        ...createdContribution.payload,
        mitOperationId: createdMitOperation.payload.id,
        paymentId: modalData.id,
        amount: Number(lastAmountConfirmed).toFixed(2),
        mail: user.email,
        phone: user.phone ? user.phone: '5511223344',
        statement: {
          period: returnMonthsNameByDate(Number(modalData.period))
        },
        userId: user.id
      });
      await dispatch(updateContribution({
        id: createdContribution.payload.id,
        mitLink: paymentLinkToFrame.url
      }))
      await dispatch(updateMitOperation({
        id: createdMitOperation.payload.id,
        rawDataRequest: paymentLinkToFrame.rawDataRequest
      }))
      setPaymentModalLink(paymentLinkToFrame.url);
      setAmountConfirmed(true);
      setAmountToPay(false)
      setIsSaveAmountDifferent(false)
      setAppIsNotLoading();
    } catch(error) {
      console.log({error});
      setAmountToPay(false)
      setIsSaveAmountDifferent(false)
      setAppIsNotLoading();
    }
  };

  const startPaymentFlow = async () => {
    setAppIsLoading()
    let info = modalData

    setInfoAboutSave(info)
    try {
      setAppIsNotLoading();
      setModalContent('paymentConfirmation')
      openPaymentModal(true);
    } catch(error) {
      console.log({errorWhileMakingMITPayment: error});
      setAppIsNotLoading();
    }
  }

  const openPaymentModal = (status) => {
    setPaymentModal(status);
  }

  const currencyFormat = data => {
    return `$ ${data}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  };

  const createAndGetPdfStatement = async () => {
    setAppIsLoading()
    const url = 'https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum';
    // const url = 'http://localhost:3001/dev/aurum';
    let info =  {
      accountsResume: {
        ...JSON.parse(modalData.federatedOwner)
      },
      monthName: returnMonthsNameByDate(Number(modalData.period))
    }
    const { accountsResume, monthName } = info
    let registersList = [];
    for(const index in accountsResume) {
      let input = {
        accIdentificaion: accountsResume[index].accName,
        accOrg: accountsResume[index].accOrg,
        accType: accountsResume[index].accType,
        ammountToSave: accountsResume[index].ammountToSave,
        chargesSum: accountsResume[index].chargesSum,
        maxAmount: accountsResume[index].maxAmount,
        percentage: accountsResume[index].percentage
      };
      registersList.push(input);
    };
    let body = {
      client_data: {
        name: user.email,
      },
      accounts_data: registersList,
      amountPayed: modalData.amountPayed ? currencyFormat((modalData.amountPayed).toFixed(2)) : null,
      monthName
    };

    try {
      const response_fetch = await axios.post(`${url}/create-statement-pdf`, {infoString: body})
      let buffer = Buffer.from(response_fetch.data);
      let arraybuffer = Uint8Array.from(buffer).buffer;
      const pdfBlob = await new Blob([arraybuffer], { type: 'application/pdf' });
      await saveAs(pdfBlob, `${monthName}.pdf`)
      setAppIsNotLoading();
    } catch(err) {
      setAppIsNotLoading();
    } finally {
      setAppIsNotLoading();
    }
  };

  const handleCancel = async () => {
    if(modalData) {
      await dispatch(getPaymentById(modalData.id))
    }
    setModalData(null)
    setModalContent(null)
    setAmountToPay(false)
    setIsSaveAmountDifferent(false)
    setVisible(false);
  }

  const handleOk = () => {
    setModalData(null)
    setModalContent(null)
    setVisible(false)
  };

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  const handlePaymentStatementModal = async (statement) => {
    setModalData(statement)
    setModalContent("statement")
    setTimeout(() => {
      setVisible(true)
    }, 500)
  }

  return (
    <NextPayment>
      {
        todayDateInfo ?
        <>
        {
          generalStatements.map((statement, i) => {
            return (
              <InfoNext key={i} payed={statement.payed}>
                {/* HOMEWORK */}
                {/* REMEMBER AFTER ADDING NEW DATA TO PAYMENT SCHEMA CHANGE HERE THE YEAR */}
                <p style={{fontSize: ".9rem", color:"#103D56", margin:"2% 25%"}}>{returnMonthsNameByDate(Number(statement.period))} 2021</p>
                <p style={{fontSize: "1.3rem", color:"#103D56", fontWeight:"900", padding:"1%"}}>{statement.amount ? formatter.format(statement.amount.toFixed(2)) : "Calculando"}</p>
                <Button style={{border:"none",color: `${statement.payed ? '#103D56' : '#FFCE00'}`, fontWeight:"600", backgroundColor: `${statement.payed ? '#c0f2bb' :  'white'}` }} onClick={() => handlePaymentStatementModal(statement)}>{
                  statement.payed ? 'VER DETALLE' : 'IR A PAGAR'
                  }</Button>
              </InfoNext>
            )
          }
          )
        }
        </> :
        null
      }
      {
        paymentModal && paymentModalLink && amountConfirmed ?
        <Modal
          title="Pago"
          visible={paymentModal && paymentModalLink}
          bodyStyle={{height: '70vh'}}
          footer={[]}
          onCancel={handleCancelPayment}
        >
          <iframe title="MIT" src={paymentModalLink} frameBorder="0" seamless="seamless" style={{width:'100%',height:'100%'}}/>
        </Modal> : null
      }
      {
        !modalData ?
          null :
          <Modal
            title={modalContent === 'paymentConfirmation' ? 'Confirmar monto a ahorrar' : 'Estado de cuenta'}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
                modalContent === 'statement' && !modalData.payed ?
                [
                  <Button
                    shape="round" icon={<FilePdfOutlined />} size="default"
                    style={{backgroundColor: DarkBlue, color: Yellow }}
                    onClick={createAndGetPdfStatement}
                  >
                    Descargar estado de cuenta
                  </Button>,
                  <Button
                    shape="round" icon={<BankOutlined />} size="default"
                    style={{backgroundColor: Yellow, color: DarkBlue }}
                    onClick={startPaymentFlow}
                  >
                    Realizar pago
                  </Button>
                ]
                :
                  modalContent === 'paymentConfirmation' ?
                  [
                    <Button
                      shape="round" icon={<EditOutlined />} size="default"
                      style={{backgroundColor: DarkBlue, color: Yellow }}
                      onClick={() => setIsSaveAmountDifferent(true)}>Deseo cambiar el monto</Button>,
                    <Button
                      shape="round" icon={<CreditCardOutlined />} size="default"
                      style={{backgroundColor: Yellow, color: DarkBlue, height: 'auto',whiteSpace: "normal" }}
                      onClick={confirmStatementPayment}
                    >
                      Deseo ahorrar la cantidad de {amountToPay ? formatter.format(amountToPay) : formatter.format(infoAboutSave.amount) } MXN
                    </Button>
                  ]
                : modalContent === 'statement' && modalData.payed ?  (
                  [
                    <Button
                      shape="round" icon={<FilePdfOutlined />} size="default"
                      style={{backgroundColor: DarkBlue, color: Yellow }}
                      onClick={createAndGetPdfStatement}
                    >
                      Descargar estado de cuenta
                    </Button>,
                    <Button
                      shape="round" icon={<FileDoneOutlined />} size="default"
                      style={{backgroundColor: Yellow, color: DarkBlue, height: 'auto',whiteSpace: "normal" }}
                      onClick={viewPaymentDetails}
                    >
                      Revisar detalle
                    </Button>
                  ]
                ) :
                [
                    <Button
                      shape="round" icon={<RollbackOutlined />} size="default"
                      style={{backgroundColor: DarkBlue, color: Yellow }}
                      onClick={() => setModalContent('statement')}
                    >
                      Atrás
                    </Button>
                  ]
            }
            bodyStyle={{FontFamily:'IBM Plex Sans', FontSize:"1rem"}}
            width={"50%"}
          >
          {
            modalContent === 'statement' ?
            <>
              <h2>Correspondiente al mes de {returnMonthsNameByDate(Number(modalData.period))}</h2>
              <p style={{fontFamily:'IBM Plex Sans'}}>Información de estado de cuenta</p>
              { modalData && (<AccountStatement data={{accountsResume: { ...JSON.parse(modalData.federatedOwner) }, monthName: returnMonthsNameByDate(Number(modalData.period)), paymentData: modalData }}/> )}
            </>
            :
            modalContent === 'paymentConfirmation' && infoAboutSave ?
            <PaymentConfirmation
              isSaveAmountDifferent={isSaveAmountDifferent}
              period={returnMonthsNameByDate(Number(modalData.period))}
              amountToPay={amountToPay}
              setAmountToPay={setAmountToPay}
              infoAboutSave={infoAboutSave}
            />
            : modalContent === 'paymentDetails' ?
            <>
              <h2>Detalle correspondiente al mes de {returnMonthsNameByDate(Number(modalData.period))}</h2>
              {/* <p style={{fontFamily:'IBM Plex Sans'}}>Información de estado de cuenta</p> */}
              { modalData && (<PaymentDetails  data={{accountsResume: { ...JSON.parse(modalData.federatedOwner) }, monthName: returnMonthsNameByDate(Number(modalData.period)), paymentData: modalData }}/> )}
            </>
            : null
            }
        </Modal>
      }
    </NextPayment>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect (mapStateToProps, mapDispatchToProps)(NextPayments);
