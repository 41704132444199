import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
const columns = [
  {
    title: 'DESCRIPCIÓN',
    // width: 100,
    dataIndex: 'descripcion',
    key: 'descripcion',
    fixed: 'left',
    responsive: ['md'],
  },
  {
    title: 'MONTO',
    // width: 50,
    dataIndex: 'monto',
    key: 'monto',
    fixed: 'left',
    responsive: ['md'],
  },
  {
    title: 'CONCEPTO',
    dataIndex: 'concepto',
    key: 'concepto',
    // width: 150,
    responsive: ['md'],
  },
  {
    title: 'CUENTA ID',
    dataIndex: 'cuenta',
    key: 'cuenta',
    // width: 150,
    responsive: ['md'],
  },
  {
    title: 'FECHA',
    dataIndex: 'fecha',
    key: 'fecha',
    // width: 100,
    fixed: 'right',
    responsive: ['md'],
  }
];

const TransactionsList = ({ transactions }) =>  {
  const [transactionsToDisplay, setTransactionsToDisplay] = useState(null);

  useEffect(() => {
    if(transactions) {
      handleTransactions(transactions)
    }
  }, [transactions])

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  const handleTransactions = (transactionsList) => {
    const data = [];
    const sortedTransactions = transactionsList.sort((a, b) => a.dt_transaction - b.dt_transaction)
    for (const element of sortedTransactions) {
      let date = new Date(element.dt_transaction * 1000);
      date = (date.toISOString().split('.00')[0]).split('T').join(' - ') + 'hrs'
    // for (let i = 0; i < transactionsToDisplay.length; i++) {
      data.push({
        descripcion: element.description,
        monto: formatter.format(element.amount),
        concepto: element.extra.concept,
        cuenta: element.accountId,
        fecha: date,
      });
    }
    setTransactionsToDisplay(data)
  };

  return (
    <>
    {
      transactionsToDisplay && transactionsToDisplay.length > 0 ?
        <Table columns={columns} dataSource={transactionsToDisplay} scroll={{ y: 300 }} /> :
        'NO HAY MOVIMIENTOS REGISTRADOS PARA ESTA CUENTA'
    }
    </>
  )
}

export default TransactionsList;
