import { API, graphqlOperation } from 'aws-amplify';
import { getMyTransactions } from '../../../graphql/customqueries';
import { createTransaction, updateTransaction } from '../../../graphql/mutations';
import { getTransaction } from '../../../graphql/queries';

export const createNewTransaction = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createTransaction, {input}));
  return rawResponse.data.createTransaction;
};

export const getSpecificTransaction = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getTransaction, {id}));
  return rawResponse.data.getTransaction;
};

export const updateExistentTransaction = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updateTransaction, {input}));
  return rawResponse.data.updateTransaction;
};

export const listTransactions = async id => {
  try {
    let rawResponse = [];
    const getData = async (token = null) => {
      let apiResponse;
      apiResponse = await API.graphql(graphqlOperation(getMyTransactions, {ownerId: id, nextToken: token}));
      rawResponse.push(...apiResponse.data.listTransactions.items);
      let { nextToken } = apiResponse.data.listTransactions
      if(nextToken) {
        await getData(nextToken)
      }
      return
    }
    await getData()
    return rawResponse;
  } catch (error) {
    console.log({error})
  }
};
