import axios from 'axios';
import { createNewError } from '../middlewares/API/errors/errors.api';

export const getAccounts = async (id_user) => {
  return await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/get-sync-accounts', JSON.stringify({ id_user }))
  .then(async r => {
    if (r.data.code === 200 && r.data.response.length === 0) {
      await createNewError({
        ownerId: id_user,
        origin: 'getAccountsSync',
        dependency: 'sync',
        data: JSON.stringify(r)
      });
    }
    return r
  })
  .catch( async e => {
    await createNewError({
      ownerId: id_user,
      origin: 'getAccountsSync',
      dependency: 'sync',
      data: JSON.stringify(e)
    })
    return e.response
  })
};

export const getAccountById = async (id_account, id_user) => {
  return await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/get-sync-account-by-id', JSON.stringify({ id_user, id_account }))
  .then(r => r)
  .catch(async err => {
    await createNewError({
      ownerId: id_user,
      origin: 'getAccountByIdCredentialSync',
      dependency: 'sync',
      data: JSON.stringify(err)
    })
    return err.response
  })
};

export const createSyncUser = async (dbUser) => {
  const { email, id_ext } = dbUser
  return await axios.post("https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/create-sync-user", JSON.stringify({ email, id_ext }))
  .then(r =>  r)
  .catch(e => e.response)
};

export const initUserSession = async (id_user) => {
  const response = await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/init-sync-session', JSON.stringify({ id_user }));
  return response;
};

export const getTransactions = async (id_user, id_account, dt_from = null) => {
  return await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/get-sync-transactions', JSON.stringify({ id_user, id_account, dt_from }))
  .then(r => {
    return r
  })
  .catch( async e => {
    console.log({e})
    await createNewError({
      ownerId: id_user,
      origin: 'getTransactions',
      dependency: 'sync',
      data: JSON.stringify(e)
    })
    return e.response
  })
};

export const getCredentialStatus = async (id_user) => {
  return await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/get-sync-credentials-status', JSON.stringify({ id_user }))
  .then(r => r)
  .catch( async e => {
    console.log({e})
    await createNewError({
      ownerId: id_user,
      origin: 'getCredentialStatus',
      dependency: 'sync',
      data: JSON.stringify(e)
    })
    return e.response
  })
};

export const getSiteOrganization = async (id_site_organization) => {
  return await axios.post('https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/get-sync-site-organization', JSON.stringify({ id_site_organization }))
  .then(r => {
    return r
  })
  .catch( async e => {
    console.log({e})
    await createNewError({
      ownerId: id_site_organization,
      origin: 'getSiteOrganization',
      dependency: 'sync',
      data: JSON.stringify(e)
    })
    return e.response
  })
};