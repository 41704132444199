import React, { useState, useEffect } from 'react';
import { Modal, Button, Tooltip, Popconfirm } from 'antd';
import {EllipsisOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {SavingGeneral, Accounts, PartnerSection, BankCard, DomiciledCard} from './BuyingSaving.style';
import InstitutionList from '../../../layout/InstitutionList/InstitutionList';
import NewRegister from '../../NewRegister/NewRegister';
import AccountModal from '../../../layout/AccountModal/AccountModal';

import { getImageURL, ccFormat, toCurrency } from './BuyingSaving.utils';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrrentDashboardPage, userSelector } from '../../../../store/user/user.slice';
import { selectAllAccounts, updateAccount as updateThisAccount } from '../../../../store/accounts/accounts.slice'
import { selectAllCards, updateCard } from '../../../../store/cards/cards.slice'
import { selectAllContributions, uniqueScheduledContribution, deleteContribution } from '../../../../store/contributions/contributions.slice'

const BuyingSaving = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const accounts = useSelector(selectAllAccounts);

  const cards = useSelector(selectAllCards);
  const contributions = useSelector(selectAllContributions);
  const uniqueScheduled = useSelector(uniqueScheduledContribution);

  const [userAccounts, setUserAccounts] = useState(null);
  const [userUniqueContribution, setUserUniqueContribution] = useState(null);
  const [isRegisterVisible, setIsRegisterVisible] = useState(false);
  const [kindVisible, setKindVisible] = useState({
    partner: false
  });
  const [modalState, setModalState] = useState(null);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [triedWhileNotAllowed, setTriedWhileNotAllowed] = useState(false);

  const [viewWidget, setViewWidget] = useState(false);
  const [registerKind, setRegisterKind] = useState(null);
  const [cardData, setCardData] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState({});
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState({number:null});


  useEffect( () => {
    setUserAccounts([...cards, ...accounts]);
    setUserUniqueContribution(uniqueScheduled)
  }, [cards, accounts, contributions, uniqueScheduled])

  useEffect( () => {
    console.log('HasBeenUpdated')
  }, [hasBeenUpdated, viewWidget])

  useEffect(() => {
    handleProhibitedConfig()
  }, [hasBeenUpdated]);

  useEffect(() => {
    onChangeVisibility()
  }, [kindVisible]);

  useEffect(() => {
    reviewIfInformationIsComplete()
  }, []);

  const showPopconfirm = (account) => {
    setAccountToDelete(account);
    setIsDeletePopupOpen(prevState => ({
      [account.id]: !prevState[account.id]
    }));
  };

  const handleOkDelete = async (accountType) => {
    setConfirmLoading(true);
    try {
      let input = {
        id: accountToDelete.id,
        hidden: true
      }
      if (accountType === 'bank') {
        await dispatch(updateThisAccount(input))
      } else {
        await dispatch(updateCard(input))
      }
      setConfirmLoading(false);
      setIsDeletePopupOpen({});
    } catch(error) {
      setIsDeletePopupOpen({});
      setConfirmLoading(false);
    }
  };

  const handleUniqueContributionOkDelete = async () => {
    setConfirmLoading(true);
    try {
      let input = {
        id: accountToDelete.id
      }
      await dispatch(deleteContribution(input))
      setConfirmLoading(false);
      setIsDeletePopupOpen({});
    } catch(error) {
      setIsDeletePopupOpen({});
      setConfirmLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setIsDeletePopupOpen({});
  };

  const handleOk = e => {
    Modal.destroyAll();
    setIsEditModalVisible(false);
    setKindVisible(prevState => ({
      ...prevState,
      partner: false
    }));
    setHasBeenUpdated(prevState => !prevState)
  };

  const onChangeVisibility = () => {
    const isVisible = (
      kindVisible.partner ||
      kindVisible.debit ||
      kindVisible.credit ||
      kindVisible.direct_debit ||
      kindVisible.unique_contribution
    )
    setIsEditModalVisible(isVisible);
};

  const handleProhibitedConfig = e => {
    setTriedWhileNotAllowed(true);
    hideTooltip()
    setTimeout(() => {
      clearTimeout(hideTooltip)
    }, 500)
  };

  let hideTooltip = () => setTimeout(() => {
    setTriedWhileNotAllowed(false);
  }, 2000);


  const handleCancel = e => {
    Modal.destroyAll();
    setModalState(null);
    setIsEditModalVisible(false);
    setKindVisible({[kindVisible]: true})
  };

  const updateAccount = (account, kind, state='null') => {
    setRegisterKind(kind);
    setModalState('update');
    setCardData(account);
    setKindVisible({[kind]: true});
  };

  const getFourLastDigits = (accountNumber) => {
    if (accountNumber) {
      return accountNumber.slice(accountNumber.length - 4)
    }
  }

  const reviewIfInformationIsComplete = () => {
    const { name, lastname1, lastname2 } = user;
    if (!name || !lastname1 || !lastname2) {
      Modal.error({
        title: 'Aún no tenemos tu información completa.',
        content: 'Dirígete a la sección Perfil y actualiza tu nombre completo',
        onOk() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage('profile'));
        },
        onCancel() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage('profile'));
        },
      });
    }
  }

  return (
    <SavingGeneral>
      <div>
        <h1>MI AHORRO</h1>
        <Button
          type="primary"
          id="#new-account"
          onClick={() => setIsRegisterVisible(true)}
          style={{
            backgroundColor:"#FFC200",
            fontFamily: 'IBM Plex Sans',
            borderColor:"#FFC200",
            color:"#103D56",
            fontSize:"12px",
            fontWeight:"600",
            justifyContent:"center",
            alignContent: "center"
          }}
        >
          AGREGAR AHORRO
        </Button>
      </div>
      <h2>Tarjetas de socios</h2>
      { isEditModalVisible && <Modal
        title={modalState === 'update' ? 'Editar cuenta' : registerKind === 'debit' ? "Agregar cuenta de debito" : registerKind === "credit" ? "Agregar cuenta de crédito" : registerKind === "unique_contribution" ? "Agregar aportación única" : registerKind === "direct_debit" ? "Agregar pago domiciliado" : "Agregar cuenta de socios"}
        visible={isEditModalVisible}
        style={{height: "auto"}}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AccountModal
          registerKind={registerKind}
          handleOk={handleOk}
          cardData={cardData}
          setCardData={setCardData}
          setUserAccounts={setUserAccounts}
          style={{margin: "1% 0"}}
          onOk={handleOk}
          onCancel={handleCancel}
          modalState={modalState}
          setHasBeenUpdated={setHasBeenUpdated}
        />
      </Modal>
      }
      <NewRegister
        isVisible={isRegisterVisible}
        setIsVisible={setIsRegisterVisible}
        setKindVisible={setKindVisible}
        setViewWidget={setViewWidget}
        viewWidget={viewWidget}
        setRegisterKind={setRegisterKind}
      />
    {
      userAccounts ?
      <Accounts>
      {
      userAccounts.map(acc => {
        if(
          acc.site?.organization === 'Liverpool' ||
          acc.type === 'partner') {
          return (
            <PartnerSection key={acc.id} style={acc.hidden ? {display: 'none'} : {}}>
              <div>
                <img src={acc.site?.organization === 'Liverpool' ? getImageURL('liverpool') : getImageURL(acc.issuingEntity)} alt={acc.issuingEntity}/>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Tooltip placement="right" title="Editar cuenta"><h2 onClick={() => updateAccount(acc, 'partner')}><EllipsisOutlined /></h2></Tooltip>
                  <Popconfirm
                    title={`¿Estás seguro de eliminar la cuenta terminación ${getFourLastDigits(accountToDelete.number)}?`}
                    visible={
                      isDeletePopupOpen[acc.id] ?
                      isDeletePopupOpen[acc.id] :
                      false
                    }
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => handleOkDelete('partner')}
                    okText='Borrar'
                    cancelText='Cancelar'
                    okButtonProps={{
                      loading: confirmLoading,
                      style: {
                        backgroundColor: 'red'
                      }
                    }}
                    onCancel={handleCancelDelete}
                  >
                  <Tooltip placement="right" title="Eliminar cuenta"><h2 onClick={() => showPopconfirm(acc)}><DeleteOutlined /></h2></Tooltip>
                  </Popconfirm>
                </div>
              </div>
              <div>
                <h3>Número de tarjeta</h3>
                <p>{ ccFormat(acc.number) }</p>
              </div>
              <div>
                <h3>Periodicidad</h3>
                <p>{ acc.periodicity ? acc.periodicity.toUpperCase() : 'Aún sin asignar'}</p>
              </div>
              <div>
                <h3>Monto límite por periodo</h3>
                <p>{ acc.maxAmount ? toCurrency(acc.maxAmount) : acc.maxAmmount ? toCurrency(acc.maxAmmount) : 'Aún sin asignar' }</p>
              </div>
              <div>
                <h3>Porcentaje de los cargos</h3>
                <p>{ acc.percentage ? `${ acc.percentage} %` : 'Aún sin asignar'}</p>
              </div>
            </PartnerSection>
          )}
        })
      }
      </Accounts>
      : <div>No has registrado tarjetas de socios. Ve a la opción AGREGAR AHORRO.</div>
    }
    <h2>Cuentas bancarias</h2>
    {
      userAccounts ?
      <Accounts>
      {
      userAccounts.map(acc => {
        if(
          acc.site?.organization !== 'Liverpool' &&
          (acc.account_type === 'Checking' ||
          acc.account_type === 'Credit Card')
        ) {
          return (
            <BankCard key={acc.id} style={acc.hidden ? {display: 'none'} : {}}>
              <div>
                <img src={getImageURL(acc.site.organization)} alt={acc.site.organization}/>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Tooltip placement="right" title="Editar cuenta"><h2 onClick={() => updateAccount(acc, 'credit')}><EllipsisOutlined /></h2></Tooltip>
                  <Popconfirm
                    title={`¿Estás seguro de eliminar la cuenta terminación ${getFourLastDigits(accountToDelete.number)}?`}
                    visible={
                      isDeletePopupOpen[acc.id] ?
                      isDeletePopupOpen[acc.id] :
                      false
                    }
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => handleOkDelete('bank')}
                    okText='Borrar'
                    cancelText='Cancelar'
                    okButtonProps={{
                      loading: confirmLoading,
                      style: {
                        backgroundColor: 'red'
                      }
                    }}
                    onCancel={handleCancelDelete}
                  >
                  <Tooltip placement="right" title="Eliminar cuenta"><h2 onClick={() => showPopconfirm(acc)}><DeleteOutlined /></h2></Tooltip>
                  </Popconfirm>
                </div>
              </div>
              <div>
                <h3>Número de tarjeta</h3>
                <p>{ ccFormat(acc.number) }</p>
              </div>
              <div>
                <h3>Periodicidad</h3>
                <p>{acc.periodicity ? acc.periodicity.toUpperCase() : 'Aún sin asignar'}</p>
              </div>
              <div>
                <h3>Monto límite por periodo</h3>
                <p>{ acc.maxAmount ? toCurrency(acc.maxAmount) : acc.maxAmmount ? toCurrency(acc.maxAmmount) : 'Aún sin asignar' }</p>
              </div>
              <div>
                <h3>Porcentaje de los cargos</h3>
                <p>{ acc.percentage ? `${ acc.percentage} %` : 'Aún sin asignar'}</p>
              </div>
            </BankCard>
          )}
        })
      }
        </Accounts>
        : <div>No has registrado cuentas. Ve a la opción AGREGAR AHORRO</div>
        }
        <h2>Domiciliado</h2>
        {
          userUniqueContribution && (
            <Accounts>
              <DomiciledCard>
                <div>
                  <img src="https://aurumresources.s3.amazonaws.com/NewStyles/panal-azul.svg"/>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Tooltip placement="right" title="Editar cuenta"><h2 onClick={() => updateAccount(userUniqueContribution, 'direct_debit')}><EllipsisOutlined /></h2></Tooltip>
                    <Popconfirm
                    title="¿Estás seguro de eliminar tu domiciliación?"
                    visible={
                      isDeletePopupOpen[userUniqueContribution.id] ?
                      isDeletePopupOpen[userUniqueContribution.id] :
                      false
                    }
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={handleUniqueContributionOkDelete}
                    okText='Borrar'
                    cancelText='Cancelar'
                    okButtonProps={{
                      loading: confirmLoading,
                      style: {
                        backgroundColor: 'red'
                      }
                    }}
                    onCancel={handleCancelDelete}
                  >
                    <Tooltip placement="right" title="Eliminar cuenta"><h2 onClick={() => showPopconfirm(userUniqueContribution)}><DeleteOutlined /></h2></Tooltip>
                  </Popconfirm>
                  </div>
                </div>
                <div>
                  <h3>Método</h3>
                  <p>General</p>
                </div>
                <div>
                  <h3>Cuenta</h3>
                  <p>{user.email}</p>
                </div>
                <div>
                  <h3>Monto</h3>
                  <p>{userUniqueContribution?.ammount ? toCurrency(userUniqueContribution?.ammount) : userUniqueContribution?.amount ? toCurrency(userUniqueContribution?.amount): ''}</p>
                </div>
                <div>
                  <h3>Periodicidad</h3>
                  <p>{ userUniqueContribution?.periodicity ? userUniqueContribution?.periodicity.toUpperCase() : ''}</p>
                </div>
                <div>
                  <h3>Plazo</h3>
                  <p>{userUniqueContribution?.goal ? userUniqueContribution?.goal.toUpperCase() : ''}</p>
                </div>
              </DomiciledCard>
            </Accounts>
          )
        }
        {
          viewWidget && (
              <InstitutionList
                setHasBeenUpdated={setHasBeenUpdated}
                setViewWidget={setViewWidget}
              />
          )
        }
    </SavingGeneral>
  )
};

export default BuyingSaving;
