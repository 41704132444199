import React from 'react';
import { Table } from 'antd';


const CardsTable = props => {
  let tableData = props.dataTable.map(element => {
    return {
      key: element.id,
      number: element.number,
      issuingEntity: element.issuingEntity,
      percentage: element.percentage,
      maxAmmount: element.maxAmmount,
      periodicity: element.periodicity
    }
  })

  const columns = [
    {
      title: 'Número',
      dataIndex: 'number',
    },
    {
      title: 'Entidad Emisora',
      dataIndex: 'issuingEntity',
      sorter: {
        compare: (a, b) => a.issuingEntity - b.issuingEntity,
        multiple: 3,
      },
    },
    {
      title: 'Porcentaje Gastos',
      dataIndex: 'percentage',
      sorter: {
        compare: (a, b) => a.percentage - b.percentage,
        multiple: 3,
      },
    },
    {
      title: 'Monto Máximo',
      dataIndex: 'maxAmmount',
      sorter: {
        compare: (a, b) => a.maxAmmount - b.maxAmmount,
        multiple: 2,
      },
    }
    // ,
    // {
    //   title: 'Información',
    //   dataIndex: 'periodicity',
    //   sorter: {
    //     compare: (a, b) => a.maxAmmount - b.maxAmmount,
    //     multiple: 2,
    //   },
    // }
  ];

  const handleChange = (pagination, filters, sorter, extra) => {
  }

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      onChange={handleChange}
      pagination={false}
      style={{
        marginBottom: '5%',
        width: '-webkit-fill-available',
        maxWidth: '90%'
      }}
    />
  );
};

export default CardsTable;
