import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import Navbar from './components/layout/Navbar/Navbar'
import Footer from './components/layout/Footer/Footer'
import Route from './Router'
import { initUserSession } from './API/Sync'

import { Auth, Hub } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import { useHistory, useLocation } from 'react-router-dom'

import { store, StateProvider } from './components/reducers/store';

import 'antd/dist/antd.css';
import Loader from './components/UI/Loader';
import { connect } from 'react-redux';
import { Disclaimer } from './components/LandingPage/LandingPage.style';
// TO BE USED IN A FEW SPRINTS -->
// import { observer } from './components/utilities/intersection-observer';

export const UserContext = React.createContext();

const App = ({ isLoading, user }) => {

  const globalState = useContext(store)
  const { state } = globalState;

  const [onDash, setOnDash] = useState(false);

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    headerView();
  }, [location]);

  useEffect(() => {
      const script = document.createElement('script')
      script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
      script.async = true
      document.body.appendChild(script)
    // getUserData();
    // const onHubCapsule = capsule => {
    //   switch(capsule.payload.event){
    //     case "signIn":
    //       getUserData();
    //       break;
    //     case 'signUp':
    //       break;
    //     case 'signOut':
    //       setUser(null)
    //       break;
    //     default:
    //       break;
    //   }
    // };

    // Hub.listen('auth', onHubCapsule);
    // if (!userData.token){
    //   initUserSession(userData.id)
    //   .then(response =>  )
    // }
  }, []);

  const getUserData = async () => {
    const userLogged = await Auth.currentAuthenticatedUser()
    userLogged ? console.log('user logged'): console.log(null)
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut()
      history.push("/")
    } catch(err) {
      alert("Hubo un error en la solicitud", err)
    }
  };

  const notNav = q => setOnDash(true)

  const headerView = () => {
    if(location.pathname === '/user-dashboard') {
      setOnDash(true);
    } else {
      setOnDash(false);
    }
  }

  return (
    <>
    <Loader/>
    {!onDash ?
    <UserContext.Provider value={{ user }}>
      <div style={isLoading ? {position: 'fixed'} : {}}>
        <Navbar user={user} signOut={handleSignOut}/>
        <StateProvider>
        <Route Authenticator={Authenticator} />
        </StateProvider>
        <Footer/>
      </div>
        <Disclaimer>
        La plataforma Inkom.mx (publicada el 17 de julio de 2021) en todos sus canales(sitio web, aplicación móvil y redes sociales) se encuentra en desarrollo y no está operativamente activa.
        Por tal motivo, las transacciones de terceros ajenos al equipo de Inkom no podrán ser procesadas.
        El uso de las plataformas está restringido a personal autorizado de Inkom y su explotación al ser de carácter privado, no genera ninguna responsabilidad con terceros.
        Lamentamos los inconvenientes que esto pueda ocasionar. Cualquier duda contáctanos a contacto@inkom.mx
      </Disclaimer>
    </UserContext.Provider>
    :
    <UserContext.Provider value={{ user }}>
      <div style={isLoading ? {position: 'fixed'} : {}}>
        <StateProvider>
        <Route Authenticator={Authenticator} />
        </StateProvider>
        <Footer/>
      </div>
        {/* <Disclaimer>
        La plataforma Inkom.mx (publicada el 17 de julio de 2021) en todos sus canales(sitio web, aplicación móvil y redes sociales) se encuentra en desarrollo y no está operativamente activa.
        Por tal motivo, las transacciones de terceros ajenos al equipo de Inkom no podrán ser procesadas.
        El uso de las plataformas está restringido a personal autorizado de Inkom y su explotación al ser de carácter privado, no genera ninguna responsabilidad con terceros.
        Lamentamos los inconvenientes que esto pueda ocasionar. Cualquier duda contáctanos a contacto@inkom.mx
      </Disclaimer> */}
    </UserContext.Provider>
    }
  </>
  );
};

const mapStateToProps = state => ({
  user: state.user.currentUser,
  isLoading: state.app.isLoading
});

export default connect(mapStateToProps)(App);
