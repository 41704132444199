/**
 * @param  {Int} month
 * @param  {Int} period
 */
export function getPastNumberMonth(month, period) {

  const months = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let response = month;
  switch (month) {
    case 0:
      response = months[months.length - period];
      break;
    case 1:
      switch (period) {
        case 2:
          response = months[months.length - period + 1];
          break;
        default:
          response = month - period
          break;
      }
    break;
    default:
      response = month - period
      break;
  }
  return response;
}

export const returnLastDay = function(y,m){
  return  new Date(y, m + 1, 0).getDate();
  }

export function returnMonthsNameByDate(dateFeed) {
  const monthList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]
  return monthList[dateFeed];
  // let month = dateFeed.getMonth()
  // switch (month) {
  //   case 0:
  //     return {
  //       actual: monthList[month],
  //       oneAgo: monthList[monthList.length -1],
  //       twoAgo: monthList[monthList.length -2]
  //     }
  //   case 1:
  //     return {
  //       actual: monthList[month],
  //       oneAgo: monthList[month -1],
  //       twoAgo: monthList[monthList.length -2]
  //     }
  //     default:
  //       return {
  //         actual: monthList[month],
  //         oneAgo: monthList[month -1],
  //         twoAgo: monthList[month -2]
  //       }
  // }

}

export const handleNotRepeatedElements = (list, attribute) => {
  if(!attribute || list.length < 1) {
    return []
  }
  let listCopy = list;
  listCopy = Array.from(new Set(listCopy.map(a => a[attribute])))
  .map(att => {
    return listCopy.find(a => a.period === att)
  })
  return listCopy;
};

/**
 * This is passed to sort method in order to sort by period and creation date. [7,8,9] <= months
 * @param  {} ob1
 * @param  {} ob2
 */

export const sortByDateAndPeriod = (ob1, ob2) => {
  if (ob1.period > ob2.period) {
      return 1;
  } else if (ob1.period < ob2.period) {
      return -1;
  }
  // Else go to the 2nd item
  if (ob1.createdAt < ob2.createdAt) {
      return -1;
  } else if (ob1.createdAt > ob2.createdAt) {
      return 1
  } else { // nothing to split them
      return 0;
  }
};
/**
 * * This is passed to sort method in order to sort by dt_created that is in unix format.
 * @param  {} ob1
 * @param  {} ob2
 */
export const sortByUnixDate = (ob1, ob2) => {
  if (ob1.dt_transaction > ob2.dt_transaction) {
      return 1;
  } else if (ob1.dt_transaction < ob2.dt_transaction) {
      return -1;
  }
};