import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { listCards, createOneCard, updateExistentCard } from '../../middlewares/API/cards/cards.api';

const cardsAdapter = createEntityAdapter();

// THUNKS
export const listAllCards = createAsyncThunk(
  'cards/listAllCards',
  async (id) => {
    const response = await listCards(id);
    return response;
  }
);

export const createNewCard = createAsyncThunk(
  'cards/createNewCard',
  async input => {
    const response = await createOneCard(input);
    return response;
  }
);

export const updateCard = createAsyncThunk(
  'cards/updateCard',
  async input => {
    const response = await updateExistentCard(input);
    return response;
  }
);
//

const cardsSlice = createSlice({
  name: 'cards',
  initialState: cardsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllCards.fulfilled]: (state, action) => {
      cardsAdapter.upsertMany(state, action.payload)
    },
    [createNewCard.fulfilled]: cardsAdapter.upsertOne,
    [updateCard.fulfilled]: cardsAdapter.upsertOne
  }
});

export default cardsSlice.reducer;

export const {
  selectAll: selectAllCards,
  selectById: selectCardById,
  selectIds: selectCardIds
} = cardsAdapter.getSelectors(state => state.cards);
