import React, { useEffect, useState } from 'react';
import { AccountContainer, MainAccountStatement } from './AccountStatement.style';

const AccountStatement = ({ data }) => {
  // console.log("REPAIR HERE")

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    setAccounts([])
  }, [])
  useEffect(() => {
    let accs = data.accountsResume
    for(let key in accs){
      setAccounts(prevState => [...prevState, accs[key]])
    }
  }, [data]);

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return (
    <MainAccountStatement>
    {
      accounts && (
        <>
          <h3>Estado de cuenta</h3>
          <div>
            <span>Nombre de la cuenta</span>
            <span>Suma gastos</span>
            <span>Porcentaje permitido</span>
            <span>Monto máximo</span>
            <span>Monto a ahorrar</span>
          </div>
          <AccountContainer>
            {
              accounts.length > 0 ?
              accounts.map(account =>
                <div key={account.accName}>
                  <div style={{width: "100%"}} ><span >{account.accOrg}</span></div>
                  <div key={account.accName}>
                    <span>{account.accName}</span>
                    <span>{formatter.format(account.chargesSum.toFixed(2))}</span>
                    <span>{account.percentage}&nbsp;%</span>
                    <span>{formatter.format(account.maxAmount)}</span>
                    <span>{formatter.format(account.ammountToSave.toFixed(2))}</span>
                  </div>
                </div>
              )
              :
              <div>Cargando...</div>
            }
            <div>
              <div style={{width: "100%"}}>
                <span>&nbsp;</span>
              </div>
              <div>
                <span><b>TOTAL</b></span>
                <span></span>
                <span>&nbsp;</span>
                <span></span>
                <span><b>{formatter.format(data.paymentData.amount.toFixed(2))}</b></span>
              </div>
            </div>
            {
              (data.paymentData && data.paymentData.amountPayed) && (
                <div style={{backgroundColor: '#C0F2BB'}}>
                  <div style={{width: "100%"}}>
                    {/* <span>&nbsp;</span> */}
                  </div>
                  <div>
                    <span><b>TOTAL PAGADO</b></span>
                    <span></span>
                    <span>&nbsp;</span>
                    <span></span>
                    <span><b>{formatter.format(data.paymentData.amountPayed.toFixed(2))}</b></span>
                  </div>
                </div>
              )
            }
          </AccountContainer>
        </>
      )
    }
    </MainAccountStatement>
  )
}

export default AccountStatement;