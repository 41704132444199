//export const DarkBlue = "#FAFAFA";
export const GrayBlue = "#48586A"

///Nuevos
export const DarkBlue = "#103D56";
export const Blue = "#0880AE";
export const LightMBlue = "#7C9CBF";
export const LightBlue = "#DBE2EA";

export const Black = "#000000";
export const DarkGray = "#756F86";

export const Gray = "#2C27381F";
export const GrayLight = "#2C273814";
export const GrayLight2 = "#2C27380A"
export const GrayWhite = "#FBFBFB";
export const White = "#FFFFFF";

export const Yellow = "#FFCE00";
export const Yellow2 = "#FFC200";
export const Orange = "#FFA700"

export const Green = "#EDFFEB";
export const BabyBlue = "#EEFBFF";
export const BabyYellow = "#FFFBED";
export const BabyYellow2 = "#f5eac4";
export const Pink = "#FFF2F2";
export const Violet = "#F5F5FF";



