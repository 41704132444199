import styled from 'styled-components';
import { Yellow, DarkBlue, GrayLight } from '../../utilities/Colors';

export const Button = styled.button(({disabled}) => {
  return {
  'background-color': disabled ? GrayLight : Yellow,
  cursor: disabled ? 'not-allowed !important' : 'pointer !important',
  border: 'none',
  width: '100%',
  color: DarkBlue,
  'font-family': "IBM Plex Sans",
  'font-weight': '700',
  'font-size': '.8rem',
  padding: '3% 0',
  height: 'auto',
}});
