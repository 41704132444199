import React, { useEffect, useState } from 'react'
import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";
import { initUserSession, getAccounts, getTransactions, getCredentialStatus } from '../../../../API/Sync';
import './UpdateWidget.css';

import { connect, useDispatch, useSelector } from 'react-redux';
import { selectAllAccounts, updateAccountInteraction } from '../../../../store/accounts/accounts.slice'
import { updateTransactionsList } from '../../../../store/transactions/transactions.slice';
import { createTransaction } from '../../../../store/transactions/transactions.slice';
import { userSelector } from '../../../../store/user/user.slice';
import { setAppLoading, setAppNotLoading } from '../../../../store/app/app.actions';
import codes from './requestCodes';

const UpdateWidget = ({
  setAppIsLoading,
  setAppIsNotLoading,
  widgetAction,
  setWidgetAction,
  setIsUrgentNotificationVisible,
  setUrgentNotificationContent
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const accounts = useSelector(selectAllAccounts);
  const [credentialsNeedAttention, setCredentialsNeedAttention] = useState([]);
  const [timesUsed, setTimesUsed] = useState(0);
  const [isWidgetMounted, setIsWidgetMounted] = useState(null);

  useEffect(() => {
    if (user && timesUsed < 1) {
      validateCredentialsStatus();
      setTimesUsed(prevState => prevState += 1);
    };
  }, []);

  useEffect(() => {
    setIsWidgetMounted(widgetAction);
    if(widgetAction && isWidgetMounted) {
      createWidget(widgetAction)
    }
  }, [widgetAction, isWidgetMounted]);

  const getUpdatedData = async () => {
    const thisMoment =  Date.now().toString().slice(0,10);
    setAppIsLoading();

    // const updatedToken = await updateUserToken(user.id_pb_user);
    // setNewUpdatedToken(updatedToken);
    await accounts.map(async accountElement => {
      if(!accountElement.lastFetch || Number(thisMoment) - Number(accountElement.lastFetch) > 86400) {
        await dispatch(updateAccountInteraction({account: accountElement,  id_user: user.id_pb_user}));
        await dispatch(updateTransactionsList({account: accountElement, user}));
      } else {
        console.log(`No han pasado 24 hrs para consultar de nuevo la cuenta ${accountElement.number}`)
      }
    });
    setAppIsNotLoading();
  }

  const validateCredentialsStatus = async () => {
    const credentialsList = [];
    const credentialsStatus = await getCredentialStatus(user.id_pb_user)
    if(credentialsStatus && credentialsStatus.data.code == 200) {
      for(const credential of credentialsStatus.data.response) {
        if (credential.code >= 400) {
          credentialsList.push(credential);
          // console.log('Algunas de tus cuentas necesitan atención');
        }
      }

      setCredentialsNeedAttention(prevState => [...prevState, ...credentialsList])
      if (credentialsList.length) {
        setIsUrgentNotificationVisible(true);
        setUrgentNotificationContent(credentialsList);
      }
    }
  };

  const createWidget = async (credential) => {
    if(!widgetAction){
      return;
    }
    let syncWidget;

    let sessionData = await syncSessionData(user, credential);

    setAppIsNotLoading();
    syncWidget = new SyncWidget(sessionData.params);
    syncWidget.setEntrypointSite(credential);
    syncWidget.$on('opened', () => {
      setAppIsNotLoading()
    })
    syncWidget.$on('closed', () => {
      if(widgetAction) {
        console.log('Cerrado');
        // syncWidget.close();
      }
      setTimeout(() => {
        setWidgetAction(null);
      }, 1000)
    })
    syncWidget.$on('success', () => {
      getUpdatedData();
      // setIsUrgentNotificationVisible(false);
    })
  };

  const syncSessionData = async (user, credential) => {
    const sessionData = await initUserSession(user.id_pb_user);
    let token = null;
    let params = null;
    if (sessionData.data.code === 200) {
      token = sessionData.data.response.token;
      localStorage.setItem('token', JSON.stringify(token))

      params = {
        token,
        element: "#widget",
        config: {
          locale: "es",
          entrypoint: {
            country: "MX"
          }, navigation: {
            hideAsideMenu: true,
            displaySiteOrganizationTypes: ["Bank"]
          }
        }
      };
    } else {
      console.log("ERROR AL GENERAR TOKEN")
    };
    return {params, user};
  };

  return (
    <div>
      <div className='pb-w-sync_modal-container' id='widget'></div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading())
  };
};

export default connect(null, mapDispatchToProps)(UpdateWidget);
