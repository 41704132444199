import styled from 'styled-components';
import { BabyBlue } from '../../utilities';

export const GeneralLoader = styled.div`
  background-color: ${BabyBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-behavior: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 95vh;
  z-index: 100000;
  &>img {
    width: 16%;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
  &>h1 {
    margin-top: 4rem;
    animation: opac 3s linear infinite;
  }

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes opac {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
  }
`;