import React , { useEffect } from 'react';
import {API, graphqlOperation, Auth} from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { listContributions, listUsers } from '../../../graphql/queries'
import {DashboardAdminPrincipalS, DashboardAdminContainer} from "./DashboardAdminPrincipal.Style";

const DashboardAdminPrincipal = () => {
  let history = useHistory();

  useEffect(() => {
    // dataToRender()
    Auth.currentAuthenticatedUser()
    .then(result => {
      console.log("Data synchronization")
    })
    .catch(err => {
      console.log({err})
    history.push('/')
  })
    ;
  }, []);

  const setInfoPage = async dataKind => {
    let newData = [];
    return new Promise(async (resolve, reject) => {
      switch(dataKind) {
        case 'contributions':
          await API.graphql(graphqlOperation(listContributions))
          .then(result => {
            newData = result.data.listContributions.items;
            return newData;
          });
          break;
        case 'users':
          await API.graphql(graphqlOperation(listUsers))
          .then(result => {
            newData = result.data.listUsers.items;
            return newData;
          });
          break;
        default:
          break;
      };
      resolve(newData);
      reject("Error en el switch");
    });
  };

  const downloadTxtFile = (data) => {
    const element = document.createElement("a");
    const file = new Blob(data, {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    const datedate = new Date()
    element.download = `${datedate.getFullYear() + String(datedate.getMonth() + 1).padStart(2, '0') + String(datedate.getDate()).padStart(2, '0')}INK.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const dataToRender = async (asked = 'users') => {
    const dataToDownload = []
    const dataToDownload2 = []
    await setInfoPage(asked)
    .then(result => {

      console.log({MMM:result})
      result.map(contribution => {
        const { contributionOwner } = contribution
        const fecha = contribution.createdAt.split('T')[0].split('-').join('')
        const [date, hour] = contribution.createdAt.split('T')
        const dateFinal = date.split('-').join('')
        const hourFinal = hour.split('.')[0].split(':').join('')
        const parsedAmount = String(contribution.ammount).includes('.') ? String(contribution.ammount) : String(contribution.ammount) + '.00'
        // const amount = contribution.ammount.includes('.')?.split('.')
        const data = {
          curp: contributionOwner.curp ? contributionOwner.curp.padEnd(18, ' '): ''.padEnd(18, ' '),
          rfc: contributionOwner.rfc ? contributionOwner.rfc.padEnd(13, ' '): ''.padEnd(13, ' '),
          nombres: contributionOwner.name ? contributionOwner.name.padEnd(40, ' '): ''.padEnd(40, ' '),
          apellido1: contributionOwner.lastname1 ? contributionOwner.lastname1.padEnd(40, ' ') : ''.padEnd(40, ' '),
          apellido2: contributionOwner.lastname2 ? contributionOwner.lastname2.padEnd(40, ' ') : ''.padEnd(40, ' '),
          telefono: contributionOwner.phone.padEnd(10, ' '),
          mail: contributionOwner.email.padEnd(40, ' '),
          tipoAportacion: '01',
          tipoDeposito: '01',
          siefore: '02',
          fechaCargo: fecha,
          fechaDispersión: fecha,
          monto: parsedAmount,
          folio: dateFinal + hourFinal + '0',
        }
      // console.log({data})
      const finalFormat =
        data.curp +
        data.rfc +
        data.nombres +
        data.apellido1 +
        data.apellido2 +
        data.telefono +
        data.mail +
        data.tipoAportacion +
        data.tipoDeposito +
        data.siefore +
        data.fechaCargo +
        data.fechaDispersión +
        data.monto +
        data.folio
      dataToDownload.push(finalFormat)
    })
    const indice = Math.floor(Math.random() * 100)
    console.log({indice})
    console.log({dataToDownload})
    dataToDownload2.push(dataToDownload[indice])

    // downloadTxtFile(dataToDownload)
    downloadTxtFile(dataToDownload2)


      return {list: result, type: asked};
    });
  };

  return (
    <DashboardAdminPrincipalS>
      Bienvenido Admin
      <DashboardAdminContainer>
        <button onClick={() => dataToRender('users')}>Usuarios</button>
        <button onClick={() => dataToRender('contributions')}>Contribuciones</button>
      </DashboardAdminContainer>
    </DashboardAdminPrincipalS>
  )
};

export default DashboardAdminPrincipal;
