import React, { useState, useEffect } from 'react';
import { Button } from './Button.style';

const CustomButton = (props) => {
  const { children, onClick = () => {}, disabled = false } = props;
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <Button onClick={onClick} disabled={isDisabled}>
      {children}
    </Button>
  );
};

export default CustomButton;
