import styled from 'styled-components'
import {DarkBlue, Yellow, Blue,GrayWhite}  from '../../../utilities/Colors'

export const Terminoscontainer = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 5% 3%;
    background-color: ${GrayWhite};
    &>p{
        margin: 0 3%;
    }
    &>h2{
        color:${Yellow};
        text-align: center;
        font-weight:600;
    }
    &>h1{
        color:${DarkBlue};
        text-align: center;
        font-weight:700;
        margin: 0 3%;
        padding: 2% 0;
    }
    &>h3{
        color:black;
        margin:5% 0;
        text-align: right;
        font-weight:500;
        font-size: .7rem;
    }
`