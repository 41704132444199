import { API, graphqlOperation } from 'aws-amplify';
import { getMyPayments } from '../../../graphql/customqueries';
import { createPayment, updatePayment } from '../../../graphql/mutations';
import { getPayment } from '../../../graphql/queries';

export const createNewPayment = async input => {
  try {
    const rawResponse = await API.graphql(graphqlOperation(createPayment, {input}));
    return rawResponse.data.createPayment;
  } catch (err) {
    console.log({err})
  }
};

export const getSpecificPayment = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getPayment, {id}));
  return rawResponse.data.getPayment;
};

export const updateExistentPayment = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updatePayment, {input}));
  return rawResponse.data.updatePayment;
};

export const listPayments = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getMyPayments, {ownerId: id}));
  return rawResponse.data.listPayments.items;
};
