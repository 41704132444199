import styled from 'styled-components';
import { DarkBlue, GrayLight2, Yellow2 } from '../../utilities';
import { device } from '../../utilities/media_queries'

export const DashContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 3% 10%;
	@media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
        width: 100%;
        margin: 2% 0;
    }
	@media ${device.tablet},
      ${device.laptop},
      ${device.laptopL},{
		width: 90%;
        margin: 2% 5%;
    }
`;

export const DashTypes = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 2%;
`;

export const DashControl = styled.h5`
	color: ${DarkBlue};
	font-weight: 500;
	cursor: pointer;
	margin: 0 2%;
	border-bottom: ${({selected}) => selected ? `2px solid ${Yellow2}` : null }
`;

export const DashDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-top: 1px solid ${GrayLight2};
	color: ${DarkBlue};
	&>h3 {
		width: 30%;
		margin: 2.5%;
		font-size: .9rem;
		color: red;
		&>img {
			width: 3vh;
		}
	}
`;
