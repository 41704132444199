import styled from 'styled-components';
import {DarkBlue, White, Yellow, Gray} from '../../utilities/Colors'
import {device} from '../../utilities/media_queries'

export const TopMenu = styled.div`
  height: auto;
  font-family: 'Oswald', sans-serif;
  padding: 2% 10% 0 10%;
  color: black;
  background-color:white;
  /* border-top: 2px solid white; */
  /* margin-bottom: 2%; */
  &>div{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 0;
    &>h3{
      /* color: ${White}; */
      color: black;
      margin: 0 4%;
      /* border-bottom: 2px solid black; */
      cursor: pointer;
      &>a{
      color: inherit;
      }
      &>h3:hover{
        color:${Yellow};
      }
    }


    @media ${device.tablet} {
    font-Size: .8rem;
    }
    @media ${device.laptop} {
    font-Size: 1rem;
    }
    @media ${device.laptopL} {
    font-Size: 1.2rem;
    }
    @media ${device.desktop} {
    font-Size: 1.8rem;
    }
  }
`