import React, {createContext, useReducer} from 'react';

const initialState = {
  user: {},
  visible_loader: false
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'activate_loader':
        let loader_state = !state.visible_loader
        const newState = {...state, visible_loader: loader_state}// do something with the action
        return newState;
      case 'set_user':
        let user_data= action.payload.user
        return {...state, user: {...state.user, ...user_data}}
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }