import { API, graphqlOperation } from 'aws-amplify';
import { createUser, updateUser } from '../../../graphql/mutations';
import { getUser } from '../../../graphql/queries';

export const createNewUser = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createUser, {input}));
  return rawResponse.data.createUser;
};

export const updateThisUser = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updateUser, {input}));
  return rawResponse.data.updateUser;
};

export const getCurrentUser = async idenfificator => {
  const rawResponse = await API.graphql(graphqlOperation(getUser, {id: idenfificator}));
  return rawResponse.data.getUser;
};
