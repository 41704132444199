import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { listMitOperationRequests, createNewMitOperationRequest, updateExistentMitOperationRequest } from '../../middlewares/API/mitOperations/mitOperations.api';

const mitOperationsAdapter = createEntityAdapter();

// THUNKS
export const listAllMitOperations = createAsyncThunk(
  'transactions/listAllMitOperations',
  async (id) => {
    const response = await listMitOperationRequests(id);
    return response;
  }
);

export const createMitOperation = createAsyncThunk(
  'transactions/createMitOperation',
  async input => {
    const response = await createNewMitOperationRequest(input);
    return response;
  }
);

export const updateMitOperation = createAsyncThunk(
  'transactions/updateMitOperation',
  async input => {
    const response = await updateExistentMitOperationRequest(input);
    return response;
  }
);
//

const mitOperationsSlice = createSlice({
  name: 'mitOperations',
  initialState: mitOperationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllMitOperations.fulfilled]: (state, action) => {
      mitOperationsAdapter.upsertMany(state, action.payload)
    },
    [createMitOperation.fulfilled]: mitOperationsAdapter.upsertOne,
    [updateMitOperation.fulfilled]: mitOperationsAdapter.upsertOne
  }
});

export default mitOperationsSlice.reducer;

export const {
  selectAll: selectAllMitOperations,
  selectById: selectMitOperationById,
  selectIds: selectMitOperationIds
} = mitOperationsAdapter.getSelectors(state => state.transactions);
