import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { SSavingsContainer, SavingsForm, SecureDataDisclaimer } from './SafeSavings.style';
import AccountSelection from '../../../layout/AccountSelection/AccountSelection';
import InstitutionList from '../../../layout/InstitutionList/InstitutionList';
import { selectAllAccounts } from '../../../../store/accounts/accounts.slice';

const SafeSavings = (props) => {
  const accounts = useSelector(selectAllAccounts);
  const [viewWidget, setViewWidget] = useState(false);

  const handleListVisibility = () => {
    setViewWidget(!viewWidget);
  };

  return (
    <SSavingsContainer>
      <h2>Información adicional para garantizar ahorro seguro a través de compras</h2>
      <p>Es necesario que sincronices la información de tus cuentas bancarias con Payboook para difrutar de los beneficios del ahorro seguro.</p>
      <p>Paybook nos permite saber el monto de tus compras y de esta manera determinar el monto de tu ahorro, así como recomendaciones.</p>
      <SavingsForm>
        <Button type="primary" style={{backgroundColor:"#103D56", borderColor:"#103D56", color:"#FFFFFF", fontWeight:"700"}} onClick={handleListVisibility}>Sincroniza con PAYBOOK</Button>
      </SavingsForm>
      {
        viewWidget && (
          <InstitutionList
            setViewWidget={setViewWidget}
          />
        )
      }
      {
        accounts.length > 0 && ( <AccountSelection /> )
      }
      <SecureDataDisclaimer>
        <p>Nuestro sitio web y aplicación movil no utiliza ningún software de seguimiento invasivo. Usamos su información personal sólo con el propósito que se describe en nuestro <Link to="/aviso-de-privacidad" style={{color:"#FFCE00", fontWeight:"600"}}>AVISO DE PRIVACIDAD</Link>.<br/>
        <br/>En ese sentido, la información de sus cuentas está segura. Proteger su privacidad se trata de tener control sobre sus datos. En Inkom, te ayudamos a controlar y proteger lo que es tuyo.</p>
      </SecureDataDisclaimer>
    </SSavingsContainer>
  );
};

export default SafeSavings;
