import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { createNewAccount, listAccounts, updateExistentAccount } from '../../middlewares/API/accounts/accounts.api';
import { updateAccountToToday } from '../../middlewares/communication/accounts/accounts.communication';

const accountsAdapter = createEntityAdapter();

// THUNKS
export const listAllAccounts = createAsyncThunk(
  'accounts/listAllAccounts',
  async (id) => {
    const response = await listAccounts(id);
    return response;
  }
);

export const updateAccount = createAsyncThunk(
  'accounts/updateAccount',
  async input => {
    const response = await updateExistentAccount(input);
    return response;
  }
);

export const updateAccountInteraction = createAsyncThunk(
  'accounts/updateAccountInteraction',
  async ({account, id_user}) => {
    const response = await updateAccountToToday(account, id_user);
    return response;
  }
);

export const createAccount = createAsyncThunk(
  'accounts/createAccount',
  async input => {
    const response = await createNewAccount(input);
    return response;
  }
)
//

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: accountsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllAccounts.fulfilled]: (state, action) => {
      accountsAdapter.upsertMany(state, action.payload)
    },
    [createAccount.fulfilled]: accountsAdapter.upsertOne,
    [updateAccount.fulfilled]: accountsAdapter.upsertOne,
    [updateAccountInteraction.fulfilled]: accountsAdapter.upsertOne
  }
});

export default accountsSlice.reducer;

export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds
} = accountsAdapter.getSelectors(state => state.accounts);
