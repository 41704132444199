import styled from 'styled-components';
import { device } from '../../../utilities/media_queries';
import { Yellow, DarkBlue, White} from '../../../utilities/Colors';

export const SavingGeneral = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: column;
  width: 96%;
  margin:3% 2%;
  padding-bottom: 2%;

  &>div{
    /* margin: 2%; */
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &>h1{
      color:${DarkBlue};
      font-weight: 700;
      font-size:3rem;
      margin: 0;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
        font-size:1.6rem;
      }
      @media ${device.tablet} ,
        ${device.laptop} {
        font-size:3rem;
      }
    }
    &>h4{
      color:${DarkBlue};
      background-color:${Yellow};
      border-radius: 5px;
      font-weight: 500;
      height: auto;
      padding: 2%;
      @media ${device.tablet} {
        height: auto;
      }
      @media ${device.laptop} {
        height: auto;
      }
    }
  }
  &>h2{
    border-left:5px solid ${Yellow};
    padding-left: 2%;
    color:${DarkBlue};
    text-align:left;
    width: 96%;
    margin: 2%;
    font-weight: 700;
    font-Size: 1.7rem;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
        font-Size: 1.2rem;
    }
    @media ${device.tablet} {
      font-Size: 1.7rem;
    }
  }
`

export const SecureDataDisclaimer = styled.div`
  height: auto;
  width: 70% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &>p{
    color:${DarkBlue};
    font-size: 14px;
    font-weight: 300;
    margin-top: 20px;
`;

export const Accounts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 2% 0;
  justify-content: space-evenly;
`;

export const PartnerSection = styled.div`
	display: flex;
	flex-direction: row;
  flex-wrap: wrap;
  background-color: ${White};
  padding: 2%;
  margin: 0 2% 2% 0;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  width: 45%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 90%;
    margin: 2% 5%;
  }
  @media ${device.laptop},
    ${device.laptopL}{
    width: 48%;
    margin: 0 2% 2% 0;
  }
  &>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    &>img{
      width: 30%;
      height: auto;
      margin-bottom: 5%;
    }
    &>h3{
      color:${DarkBlue};
      width: 50%;
    }
    &>p{
      width: 50%;
    }
    &>div>h2{
      color:${Yellow};
      margin: 0 10px;
      cursor: pointer;
      rotate: 180;
      font-size: 1.5rem;
      text-align: right;
    }
  }
`

export const DomiciledCard = styled.div`
  display: flex;
	flex-direction: row;
  flex-wrap: wrap;
  background-color: ${White};
  padding: 2%;
  margin: 0 2% 2% 0;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  width: 45%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 90%;
    margin: 2% 5%;
  }
  @media ${device.laptop},
    ${device.laptopL}{
    width: 45%;
  }
  &>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    width: 50%;
    &>img{
      width: 50%;
      height: auto;
      margin-bottom: 5%;
    }
    &>h3{
      color:${DarkBlue};
      width: 50%;
    }
    &>p{
      width: 50%;
    }
    &>div>h2{
      /* width: 45%; */
      color:${Yellow};
      margin: 0 10px;
      cursor: pointer;
      rotate: 180;
      font-size: 1.5rem;
      text-align: right;
    }
  }
`

export const BankCard = styled.div`
  position: relative;
  display: flex;
  background-color: ${White};
  padding: 2%;
  margin:0 2% 2% 0;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  width: 75%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 90%;
    margin: 2% 5%;
  }
  @media ${device.laptop},
    ${device.laptopL}{
    width: 75%;
  }
  &>div:first-child{
    display: flex;
    align-items: center;
    width: 25%;
    &>img{
      width: 90%;
      height: auto;
    }
  }
  &>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 55%;
    &>h3{
      color:${DarkBlue};
    }
  }
  &>div:nth-child(3){
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20%;
    &>h2{
      color:${DarkBlue};
    }
  }
  &>div:nth-child(4){
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background-color: ${Yellow};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -10px;
  }
  &>div:nth-child(4):hover{
    cursor: pointer;
  }
`;
