import styled from 'styled-components';
import {DarkBlue, Gray,GrayWhite,Yellow} from '../utilities/Colors';
import {device} from '../utilities/media_queries'

export const Disclaimer = styled.div`
  position: fixed;
  z-index: 99999;
  bottom: 0;
  margin-bottom: 1px;
  font-size: 20px;
  font-weight: bolder;
  padding-top: 0 !important;
  padding: 1.5% !important;
  width: 100%;
  background-color: rgba(241, 16, 68, 1);
  color: white;
  @media ${device.mobileS} {
    font-size: 12px;
    font-weight: bold;
    }
    @media ${device.mobileM} {
      font-size: 12px;
      font-weight: bold;
    }
    @media ${device.mobileL} {
      font-size: 14px;
      font-weight: bold;
    }
    @media ${device.tablet} {
      font-size: 16px;
      font-weight: bold;
    }
`;

export const LandingGral = styled.div`
  height: auto;
  padding:0 0 2% 0;
  background-color: ${GrayWhite};
  font-family: 'IBM Plex Sans', sans-serif;
  &>div{
    // padding-top: 5%;
  }
  @media ${device.mobileM} {
    margin-top:60px;
    padding-top: 0;
  }
  @media ${device.laptop} {
    margin-top:60px;
  }
  @media ${device.laptop} {
    margin-top:76px;
  }
`

export const WellnessContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  width: 100%;
  height: auto;
`

export const WhatSection = styled.div`
  width: 100%;
  background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/WhatSection-01.svg');
  background-size: cover;
  padding: 1% 0;
  text-align: center;
  &>h1{
    color:${DarkBlue};
    font-weight: 700;
    font-size:3rem;
  }
  &>p{
    padding: 2%;
    color:${DarkBlue};
    width:50%;
    margin:3% 25%;
    font-size:1rem;
    text-align: justify;
    font-weight:400;
  }
    @media ${device.mobileS} {
      margin: 2% 5%;
      &>h1{
        font-size:1.8rem;
      }
      &>p{
        width: 70%;
        margin:3% 15%;
      }
    }
    @media ${device.mobileM} {
      margin: 2% 5%;
      &>h1{
        font-size:1.8rem;
      }
      &>p{
        width: 70%;
        margin:3% 15%;
      }
    }
    @media ${device.mobileL} {
      margin: 2% 5%;
      &>h1{
        font-size:1.8rem;
      }
      &>p{
        width: 70%;
        margin:3% 15%;
      }
    }
    @media ${device.tablet} {
      margin: 2% 0;
      &>h1{
        font-size:3rem;
      }
      &>p{
        width: 60%;
        margin: 3% 20%;
      }
    }
    @media ${device.laptop} {
      font-size:1rem;
      margin: 1.5% 0;
      &>p{
        width: 50%;
        margin:3% 25%;
      }
    }
    @media ${device.laptopL} {
      margin: 1.5% 0;
      font-size:1.8rem;
      padding: 5% 0;
      &>h1{
        font-size:3.5rem;
      }
      &>p{
        width: 50%;
        margin:3% 25%;
      }
    }


/*
  &>div:nth-child(1){
    background-image: url("https://aurumresources.s3.amazonaws.com/general/nick-karvounis-og_p3b9bJ7E-unsplash.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    transform:perspective(600px) rotateY(0deg);
    backface-visibility: hidden;
    transition: transform .4s ease-in-out 0s;
    width: 30%;
    height: 300px;
    cursor: pointer;
    @media ${device.mobileS} {
      width: 86%;
    }
    @media ${device.mobileM} {
      width: 86%;
    }
    @media ${device.mobileL} {
      width: 86%;
    }
    @media ${device.tablet} {
      width: 28%;
      padding: 1%;
    }
    @media ${device.laptop} {
      width: 28%;
      padding: 1%;
    }
    @media ${device.laptopL} {
      width: 28%;
      padding: 1%;
    }
  } */


  /* &>div:nth-child(2){
    position: relative;
    background-color: ${DarkBlue};
    backface-visibility: hidden;
    transform: perspective(600px) rotateY(180deg);
    transition: transform .4s ease-in-out 0s;
    width: 100%;
    height: 300px;
    padding: 1% 5%;
    cursor: pointer;
    &>p{
      padding: 2%;
      color:whitesmoke;
      margin-top: 30%;
      }
  }*/
`

// export const WellnessThree = styled.div`
//   width: 30%;
//   margin: 2% 1.5%;
//   padding: 1%;
//   text-align: center;
//     &:hover div:nth-child(1){
//       transform: perspective(600px) rotateY(-180deg);
//     }
//     &:hover div:nth-child(2){
//       transform: perspective(600px) rotateY(0deg);
//       &>p{
//       padding: 2%;
//       color:whitesmoke;
//       margin-top: 30%;
//       }
//     }
//     @media ${device.mobileS} {
//       width: 90%;
//       margin: 2% 5%;
//       padding: 1%;
//     }
//     @media ${device.mobileM} {
//       width: 90%;
//       margin: 2% 5%;
//       padding: 1%;
//     }
//     @media ${device.mobileL} {
//       width: 90%;
//       margin: 2% 5%;
//       padding: 1%;
//     }
//     @media ${device.tablet} {
//       width: 30%;
//       margin: 2% 1.5%;
//       padding: 1%;
//     }
//     @media ${device.laptop} {
//       width: 30%;
//       margin: 2% 1.5%;
//       padding: 1%;
//     }
//     @media ${device.laptopL} {
//       width: 30%;
//       margin: 2% 1.5%;
//       padding: 1%;
//     }


//   &>div:nth-child(1){
//     background-image:url("https://aurumresources.s3.amazonaws.com/general/denys-nevozhai-z0nVqfrOqWA-unsplash.jpg");
//     background-size: cover;
//     background-repeat: no-repeat;
//     position: absolute;
//     transform:perspective(600px) rotateY(0deg);
//     backface-visibility: hidden;
//     transition: transform .4s ease-in-out 0s;
//     width: 30%;
//     height: 300px;
//     cursor: pointer;
//     @media ${device.mobileS} {
//       width: 86%;
//     }
//     @media ${device.mobileM} {
//       width: 86%;
//     }
//     @media ${device.mobileL} {
//       width: 86%;
//     }
//     @media ${device.tablet} {
//       width: 28%;
//       padding: 1%;
//     }
//     @media ${device.laptop} {
//       width: 28%;
//       padding: 1%;
//     }
//     @media ${device.laptopL} {
//       width: 28%;
//       padding: 1%;
//     }
//   }


//   &>div:nth-child(2){
//     position: relative;
//     background-color: ${DarkBlue};
//     backface-visibility: hidden;
//     transform: perspective(600px) rotateY(180deg);
//     transition: transform .4s ease-in-out 0s;
//     width: 100%;
//     height: 300px;
//     padding: 1% 5%;
//     cursor: pointer;
//     &>p{
//       padding: 2%;
//       color:whitesmoke;
//       margin-top: 30%;
//       }
//   }
// `