import styled from 'styled-components'
import { device } from '../../../../utilities/media_queries';
import { Black, DarkBlue, White, Yellow} from '../../../../utilities/Colors'

export const PartnerCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height:auto;
  /* background-color: purple; */
  @media ${device.mobileS} {
  /* margin: 2%; */
}
  @media ${device.tablet} {
  font-size:.8rem;
  /* margin: 5% 2%;
  padding: 10% 0; */
}
  @media ${device.laptop} {
  font-size:.9rem;
  /* margin: 3% 2%;
  padding: 5% 0; */
}
@media ${device.laptopL} {
  /* margin: 2%;
  padding: 2% 0; */
}
  @media ${device.desktop} {
  font-size: 1.5rem;
  /* margin: 2%;
  padding: 5% 0; */
}
`

export const PartnerCardDashboard= styled.div`
  background-color: rgb(255,255,255);
  /* background-color: blue; */
  padding: 0;
  width: 75%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  &>div:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color:${Yellow};
    margin-bottom: 2%;
    &>h2{
      width: 80%;
      justify-content: center;
      color: white;
    }
  }
  &>div:nth-child(2){
    cursor: pointer;
    @media ${device.mobileS} {
        width: 90%;
        margin: 0 25%;
        font-size:.8rem;
      }
    @media ${device.mobileM} {
        width: 70%;
        margin: 0 25%;
      }
      @media ${device.MobileL} {
        width: 70%;
        margin: 0 25%;
      }
      @media ${device.tablet} {
        width: 40%;
        margin: 0 30%;
      }
      @media ${device.laptop} {
        width: 30%;
        margin: 0 30%;
      }
      @media ${device.laptopL} {
        width: 30%;
        margin: 0 30%;
      }
      @media ${device.desktop} {
        width: 30%;
        margin: 0 30%;
      }
  &>h4{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid ${Yellow};
    border-radius: 5px;
    }
  }

  &>div:nth-child(2):hover{

    &>h4:nth-child(1){
      color:white;
      background-color:${DarkBlue};
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid white;
      border-radius: 5px;
      transition: 1s ease-out;
      @media ${device.mobileM} {
      }
      @media ${device.MobileL} {
      }
      @media ${device.tablet} {
      }
      @media ${device.laptop} {
      }
      @media ${device.laptopL} {
      }
      @media ${device.desktop} {
      }
    &>h4:nth-child(2){
      /* color:white; */
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid white;
      border-radius: 5px;
      transition: 1s ease-out;
      @media ${device.mobileM} {
      }
      @media ${device.MobileL} {
      }
      @media ${device.tablet} {
      }
      @media ${device.laptop} {
      }
      @media ${device.laptopL} {
      }
      @media ${device.desktop} {
      }
    }
  }
  @media ${device.mobileS} {
    width: 98%;
    margin: 1%;
    }
    @media ${device.mobileM} {
    width: 98%;
    margin: 1%;
    }
  @media ${device.mobileL} {
    width: 90%;
    margin: 1%;
    }
  @media ${device.tablet} {
    width: 90%;
    margin: 1%;
  }
  @media ${device.laptop} {
  width: 80%;
  margin: 1%;
}
  @media ${device.desktop} {
    margin: 0;
  padding: 0;
}
  }
`

export const PartnerCardInfo= styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
  flex-wrap: wrap;
	justify-content: space-evenly;
  padding: 2%;
  margin: 2%;
  align-items: center;
  @media ${device.laptop} {
    &>div{
    width: 22%;
    margin: 2% 1%;
    }
  }
  @media ${device.laptopL} {
    margin: 1%;
    &>div{
    width: 20%;
    margin: 1% 0;
    }
  }

`
