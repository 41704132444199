export const errorHandler = (error, aditionalFunction=null, dataNeeded=null) => {
  switch (error.code) {
    // User has not confirmed code.
    case 'UserNotConfirmedException':
      aditionalFunction(dataNeeded);
      return {
        type: 'codeConfirmation',
        codeConfirmation: true
      };
    // User is not registered yet.
    case 'UserNotFoundException':
      return {
        type: 'userDoesNotExists',
        userDoesNotExists: true
      };
    // User password expired
    case 'PasswordResetRequiredException':
      return {
        type: 'passwordResetRequired',
        passwordResetRequired: true
      };
    // Credentials
    case 'NotAuthorizedException':
      switch (error.message) {
        case 'Incorrect username or password.':
          return {
            type: 'passwordWrong',
            passwordWrong: true
          };
        case 'User is disabled.':
          return {
            type: 'disabledUser',
            disabledUser: true
          };
        default:
          console.log({error});
          return {
            type: 'somethingWentWrong',
            somethingWentWrong: true
          };
      }
    // Code provided is not correct
    case 'CodeMismatchException':
      return {
        type: 'passwordRecoveryFailedCode',
        passwordRecoveryFailedCode: true
      };
    // Weak password
    case 'InvalidParameterException':
      switch (error.message) {
        case 'Cannot reset password for the user as there is no registered/verified email or phone_number':
          aditionalFunction(dataNeeded);
          return {
            type: 'codeConfirmation',
            codeConfirmation: true
          };
        default:
          return {
            type: 'passwordIsTooWeak',
            passwordIsTooWeak: true
          };
      }
    // TOO MANY ATTEMPTS
    case 'LimitExceededException':
      return {
        type: 'tooManyAttempts',
        tooManyAttempts: true
      };
    default:
      console.log({error});
      return {
        type: 'somethingWentWrong',
        somethingWentWrong: true
      };
  }
};
