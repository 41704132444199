import styled from 'styled-components'
import {Black, DarkBlue, GrayWhite, Yellow} from '../../../utilities/Colors'
import {device} from '../../../utilities/media_queries'

export const GeneralSectionLog = styled.div` 
	margin: 2% 0;
	@media ${device.mobileS},
		${device.mobileM}, 
		${device.mobileL} {
			margin: 2% 0;
	}
	@media ${device.tablet},
			${device.laptop},
			${device.laptopL}{
			margin: 2% 0;
		}
`

export const ContainerLog = styled.div`
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 1%;
	margin: 0 2%;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content:center;
	background-color: ${GrayWhite};
	@media ${device.mobileS},
	${device.mobileM}, 
	${device.mobileL} {
	padding-top: 80px; 
	margin-top: -45px;
	}
`

export const LogText = styled.div`
	width: 30%;
	@media ${device.mobileS},${device.mobileM}, ${device.mobileL} {
		width: 90%;
	}
	@media ${device.tablet},
			${device.laptop},
			${device.laptopL}{
			width:30%
		}	
	&>h1{
		color: ${DarkBlue};
		font-size:3.5rem;
		border-left:10px solid ${Yellow};
		font-weight: 700;
		@media ${device.mobileS},${device.mobileM}, ${device.mobileL} {
			font-size:1.5rem;
		}
		@media ${device.tablet} {
			font-size:3rem;
		}
		@media ${device.laptop} {
			font-size:3.5rem;
		}
		@media ${device.laptopL} {
			font-size:4rem;
		}
	}
`

export const LogSteps = styled.div`
	width: 65%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	@media ${device.mobileL},
		${device.mobileM},
		${device.mobileS} {
		width: 100%;
	}
	@media ${device.tablet},
		${device.laptop},
		${device.laptopL} {
		width: 65%;
	}
`


export const LogSect = styled.div`
	width: 45%;
	margin: 1%;
	flex-direction: row;
	display:flex;
	img{
		height:auto;
		width: 12%;
		margin: 1% 5% 50% 5%;
		@media ${device.laptop} {
			margin: 1% 5% 50% 5%;
		}
		@media ${device.laptopL} {
			margin: 1% 5% 35% 5%;
		}
	}
	&>div{
		width: 70%;
		&>h3{
			color: ${DarkBlue};
			font-weight:600;
		}
		&>p{
			color:${DarkBlue};
		}
	}
`
