import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSiteOrganization } from '../../../API/Sync';
import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';
import codes from '../../Dashboard/UserDashboard/UpdateWidget/requestCodes';
import { Blue, DarkBlue, Yellow2 } from '../../utilities';
import { AccountNeedsAttention, CloseButton } from './UrgentNotifications.style';

const UrgentNotifications = ({
  setAppIsLoading,
  setAppIsNotLoading,
  setWidgetAction,
  urgentNotificationContent,
  setIsUrgentNotificationVisible
}) => {
  const [brandedData, setBrandedData] = useState(null);
  const [gotData, setGotData] = useState(0);

  useEffect(() => {
    if (urgentNotificationContent) {
      getBrandedData();
    };
  }, [urgentNotificationContent])

  const selectWidgetAction = (id) => {
    setAppIsLoading();
    setWidgetAction(id)
  }


  const getBrandedData = async () => {
    const zzz = await Promise.all(urgentNotificationContent.map(async credential => {
      const site = await getSiteOrganization(credential.id_site_organization);
      return [{...site.data.response[0], ...credential}]
    }))
    setBrandedData(...zzz)
    return zzz;
  };

  return (
    <div style={{backgroundColor: DarkBlue, color: Yellow2, position: 'fixed', width: '100vw', height: 'auto', top: 0, padding: '1% 2%', fontSize: '1.2em', zIndex: 9999}}>
      {
        brandedData && brandedData.map(site => {
          return (
            <div>
              <p>Tu cuenta necesita revisión debido a que: {(codes[site.code] && codes[site.code]['description']) ? codes[site.code]['description']: 'hubo un error al actualizar.'}</p>
              <AccountNeedsAttention onClick={() => selectWidgetAction(site.id_site)}>- {site.name}</AccountNeedsAttention>
              <CloseButton onClick={() => setIsUrgentNotificationVisible(false)}>&times;</CloseButton>
            </div>
          )
        }
        )
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading())
  };
};

export default connect(null, mapDispatchToProps)(UrgentNotifications)
