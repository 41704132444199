import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

// REDUCERS
import pageReducer from './user/user.reducer';
import userSliceReducer from './user/user.slice';
import appReducer from './app/app.reducer';
import accountsReducer from './accounts/accounts.slice';
import cardsReducer from './cards/cards.slice';
import transactionsReducer from './transactions/transactions.slice';
import contributionsReducer from './contributions/contributions.slice';
import paymentsReducer from './payments/payments.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

const combinedReducer = combineReducers({
  user: userSliceReducer,
  app: appReducer,
  accounts: accountsReducer,
  cards: cardsReducer,
  transactions: transactionsReducer,
  contributions: contributionsReducer,
  payments: paymentsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'user/resetState') {
    state = ({
      user : {
      currentPage: 'profile',
      isLogged: false
      }
    })
  }
  return combinedReducer(state, action);
};


export default persistReducer(persistConfig, rootReducer);
