import React, { useState, useEffect } from 'react';
import { Input, Select, Radio, Button, message, InputNumber, Tooltip } from "antd";
import { DollarOutlined ,PercentageOutlined , CreditCardOutlined } from '@ant-design/icons';
import * as MxnBanks from 'mxn-banks';

import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.slice';

import { createNewCard, updateCard } from '../../../store/cards/cards.slice';
import { updateAccount } from '../../../store/accounts/accounts.slice';
import { GeneralContainer, AddAccountCotainer } from './AccountModal.style';
import { getImageURL } from '../../Dashboard/UserDashboard/AhorroCompras/BuyingSaving.utils';
import TraditionalSaving from '../../Dashboard/UserDashboard/AhorroTradicional/TraditionalSaving';

const AccountModal = props => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [bankList, setBankList] = useState();
  const [cardFormat, setCardFormat] = useState(true);
  const [partnerSelected, setPartnerSelected] = useState(null);

  const {
    registerKind,
    cardData,
    setCardData,
    modalState,
    onCancel,
    handleOk,
    setHasBeenUpdated,
    setUserAccounts
  } = props;


  useEffect(() => {
    setBankList(MxnBanks.toJson());
  }, []);

  const handleCardChange = (e, field) => {
    let cardCopy = {...cardData};
    if(!e.target){
      setCardData({...cardCopy, [field]: e})
    } else {
      cardCopy[e.target.name] =  e.target.value;
      setCardData(cardCopy)
    }
  }

  const onChangePartner = e => {
    setPartnerSelected(e.target.value);
    setCardData(prevState => ({...prevState, issuingEntity: e.target.value}));
  };

  const handleCardRegister = async () => {
    if (modalState === 'update') {
      let input = {
        id: cardData.id,
        percentage: cardData.percentage,
        maxAmmount: cardData.maxAmmount,
        periodicity: 'Mensual'
      };
      if(
        registerKind === 'credit' ||
        registerKind === 'debit' ||
        cardData?.site?.organization === 'Liverpool'
        ) {
        input = {
          id: cardData.id,
          percentage: cardData.percentage,
          maxAmount: cardData.maxAmmount,
          periodicity: 'Mensual'
        };
        try {
          dispatch(updateAccount(input));
          message.success('Información actualizada exitosamente.');
          setUserAccounts(prevState => prevState)
          setHasBeenUpdated(prevState => !prevState)
          setCardData({})
          onCancel()
          handleOk()
        } catch(error) {
          console.log({error})
          message.error('Ocurrió un error, vuelve a intentarlo.');
        }
      } else {
        try {
          await dispatch(updateCard(input));
          message.success('Información actualizada exitosamente.');
          setUserAccounts(prevState => prevState)
          setHasBeenUpdated(prevState => !prevState)
          setCardData({})
          onCancel()
          handleOk()
        } catch(error) {
          console.log({error})
          message.error('Ocurrió un error, vuelve a intentarlo.');
        }
      }

    } else {
      let input = {
        number: cardData.cardNumber,
        ownerId: user.id,
        issuingEntity: cardData.issuingEntity,
        periodicity: 'Mensual',
        percentage: cardData.percentage,
        maxAmmount: cardData.maxAmmount,
        type: registerKind
      };
      try {
        await dispatch(createNewCard(input))
        message.success('Información registrada exitosamente.');
        if (setHasBeenUpdated) {
          setHasBeenUpdated(prevState => !prevState)
        }
        setCardData({});
        if (onCancel) {
          onCancel();
        }
        handleOk();
      } catch(error) {
        console.log({error})
        message.error('Ocurrió un error, vuelve a intentarlo.');
      }
    }
  };

  const partner =
    <AddAccountCotainer>
      <Radio.Group
        onChange={handleCardChange}
        value={cardData.issuingEntity}
        name="issuingEntity"
        style={{display: 'flex', justifyContent: 'space-evenly'}}
        disabled={modalState === 'update'}
      >
        <Radio value="elpalaciodehierro" style={{display: 'flex', alignItems: 'center'}}>
          <img src="https://aurumresources.s3.amazonaws.com/general/palacio.jpg" alt="El Palacio de Hierro." style={{width: "150px"}}/>
        </Radio>
        <Radio value="sears" style={{display: 'flex', alignItems: 'center'}}>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Sears_Mexico_Logo.svg/1280px-Sears_Mexico_Logo.svg.png" alt="SEARS. Me entiende." style={{width: "150px"}}/>
        </Radio>
      </Radio.Group>
      <p>Numeración</p>
      <Input
        size="large"
        maxLength={16}
        placeholder="16 dígitos tarjeta"
        prefix={<CreditCardOutlined />}
        onChange={handleCardChange}
        defaultValue={cardData.number}
        name="cardNumber"
        disabled={modalState === 'update'}
      />
      <div style={{marginTop: "5%", display: "flex", flexDirection: "row", justifyContent: 'space-evenly'}}>
        <div>
          <p>Porcentaje de los cargos:</p>
          <InputNumber
            size="medium"
            maxLength={3}
            max={100}
            min={1}
            prefix={<PercentageOutlined />}
            name="percentage"
            defaultValue={cardData.percentage}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            onChange={e => handleCardChange(e, "percentage")}
          />
        </div>
        <div>
          <p>Monto límite por periodo:</p>
          <Tooltip title="Monto mínimo: $50.00">
            <InputNumber
              size="medium"
              prefix={<DollarOutlined />}
              min={50}
              name="maxAmmount"
              defaultValue={cardData.maxAmmount}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => handleCardChange(e, "maxAmmount")}
            />
          </Tooltip>
        </div>

      </div>
    </AddAccountCotainer>

  const direct_debit =
    <AddAccountCotainer>
      <TraditionalSaving
        kind="direct_debit"
        setHasBeenUpdated={setHasBeenUpdated}
        handleOk={handleOk}
        onCancel={onCancel}
      />
    </AddAccountCotainer>

  const unique_contribution =
    <AddAccountCotainer>
      <TraditionalSaving
        kind="unique_contribution"
        setHasBeenUpdated={setHasBeenUpdated}
        handleOk={handleOk}
        onCancel={onCancel}
      />
    </AddAccountCotainer>

  const credit =
    <AddAccountCotainer>
      <p>Banco emisor</p>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <img src={getImageURL(cardData.site?.organization ? cardData.site?.organization : cardData.issuingEntity)} alt={cardData.issuingEntity} style={{width: '50%'}}/>

      </div>
      {/* <Select
        name="issuingEntity"
        defaultValue={cardData.issuingEntity}
        onChange={e => handleCardChange(e, "issuingEntity")}
        style={{width: '100%'}}
      >
        {
          bankList?.map((bankRegister, i) => {
            return <Option key={i} defaultValue={bankRegister.abbreviate}>{bankRegister.abbreviate}</Option>
          })
        }
      </Select> */}
      <p>Numeración</p>
      <Input
        size="large"
        maxLength={16}
        placeholder="16 dígitos tarjeta"
        prefix={<CreditCardOutlined />}
        name="cardNumber"
        defaultValue={cardData.number}
        onChange={e=>handleCardChange(e, 'card')}
        disabled={true}
      />
      {!cardFormat &&
        <p style={{color: "red"}}>Formato incorrecto</p>
      }
      <div style={{marginTop: "5%", display: "flex", flexDirection: "row", justifyContent: 'space-evenly'}}>
        <div>
          <p>Porcentaje de los cargos</p>
          <InputNumber
            size="medium"
            maxLength={3}
            max={100}
            min={1}
            prefix={<PercentageOutlined />}
            name="percentage"
            defaultValue={cardData.percentage}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            onChange={e => handleCardChange(e, "percentage")}
          />
        </div>
        <div>
          <p>Monto límite por periodo:</p>
          <Tooltip title="Monto mínimo: $50.00">
            <InputNumber
              size="medium"
              prefix={<DollarOutlined />}
              min={50}
              name="maxAmmount"
              defaultValue={cardData.maxAmmount}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => handleCardChange(e, "maxAmmount")}
            />
          </Tooltip>
        </div>
      </div>

    </AddAccountCotainer>

  return (
    <GeneralContainer>
      {
        console.log({cardData})
      }
      {
        registerKind === "partner" ? partner : registerKind === 'credit' ? credit : registerKind ==='direct_debit' ? direct_debit : registerKind ==='unique_contribution' ? unique_contribution : credit
      }
      {
        registerKind === 'direct_debit' || registerKind === 'unique_contribution' ?
        null :
        <Button
          type="primary"
          onClick={handleCardRegister}
          style={{bottom: 0,backgroundColor:"#FFC200", fontFamily: 'IBM Plex Sans',borderColor:"#FFC200", color:"#103D56", fontSize:"12px",height:"auto",fontWeight:"600", padding:"14px 38px", marginTop: "25px"}}
        >
        { modalState === 'update' ? 'Actualizar' : 'Registrar' }

        </Button>
      }
    </GeneralContainer>
  );
};

export default  AccountModal;
