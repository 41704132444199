import styled from 'styled-components';
import {GrayWhite, White, DarkBlue } from '../../utilities/Colors';
import {device} from '../../utilities/media_queries'

export const AuthContainer = styled.div`
	color: ${White};
	display: flex;
	font-family: 'IBM Plex Sans', sans-serif;
	width: 100%;
	padding: 2%;
	justify-content: space-evenly;
	align-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	text-align: center;
	background-color:${GrayWhite};
    @media ${device.mobileS}{
			font-Size: .8rem;
			margin-top: 18%;
    }
    @media ${device.mobileM}{
			font-Size: .8rem;
			margin-top: 13%;
		}
		@media ${device.mobileL} {
			font-Size: .8rem;
			margin-top: 11%;
    }
    @media ${device.tablet} {
			font-Size: .8rem;
			margin-top: 8%;
    }
    @media ${device.laptop} {
			font-Size: 1rem;
			margin: 0;
    }
    @media ${device.laptopL} {
    	font-Size: 1.2rem;
    }
    @media ${device.desktop} {
			font-Size: 1.8rem;
			margin: 0;
    }
`

export const StepOneLanding = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1%;
	margin: 0;
	justify-content: center;
	width: 40%;
	margin-bottom: 1%;
	&>h2{
		color: ${DarkBlue};
		font-family: 'IBM Plex Sans', sans-serif;
		align-self:left;
		font-weight:700;
		@media ${device.mobileS},
					${device.mobileM},
					${device.mobileL} {
			text-align: center;
		}
		@media ${device.tablet},
					${device.laptop},
					${device.laptopL} {
			text-align: left;
		}
	}
	&>img{
	height: auto;
	width: 90%;
	margin: 0 5%;
	}
    @media ${device.mobileS} {
    font-Size: .8rem;
    width: 100%;
    margin-bottom: 1%;
    }
    @media ${device.mobileM} {
    font-Size: .8rem;
    width: 100%;
    margin-bottom: 1%;
    }
    @media ${device.mobileL} {
    font-Size: .8rem;
    width: 100%;
    margin-bottom: 1%;
		}
    @media ${device.tablet} {
    font-Size: .8rem;
    width: 50%;

    }
    @media ${device.laptop} {
    font-Size: 1rem;
    width: 50%;
    }
    @media ${device.laptopL} {
    font-Size: 1.2rem;
    width: 50%;
    }
    @media ${device.desktop} {
    font-Size: 1.8rem;
    width: 50%;
    }
`
export const AuthCont = styled.div`
    display: flex;
    width: 40%;
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}  {
        width: 90%;
        margin: 0 5%;
    }
    @media ${device.tablet} {
        width: 40%;
        margin: 0 5%;
    }
`
