import React, { useState, useEffect } from 'react';
import {Button, Input, Select, Modal, message, Divider} from "antd";
import { MailOutlined, LockOutlined, DollarOutlined, PhoneOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { updateCurrentUser } from '../../../store/user/user.slice';

const EditModal = ({ user, editCase, isModalVisible, handleOk, handleCancel }) => {
  const dispatch = useDispatch();
  const [contentCase, setContentCase] = useState(null);
	const [dataModal, setDataModal] = useState({});
  const [curpValido, setCurpValido] = useState(true);
	const { Option } = Select;

	const aforeList = [
    "Azteca, S.A. de C.V.",
    "Coppel, S.A. de C.V.",
    "Invercap, S.A. de C.V.",
    "Profuturo AFORE, S.A. de C.V",
    "XXI Banorte, S.A de C.V.",
    "Banamex, S.A. de C.V.",
    "Inbursa, S.A. de C.V.",
    "Sura, S.A. de C.V.",
    "Principal AFORE, S. A. de C.V.",
    "PENSIONISSSTE",
  ];

  useEffect(() => {
    console.log('Ando modificando ')
    setDataModal(user);
  }, [user]);

  useEffect(() => {
    buildEditModal(editCase);
  },[isModalVisible, editCase]);

  /**
   * @param  {string} input
   */
  const validarInput = (input:string) => {

    let curp = input.toUpperCase();
    let isValid = curpValida(curp)
    if (isValid) {
      setCurpValido(true);
    } else {
      setCurpValido(false);
    }
  };

  const curpValida = (curp) => {
    let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    validado = curp.match(re);

    if (!validado) {
        return false;
      } //Coincide con el formato general?

      //Validar que coincida el dígito verificador
      function digitoVerificador(curp17) {
          //Fuente https://consultas.curp.gob.mx/CurpSP/
          let diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
              lngSuma      = 0.0,
              lngDigito    = 0.0;
          for(let i=0; i<17; i++)
              lngSuma= lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
          lngDigito = 10 - lngSuma % 10;
          if(lngDigito == 10)
              return 0;
          return lngDigito;
      }
      if (validado[2] != digitoVerificador(validado[1]))
        return false;

    return true; //Validado
  }

  const handleChangeFormAfore = (e, type = null) => {
    let dataGen = { ...dataModal };
    dataGen['afore'] = e;
    setDataModal(dataGen);
  }

  const handleChangeForm = (e) => {
    console.log({ 'TARGETNAME': e.target.name });
    console.log({ 'TARGETVALUE': e.target.value });
    console.log({ dataModal0: dataModal });
    let dataGen = {};
    if (editCase === 'name') {
      const { name, value } = e.target;
      setDataModal((prevState) => {
        console.log({prevState})
        return {
          [editCase]: {
            ...prevState[editCase],
            [name]: value,
          }
      }});
      return
    }
      if (editCase === 'curp') {
        validarInput(e.target.value)
      }
      dataGen = {
        [e.target.name]: e.target.value,
      };

    setDataModal(dataGen);
	};

  const onModalClose = () => {
    Modal.destroyAll();
    handleCancel();
  };

  const updateData = async () => {
    console.log({ dataModalXYZ: dataModal });
    let input = {}
    if (editCase === 'name') {
      input = {
        id: user.id,
        ...dataModal[editCase],
      };
    } else {
      input = {
        id: user.id,
        [editCase]: dataModal[editCase]
      }
    }
    try {
      const xxx = await dispatch(updateCurrentUser(input));
      console.log({xxx})
      message.success('Información actualizada exitosamente.');
			handleOk();
    }catch(error) {
      console.log({error});
      message.error('Ocurrió un error, vuelve a intentarlo.');
    }
	};

  const buildEditModal = inputCase => {
    switch (inputCase) {
      case 'name':
        setContentCase(
          <>
          <Divider orientation="left">Nombre(s)</Divider>
            <div style={{marginTop: '5px'}}>
              {/* <span>
                <p>Nombre(s):</p>
              </span> */}
              <span>
                <p>{user.name}</p>
              </span>
              <Input
                allowClear
                placeholder="Ingresa tu nombre(s)"
                name="name"
                onChange={handleChangeForm}
              />
            </div>
            <Divider orientation="left">Apellido Paterno</Divider>
            <div style={{marginTop: '5px'}}>
              {/* <span>
                <p>Apellido Paterno:</p>
              </span> */}
              <span>
                <p>{user.lastname1}</p>
              </span>
              <Input
                allowClear
                placeholder="Ingresa tu apellido paterno"
                name="lastname1"
                onChange={handleChangeForm}
              />
            </div>
            <Divider orientation="left">Apellido Materno</Divider>
            <div style={{marginTop: '5px'}}>
              {/* <span>
                <p>Apellido Materno:</p>
              </span> */}
              <span>
                <p>{user.lastname2}</p>
              </span>
              <Input
                allowClear
                placeholder="Ingresa tu apellido materno"
                name="lastname2"
                onChange={handleChangeForm}
              />
            </div>
          </>
        );
        break;
      case 'email':
        setContentCase(
					<>
						<span><p>Correo electrónico:</p></span>
						<span><p>{user.email}</p></span>
              <Input
                allowClear
                placeholder="Ingresa el coreo electrónico"
                prefix={<MailOutlined />}
                name="email"
                onChange={handleChangeForm}
              />
					</>
				);
        break;
      case 'password':
        setContentCase(
					<>
						<p>Contraseña actual:</p>
              <Input.Password
                allowClear
                placeholder="Ingresa la contraseña actual"
                prefix={<LockOutlined />}
                name="password"
                onChange={handleChangeForm}
              />
            <p>Contaseña nueva:</p>
              <Input.Password
                allowClear
                placeholder="Ingresa la nueva contraseña"
                prefix={<LockOutlined />}
                name="password"
                onChange={handleChangeForm}
              />
						<p>Confirma contraseña:</p>
              <Input.Password
                allowClear
                placeholder="Confirma la nueva contraseña"
                prefix={<LockOutlined />}
                name="password"
                onChange={handleChangeForm}
              />
					</>
				);
        break;
      case 'curp':
        setContentCase(
          <>
						<span><p>CURP:</p></span>
						<span><p>{user.curp}</p></span>
              <Input
                allowClear
                value={dataModal.curp}
                placeholder="Ingresa la CURP"
                prefix={<DollarOutlined />}
                name="curp"
                onChange={e => handleChangeForm(e, 'curp')}
              />
          </>
        );
        break;
      case 'phone':
        setContentCase(
          <>
						<span><p>Teléfono:</p></span>
						<span><p>{user.phone}</p></span>
              <Input
                allowClear
                placeholder="Ingresa el teléfono"
                prefix={<PhoneOutlined />}
                name="phone"
                onChange={handleChangeForm}
              />
					</>
				);
				break;
      case 'afore':
        setContentCase(
          <>
            <span>
              <p>AFORE:</p>
            </span>
            <span>
              <p>{user.afore}</p>
            </span>
            <Select
              name="afore"
              onChange={(e) => handleChangeFormAfore(e, 'afore')}
              style={{ width: '50%', margin: '.5% 25%' }}
            >
              {aforeList?.map((aforeName, i) => {
                return (
                  <Option key={i} value={aforeName}>
                    {aforeName}
                  </Option>
                );
              })}
            </Select>
          </>
        );
				break;
			default:
				setContentCase(<></>);
				break;
    }
  };

  return (
    <Modal
			title="Editar"
			visible={isModalVisible}
			onCancel={onModalClose}
			footer={null}
    >
      {contentCase}
              {
            !curpValido && <p style={{color:'red'}}>Formato de CURP no válido. Revisa si el formato es correcto.</p>
          }
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Button type="primary" style={{margin: '5% auto 0 auto',bottom: 0, backgroundColor:"#FFC200", fontFamily: 'IBM Plex Sans',borderColor:"#FFC200", color:"#103D56", fontSize:"12px",height:"auto",fontWeight:"600", padding:"14px 38px"}} onClick={updateData}>
          ACTUALIZAR
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;
