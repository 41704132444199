import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { listContributions, getSpecificContribution, createNewContribution, updateExistentContribution, deleteUniqueContribution } from '../../middlewares/API/contributions/contributions.api';

const contributionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt)
});

// THUNKS
export const listAllContributions = createAsyncThunk(
  'contributions/listAllContributions',
  async (id) => {
    const response = await listContributions(id);
    return response;
  }
);

export const createContribution = createAsyncThunk(
  'contributions/createContribution',
  async input => {
    const response = await createNewContribution(input);
    return response;
  }
);

export const updateContribution = createAsyncThunk(
  'contributions/updateContribution',
  async input => {
    const response = await updateExistentContribution(input);
    return response;
  }
);
export const deleteContribution = createAsyncThunk(
  'contributions/deleteContribution',
  async input => {
    const response = await deleteUniqueContribution(input);
    return response;
  }
);
export const getContributionById = createAsyncThunk(
  'contributions/getContributionById',
  async (id) => {
    const response = await getSpecificContribution(id);
    return response;
  }
);
//

const contributionsSlice = createSlice({
  name: 'contributions',
  initialState: contributionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllContributions.fulfilled]: (state, action) => {
      contributionsAdapter.upsertMany(state, action.payload)
    },
    [createContribution.fulfilled]: contributionsAdapter.upsertOne,
    [updateContribution.fulfilled]: contributionsAdapter.upsertOne,
    [deleteContribution.fulfilled]: contributionsAdapter.removeOne,
    [getContributionById.fulfilled]: contributionsAdapter.upsertOne
  }
});

export default contributionsSlice.reducer;

export const {
  selectAll: selectAllContributions,
  selectById: selectContributionById,
  selectIds: selectContributionIds
} = contributionsAdapter.getSelectors(state => state.contributions);

export const uniqueScheduledContribution = createSelector(
  [selectAllContributions],
  (contributions) => contributions.find(contribution => contribution.scheduled)
);
