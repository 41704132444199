import { getTransactions } from '../../../API/Sync';
import { createNewTransaction } from '../../API/transactions/transactions.api';

export const updateTransactionsToToday = (account, user) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = Number(today.getMonth()) + 1;

  const day = account.lastFetch ? (new Date(Number(account.lastFetch) * 1000)).getDate() : 1;

  const dateFrom = new Date(`${year}-${month}-${day}`);
  const dateParsed = Date.parse(dateFrom).toString().slice(0,10);
  return getTransactions(account.id_user, account.id_account, dateParsed)
  .then(async syncResponse => {
    const transactionsList = syncResponse.data.response;
    if(transactionsList.length > 0) {
      return await Promise.all(transactionsList.map(async transaction => {
        return await createNewTransaction({
          ...transaction,
          ownerId: user.id,
          accountId: account.id
        })
        .then(apiResponse => {
          return apiResponse
        })
        .catch(err => console.log({err}))
      }));
    }
    return [];
  })
  .catch(err => {
    console.log({err})
    return []
  })
};
