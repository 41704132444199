import styled from 'styled-components';
import {DarkBlue, DarkGray, GrayLight, White} from '../../../utilities/Colors'
import {device} from '../../../utilities/media_queries'


export const SideMenuD = styled.div`
  padding: 5% 1%;
  width: 25%;
  height: 96vh;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color:${White};
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: space-between;
  border-radius: 15px;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
    width: 100%;
    height: auto;
    padding-left: 0;
  }
  @media ${device.tablet},{
      width: 30%;
      height: 96vh;
  }
  @media ${device.laptop},{
      padding-left: 2%;
  }
  @media ${device.laptopL} {
      width: 25%;
      height: 96vh;
      padding-left: 4%;
    }

  &>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    text-align: justify;
    @media ${device.tablet} {
      padding:0%;
      margin: 0 1%;
      font-size: .9rem;
    }
    @media ${device.laptop} {
      margin: 0 1%;
      &>div>img{
        display: none;
      }
    }
    @media ${device.laptopL} {
      margin: 0 1%;
      &>div>img{
        display: none;
      }
    }
    &>div>a{
      &>img{
        height: 4vh;
        @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL} {
          margin: 2% 2% 10% 5%
        }
        @media ${device.tablet},
        ${device.laptop},
        ${device.laptopL} {
          margin: 2% 2% 20% 5%
        }
      }
    }
    &>div>img{
      width: 30px;
      height: 33px;
    }
  }

  &>h3{
    color:${DarkBlue};
    padding-left:28%;
    width: 100%;
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
      padding-left:0;
      width: 100%;
    }
    @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      padding-left: 4%;
    }
  }
`
export const NavigationItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      flex-wrap: nowrap;
      width: 100%;
    }
  &>h4{
    display: flex;
    align-items: center;
    color: ${DarkBlue};
    cursor: pointer;
    margin: 5%;
    height: auto;
    text-align: justify;
    width: 100%;
    font-size: .9rem;
    &>img{
      margin-right: 10px;
    }
    &>a{
    color: inherit;
    }
    &img{
      margin-bottom: 15%;
    }
  }
  &>h4:focus{
    font-weight: 500;
    color: red;
  }
`
export const NavigationSection = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

`
