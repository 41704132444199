import styled, { keyframes } from 'styled-components';
import { DarkBlue, Yellow, White} from '../../utilities';
import {device} from '../../utilities/media_queries'

const glowAnimation = keyframes`
  from {
    text-shadow: 0 0 2.5px #fff, 0 0 5px #fff, 0 0 7.5px #ffc200, 0 0 10px #ffce00, 0 0 12.5px #e60073, 0 0 15px #fffbed, 0 0 17.5px #d4af37;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 7.5px #ffc200, 0 0 10px #ffce00, 0 0 12.5px #ffc200, 0 0 15px #ff4da6, 0 0 17.5px #d4af37, 0 0 20px #ffd966;
`;

export const AccountNeedsAttention = styled.p`
  cursor: pointer;
  color: white;
  animation: ${glowAnimation} 1s ease-in-out infinite alternate;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 2em;
  color: $grey;
  cursor: pointer;
  &:hover {
    color: lightgray;
  }
`;