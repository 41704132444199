import styled from 'styled-components'
import { device } from '../../utilities/media_queries';
import { GrayLight2, DarkBlue, Yellow } from '../../utilities/Colors';

export const GeneralDashboard = styled.div`
  background-color: ${GrayLight2};
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      flex-wrap: nowrap;
    }
`;

export const TopMenuD = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${DarkBlue};
  width: 100%;
  margin-top: 0;
  margin: 0;
  text-align: right;
  font-family: 'IBM Plex Sans', sans-serif;
  &>h2{
    color:${Yellow};
    font-weight: 700;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size:.9rem;
    padding: 0 2%;
    margin: auto 0;
  }
`;

export const AgenteButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &>button{
    height: auto;
    border-radius: 5px;
    background-color: #FFC200;
    font-family: 'IBM Plex Sans';
    border-color: #FFC200;
    color: #103D56;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 38px;
  }
`;

export const DashboardUserContainer = styled.div`

`;
