import styled from 'styled-components';
import {DarkBlue, Yellow, White, BabyBlue, BabyYellow, Green, Pink, Violet} from '../../../utilities/Colors';
import { device } from '../../../utilities/media_queries';

export const ProfileInfo = styled.div`
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: column;
  &>h1{
    color:${DarkBlue};
    margin: 3% 0;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
    font-weight:700;
    font-size:3rem;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
      font-size:1.6rem;
      margin: 0 2%;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL},{
      font-size:3rem;
      margin: 0;
    }
  }
  
  @media ${device.tablet} {  
    margin:3% 2%;
    padding-bottom: 5%;
    
    }
    @media ${device.laptop} {   
      margin:3% 2%;
      padding-bottom: 2%;
    } 
    @media ${device.laptopL} {  
      margin:0% 3%;
      padding-bottom: 2%;
      &>h1{
        font-size:3rem;
      }
    } 
    @media ${device.desktop} {   
      margin:0 3%;
      padding-bottom: 2%;
    } 
`

export const GeneralInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: inherit;
  justify-content: space-evenly;
  text-align: center;
  padding: 1% 0;
  width: 100%;
    &>h2{
    border-left:5px solid ${Yellow};
    padding-left: 2%;
    color:${DarkBlue};
    text-align:left;
    width: 70%;
    font-weight: 700;
    font-Size: 1.7rem;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
        font-size:1.2rem;
        margin: 5% 2%;
      }
      @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        font-size:1.7rem;
        magin: 1% 0;
      }
    }
`

export const AditionalInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 35%;
  flex-wrap: wrap;
  background-color:${White};
  box-shadow:5px 10px 5px #2C273814;
  border-radius:10px;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 96%;
    margin: 0 2%;
  }
  @media ${device.tablet}{
    width: 45%;
    margin: 0;
  }
  @media ${device.laptop},
    ${device.laptopL} {  
    width: 35%;
    margin: 0;
  }
  &>div{
    background-color:${Green};
    width: 100%;
    display: grid;
    grid-template-columns:3fr 1fr;
    margin: 3% 4%;
    padding: 3%;
    text-align: left;
    &>p{
      color:${DarkBlue};
    }
    &>img{
      width: 20px;
      align-self:right;
      cursor: pointer;
    }
    &>h3{
      font-weight:700;
      color:${DarkBlue};
      font-size:1rem;
    }
  }
 `

export const PrincipalInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  flex-wrap: wrap;
  border-radius: 15px;
  background-color:${White};
  box-shadow:5px 10px 5px #2C273814;
  padding: 3%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 96%;
    margin: 0 2%;
  }
  @media ${device.tablet}{
    width: 96%;
    margin: 0;
  }
  @media ${device.laptop},
    ${device.laptopL} {  
    width: 70%;
    margin: 0;
  } 
  &>div{
    display: grid;
    grid-template-columns:3fr 1fr;
    margin: 2% 1%;
    color:${DarkBlue};
    text-align: left;
    padding: 1%;
    &p>{
      color:${DarkBlue};
    }
    &>h4{
      color:${DarkBlue};
      font-size:1rem;
    }
    &>img{
      width: 20px;
      align-self: right;;
      cursor: pointer;
    }
    &>h3{
      font-weight:700;
      font-size:1.7rem;
      color:${DarkBlue};
    }
  }
  &>div:nth-child(1){
    background-color:${Green};
    width: 100%;
  }
  &>div:nth-child(2){
    background-color:${BabyBlue};
    width: 48%;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
      width: 98%;
    }
    @media ${device.tablet}{
      width: 48%;
    }
  }
  &>div:nth-child(3){
    background-color:${BabyYellow};
    width: 48%;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
      width: 98%;
    }
    @media ${device.tablet}{
      width: 48%;
    }
  }
  &>div:nth-child(4){
    background-color:${Pink};
    width: 48%;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
      width: 98%;
    }
    @media ${device.tablet}{
      width: 48%;
    }
  }
  &>div:nth-child(5){
    background-color:${Violet};
    width: 48%;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
      width: 98%;
    }
    @media ${device.tablet}{
      width: 48%;
    }
  }
`
