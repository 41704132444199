import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { listPayments, getSpecificPayment, createNewPayment, updateExistentPayment } from '../../middlewares/API/payments/payments.api';

const paymentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt)
});

// THUNKS
export const listAllPayments = createAsyncThunk(
  'payments/listAllPayments',
  async (id) => {
    const response = await listPayments(id);
    return response;
  }
);

export const createPayment = createAsyncThunk(
  'payments/createPayment',
  async input => {
    const response = await createNewPayment(input);
    return response;
  }
);

export const updatePayment = createAsyncThunk(
  'payments/updatePayment',
  async input => {
    const response = await updateExistentPayment(input);
    return response;
  }
);

export const getPaymentById = createAsyncThunk(
  'payments/getPaymentById',
  async (id) => {
    const response = await getSpecificPayment(id);
    return response;
  }
);
//

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: paymentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllPayments.fulfilled]: (state, action) =>{
      paymentsAdapter.upsertMany(state, action.payload)
    },
    [createPayment.fulfilled]: paymentsAdapter.upsertOne,
    [updatePayment.fulfilled]: paymentsAdapter.upsertOne,
    [getPaymentById.fulfilled]: paymentsAdapter.upsertOne
  }
});

export default paymentsSlice.reducer;

export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById
} = paymentsAdapter.getSelectors(state => state.payments);

export const selectLastPeriodsPayments = createSelector(
  [selectAllPayments],
  (payments) => {
    return payments.slice(Math.max(payments.length - 3, 0))
  }
)
