import styled from 'styled-components';
import { DarkBlue, Yellow, Yellow2 } from '../../../utilities';
import { device } from '../../../utilities/media_queries';

export const MainRetirement = styled.section`
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  @media ${device.mobileS} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.desktop} {
  }
`;


export const RetAccountContainer  = styled.div`
  height: auto;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 0 0 5%;
  padding: 10% 0;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 100%;
    margin: 0;
    // background-color: brown;
  }
  @media ${device.tablet},
  ${device.laptop},
  ${device.laptopL}{
    width: 85%;
    margin: 0 3% 0 7%;
    // background-color: teal;
  }
  @media ${device.desktop} {
    margin: 0 5%;
  }
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
    margin-left:0;
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
      font-size: 1.2rem;
    }
    @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL}{
      font-size: 2rem;
    }
  }
  &>p{
    color:${DarkBlue};
    font-size:.9rem;
    font-weight:300;
    
  }
  &>Button{
  margin-top: 5vh;
  margin-left: 15vw;
}
`

export const RetAccountForm = styled.div`
  margin: 0;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 100%;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      width: 90%;
  }
 
  &>p{
    margin: 2% 0;
    color:${DarkBlue};
    font-weight:500;
    width: 100%;
    text-align: left;
    }
  &>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    .ant-select{
      box-shadow:5px 10px 5px #2C273814;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
          width: 100%;
      }
      @media ${device.tablet},
        ${device.laptop},
        ${device.laptopL} {
          width: 50%;
      }
    }
    &>a{
      color:${Yellow2};
      font-weight:700;
      width: 80%;
      margin: 0 2%;
      font-size:.9rem;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
          width: 100%;
          margin: 2% 0;
      }
      @media ${device.tablet},
        ${device.laptop},
        ${device.laptopL} {
          width: 45%;
          margin: 0 2%;
      }
    }
    .ant-input-affix-wrapper {
      color:${Yellow2};
      font-weight:700;
      font-size:.9rem;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
          width: 100%;
      }
      @media ${device.tablet},
        ${device.laptop},
        ${device.laptopL} {
          width: 50%;
      }
    }
  }

`
export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  margin: 1% 0;
`
export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &>Button{
    margin: 3% 3%;
  }
`