export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String!) {
    listUsers( filter: {
      email: {
        contains: $email
      }
    }) {
      items {
        id
        federatedId
        email
        id_pb_user
        role
        owner
        lastFetch
      }
    }
  }
`;

export const getMyAccounts = /* GraphQL */ `
  query GetMyAccounts($ownerId: ID!) {
    listAccounts( filter: {
      ownerId: {
        eq: $ownerId
      }
    }) {
      items {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMyCards = /* GraphQL */ `
  query GetMyCards($ownerId: ID!) {
    listCards( filter: {
      ownerId: {
        eq: $ownerId
      }
    }) {
      items {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        hidden
        cvv
        issuingEntity
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMyContributions = /* GraphQL */ `
  query GetMyContributions($ownerId: ID!) {
    listContributions(
      filter: {
        ownerId: {
          eq: $ownerId
        }
      },
      limit: 500
    ) {
      items {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        contributionStatus
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMyTransactions = /* GraphQL */ `
  query GetMyTransactions($ownerId: ID!, $nextToken: String) {
    listTransactions(
      filter: {
        ownerId: {
          eq: $ownerId
        }
      },
      limit: 999,
      nextToken: $nextToken
      ) {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          currency
          description
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_pending
          extra {
            concept
            balance
            spei_concept
            spei_reference_number
            spei_track_number
            spei_transmitter_name
            spei_transmitter_tax_id
            spei_transmitter_account
            spei_beneficiary_account
          }
          createdAt
          updatedAt
        }
        nextToken
      }
  }
`;

export const getMyPayments = /* GraphQL */ `
  query GetMyPayments($ownerId: ID!) {
    listPayments( filter: {
      ownerId: {
        eq: $ownerId
      }
    }) {
      items {
        id
        ownerId
        federatedOwner
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentProviderResponse
        payedAt
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMyMitOperations = /* GraphQL */ `
  query GetMyMitOperations($ownerId: ID!, $nextToken: String) {
    listMitOperationRequests(
      filter: {
        ownerId: {
          eq: $ownerId
        }
      },
      limit: 999,
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        paymentId
        contributionId
        rawDataRequest
        rawDataResponse
        reference
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
