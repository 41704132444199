import React, { useState, useEffect  } from "react"
import { ContactUsS } from "./ContactUs.Style"
import { Button, Input, Modal } from "antd"
import { UserOutlined, MailOutlined, PhoneOutlined} from '@ant-design/icons';
import axios from "axios"

export default function ContactUs() {
  const [contact] = useState({})

  const Data = e => contact[e.target.name] = e.target.value

  const SendDataToServer = e => {
    e.preventDefault()
    axios.post("https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/contact-us", contact)
      .then( ( ) =>
        Modal.success({
          title: "Envío con éxito",
          content: "Tu mensaje ha sido enviado, en unos momentos te contestaremos",
          onOk() {
            Modal.destroyAll();
          },
          onCancel() {
            Modal.destroyAll();
          },
        })
      )
      .catch( ( ) => Modal.error({title: 'El mensaje no pudo ser enviado'}) )
  }
  useEffect(() => {
		window.scrollTo(0,400)
},[])

  return (
    <ContactUsS id='contacta-a-inkom'>
      <h1>CONTÁCTANOS</h1>
      <h3>Estamos listos para darte las mejores recomendaciones para que alcances tus metas.</h3>
      <div>
        <img style={{margin:"0 5% 0 0"}} src="https://aurumresources.s3.amazonaws.com/NewStyles/panal-shape-izq.svg"/>
        <form onSubmit={SendDataToServer}>
          <p>Nombre completo</p>
          <Input onChange={Data}
            name="name" />
          <p>Correo electrónico</p>
          <Input onChange={Data}
            type="email"
            name="email" />
            <p>Teléfono celular</p>
            <Input onChange={Data}
            type="phone"
            name="telephone" />
          <p>Escribe tu comentario</p>
          <Input.TextArea onChange={Data}
            name="comments" rows="5" cols="50" />
          <br />
          <Button onClick={SendDataToServer}
          style= {{
            color:"#103D56",
            width:"50%",
            margin:"5% 25%",
            backgroundColor:"#FFCE00",
            padding: "3% 0",
            height: "auto",
            fontWeight:"600",
            boxShadow: "0px 4px 8px #2C273814",
            fontSize:".7rem",
          }}>
            ENVIAR MENSAJE
          </Button>
        </form>
        <img style={{margin:"0 0 0 5%"}} src="https://aurumresources.s3.amazonaws.com/NewStyles/panal-shape-der.svg"/>
      </div>
    </ContactUsS>
  )
}