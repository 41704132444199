import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Input, Button,  Modal, Spin, Checkbox } from 'antd';
import { useHistory } from "react-router-dom"

// Local components
import { MainSignup, Error } from './Signup.style';
import { createSyncUser, initUserSession } from '../../../API/Sync'
import { createUser } from '../../../graphql/mutations';

import { connect, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../../store/user/user.slice';
import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';
import { sendWelcomeMail } from '../../utilities/mailHandler';

const Signup = ({ setAppIsLoading, setAppIsNotLoading}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [requireCode, setRequireCode] = useState(false);
  const [requireInvitationCode, setrequireInvitationCode] = useState(false);
  const [ successes, setSuccesses ] = useState({ spin:true });
  const [ errors , setErrors ] = useState({});
  const [ acceptTerms , setAcceptTerms ] = useState(false);

  const history = useHistory();

  const handleFormChanges = e => {
    let formData = {};
    formData = {...data};
    formData[e.target.name] = e.target.value;
    setData({...formData});
  };

  const sendDataToServer = async () => {
    if(!acceptTerms) {
      Modal.error({
        title: 'Es necesario que aceptes "Términos y condiciones" para poder continuar',
        onOk() {
          setSuccesses({spin:true})
          Modal.destroyAll();
        },
        onCancel() {
          setSuccesses({spin:true})
          Modal.destroyAll();
        }
      });
      return
    };
    if(
      data.email === undefined ||
      data.password === undefined ||
      data.password_confirm === undefined
      ) {
        Modal.error({
          title: 'Ingresa tus datos completos',
          onOk() {
            setSuccesses({spin:true})
            Modal.destroyAll();
          },
          onCancel() {
            setSuccesses({spin:true})
            Modal.destroyAll();
          }
        });
      } else if(data.password !== data.password_confirm) {
        setErrors({ "pass_confirm":true });
        return;
      } else {
        setSuccesses({ spin: false })
        setSuccesses({ spin: true })
        setrequireInvitationCode(true)
      };
  };

  const reviewInvitationCode = async () => {
    setSuccesses({spin:true})
    const config = {
      title: 'Lo sentimos, el código de invitación es incorrecto.',
      content: (
        <>
          <br />
          <div>En esta etapa Inkom sólo puede ser utilizada para su evaluación.</div>
          <br />
          <div>Si requires un código de invitación, envíanos un correo a contacto@inkom.mx</div>
        </>
      ),
    };
    const invitationCode = data.invitation_code
    const url = 'https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/invitation-code'

    let isValid = await axios.post(url, JSON.stringify({invitationCode}))
    if(!isValid.data) {
      setSuccesses({ spin: false })
      Modal.warning({
        ...config,
        onOk() {
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        }
      })
      return;
    }
    setSuccesses({ spin: false })
    setrequireInvitationCode(false);
    await Auth.signUp({username: data.email, password: data.password})
      .then( async responseData => {
        setData(prevState => ({...prevState, id_ext: responseData.userSub}));
        await axios.post("https://6113ldyqx6.execute-api.us-east-1.amazonaws.com/dev/aurum/add-user-to-group",{ email: data.email, group:"User" })
          .then( async result => {
            setSuccesses({ spin: true })
            Modal.info({
              title: 'Para completar tu registro, inserta el código que llegó a tu correo',
              onOk() {
                Modal.destroyAll();
              },
              onCancel() {
                Modal.destroyAll();
              }
            })

            setRequireCode(true)
          })
          .catch(err => {
            setRequireCode(false)
          })
      })
      .catch( e  => {
        if(e.message === "An account with the given email already exists.") {
          Modal.error({
            title: 'Este usuario ya existe',
            onOk() {
              Modal.destroyAll();
            },
            onCancel() {
              Modal.destroyAll();
            }
          })
        }
      });

    return;
  }

  const sendCodeToServer = async () => {
    const user = {
      email: data.email,
      password: data.password,
      id_ext: data.id_ext
    };
    await Auth.confirmSignUp(data.email, data.code)
    .then( async result => {
      setSuccesses({spin:true})
      sendWelcomeMail(data.email);
      await createSyncUser(user)
      .then( async syncRes => {
        if (syncRes.data.code === 200) {
          const { id_user } = syncRes.data.response
          Auth.signIn(user.email,user.password)
          .then(() => {
            Auth.currentSession()
            .then( s => {
              const input= {
                id:s.idToken.payload.sub,
                email:user.email,
                id_pb_user: id_user,
                owner: s.idToken.payload.sub
              }

              API.graphql(graphqlOperation( createUser , { input }))
              .then( async response=> {
                await initUserSession(response.data?.createUser?.id_pb_user)
                .then(async responseSession =>  {
                  const { token } = responseSession.data.response;
                  const finalUserData = {
                    ...response.data.createUser,
                    token
                  }
                  setAppIsLoading()
                  await dispatch(setCurrentUser(finalUserData.id));
                  setAppIsNotLoading()
                  //- toDelete///////////////////
                  localStorage.setItem('user', JSON.stringify(finalUserData))
                  /////////////////// toDelete-//
                  history.push("/user-dashboard")
                  return;
                })
              });
            });
          })
        }})
      .catch(error => {
        console.log({error})
        return error.response
      })
      setSuccesses({spin:false})
    })
    .catch( (error) => {
      console.log({error})
      Modal.error({title: 'Código incorrecto'})
    } )
  }

  useEffect(() => {

  }, [errors]);

  return (
    <MainSignup>
    { successes.spin ? (
      <>
      { !requireInvitationCode  && !requireCode ?
        <>
          <p>Correo electrónico</p>
          <Input
            type="email"
            name="email"
            onChange={handleFormChanges}
          />
          <p>Contraseña</p>
          <Input.Password name="password"  onChange={handleFormChanges}/>
          <p>Confirma contraseña</p>
          <Input.Password name="password_confirm" onChange={handleFormChanges} onFocus={() => setErrors({})}/>
        <Checkbox style={{color: "#103D56", margin: "4% 0 2% 0", fontSize:".65rem"}} onChange={() => setAcceptTerms(acceptTerms => !acceptTerms)}>
          Al registrarme, acepto el<a style={{textDecoration:"none", margin:"0 1%", color:"#FFC200", fontWeight:"600"}} href="https://www.facebook.com/legal/terms/update" target="_blank" rel="noopener noreferrer">
          AVISO DE PRIVACIDAD</a> y los<a style={{textDecoration:"none", margin:"0 1%", color:"#FFC200",fontWeight:"600"}}  href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noopener noreferrer">
          TÉRMINOS Y CONDICIONES</a>.</Checkbox>
        </>
        :
        null
      }
      { errors.pass_confirm ? <Error>La contraseña debe ser igual en ambos campos</Error> : null }
      { requireInvitationCode ?
      <>
        <Input name="invitation_code" placeholder="Código de Invitación" onChange ={handleFormChanges}/>
        <Button style={{border:"none", width: "100%", backgroundColor:"#FFCE00", color:"#103D56",fontFamily:"IBM Plex Sans", fontWeight:"700",fontSize:".8rem", padding: "3% 0", height: "auto"}} onClick={reviewInvitationCode}>Verificar código</Button>
      </>
      : requireCode ?
      <>
        <Input name="code" placeholder="Código de verificación" onChange ={handleFormChanges}/>
        <Button style={{border:"none", width: "100%", backgroundColor:"#FFCE00", color:"#103D56",fontFamily:"IBM Plex Sans", fontWeight:"700",fontSize:".8rem", padding: "3% 0", height: "auto"}} onClick={sendCodeToServer}>Verificar código</Button>
      </>
      :
      <>
      <Button style={{border:"none", width: "100%", backgroundColor:"#FFCE00", color:"#103D56",fontFamily:"IBM Plex Sans", fontWeight:"700",fontSize:".8rem", padding: "3% 0", height: "auto", marginBottom: '1%'}} onClick={sendDataToServer}>CREAR CUENTA</Button>
      </>
    }
    <div style={{color: "white"}}>o</div>
        <div
          style={{
            display: 'flex',
            width: "100%",
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
        </div>
      </>
      ) :
      <Spin tip="Cargando..." style={{color:"#FFC200"}} size="large" hidden={successes.spin}/>
      }
    </MainSignup>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect(null, mapDispatchToProps)(Signup);
