import React, { useEffect } from 'react'
import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";
import { initUserSession, getAccounts, getTransactions } from '../../../API/Sync';

import { connect, useDispatch, useSelector } from 'react-redux';
import { createAccount, selectAllAccounts } from '../../../store/accounts/accounts.slice';
import { createTransaction } from '../../../store/transactions/transactions.slice';
import { userSelector } from '../../../store/user/user.slice';
import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';

const InstitutionList = (props) => {
  const user = useSelector(userSelector);
  const accounts = useSelector(selectAllAccounts);
  const dispatch = useDispatch();

  useEffect(() => {
    createWidget()
  }, []);

  const createWidget = async () => {
    let syncWidget;
    let sessionData = await syncSessionData(user);
    syncWidget = new SyncWidget(sessionData.params);

    syncWidget.open();
    syncWidget.$on('success', (data) => {
      setTimeout(() => {
        getUserAccounts({...sessionData.params, user: {...sessionData.user}}, sessionData.user)
        if(props.setHasBeenUpdated) {
          props.setHasBeenUpdated(prevState => !prevState)
        }
        if(props.setViewWidget) {
          props.setViewWidget(false);
        }

        syncWidget.close();
      }, 3000)
    })
    syncWidget.$on('closed', () => {
      if(props.setViewWidget) {
        props.setViewWidget(false);
      }
    });
  };

  const getUserAccounts = async (data, user = null) => {
    try {
      props.setAppIsLoading()
      let accountsPaybookResponse = await getAccounts(data.user.id_pb_user);
      let accountsData = accountsPaybookResponse.data.response;
      Promise.all(accountsData.map(async acc => {
        let exists = accounts.find(account => account.id_account === acc.id_account);
        if(!exists){
          let input = {
            ...acc,
            extra: {
              owner: acc.extra.owner
            },
            ownerId: user.id
          }
          if(acc.account_type === "Checking" || acc.account_type === "Credit Card") {
            try {
              let createdAccount = await dispatch(createAccount({...input}));
              getAccountsTrasactions({...acc, id: createdAccount.payload.id}, data)
            } catch (error) {
              console.log({error})
              props.setAppIsNotLoading()
            }
          }
        };
      }));
      props.setAppIsNotLoading()
    } catch (error) {
      props.setAppIsNotLoading()
      console.log({error});
    }
  };


  const getAccountsTrasactions = async (acc, data) => {
    try {
      const transactionsPaybookResponse = await getTransactions(acc.id_user, acc.id_account);
      const transactionsData = transactionsPaybookResponse.data.response;
      Promise.all(transactionsData.map(async transaction => {
        let input = {
          ...transaction,
          ownerId: user.id,
          accountId: acc.id
        };
        delete input.extra;
        try {
          let yyy = await dispatch(createTransaction({...input}));
        } catch (error) {
          console.log({error});
        }
      }))
    } catch(error) {
      console.log({error});
    }
  };

  const syncSessionData = async (user) => {
    const sessionData = await initUserSession(user.id_pb_user);
    let token = null;
    let params = null;
    if (sessionData.data.code === 200) {
      token = sessionData.data.response.token;
      localStorage.setItem('token', JSON.stringify(token))

      params = {
        token,
        element: "#widget",
        config: {
          locale: "es",
          entrypoint: {
            country: "MX"
          }, navigation: {
            hideAsideMenu: true,
            displaySiteOrganizationTypes: ["Bank"]
          }
        }
      };
    } else {
      console.log("ERROR AL GENERAR TOKEN")
    };
    return {params, user};
  };

  return (
    <div>
      <div id="widget"></div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect(null, mapDispatchToProps)(InstitutionList)
