import React from 'react';
import {Link} from 'react-router-dom';
import {FooterS, FooterSec1, FooterSec2, FooterSec3, FooterHeader} from './Footer.style';

const Footer = () => {
    return (
      <div>
        <FooterS>
          <FooterHeader>
            <div style={{marginLeft: '3%'}}>
              <img alt="Links de ayuda Inkom" src="https://aurumresources.s3.amazonaws.com/inkom/logo_footer.png"/>
            </div>
            <h2>CONTACTO</h2>
          </FooterHeader>
          <FooterSec1>
            <h3>La forma más sencilla, rápida y segura de asegurar tu retiro.</h3>
            <div>
              <a href="https://www.facebook.com/InkomMX" target="_blank" rel="noopener noreferrer"><img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-fb.svg" alt="Ir a facebook de Inkom"/></a>
              <a href="https://www.instagram.com/inkom.mx" target="_blank" rel="noopener noreferrer"><img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-insta.svg" alt="Ir a instagram de Inkom"/></a>
              <a href="https://www.linkedin.com/company/inkom-mx" target="_blank" rel="noopener noreferrer"><img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-linkedin.svg" alt="Ir a linkedin de Inkom"/></a>
            </div>
          </FooterSec1>
          <FooterSec2>
            <div>
              <h4><img src="https://aurumresources.s3.amazonaws.com/NewStyles/Icon-direccion.svg" alt="Dirección física"/>Dirección Insurgentes Sur 1787, piso 2 Guadalupe Inn, Álvaro Obregón, 01020 Ciudad de México, CDMX.</h4>
              <h4><img src="https://aurumresources.s3.amazonaws.com/NewStyles/Icon-mail.svg" alt="Enviar correo electrónico"/>contacto@inkom.mx</h4>
              <h4><img src="https://aurumresources.s3.amazonaws.com/NewStyles/Icon.telefono.svg" alt="Teléfono"/>55 2844 2452</h4>
              <h4><img  src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-whatsapp.svg" alt="Whatsapp"/>55 2844 2452</h4>
            </div>
          </FooterSec2>
          <FooterSec3>
            <h4><Link to="/terminos-y-condiciones" style={{color:"#FFCE00", fontWeight:"600"}}>TÉRMINOS Y CONDICIONES</Link></h4>
            <h4><Link to="/aviso-de-privacidad" style={{color:"#FFCE00", fontWeight:"600"}}>AVISO DE PRIVACIDAD</Link></h4>
            <h4><Link to="/faqs" style={{color:"#FFCE00", fontWeight:"600"}}>PREGUNTAS FRECUENTES</Link></h4>
          </FooterSec3>

        </FooterS>
          <p style={{color: "black",fontSize:".7rem", textAlign: "center", padding:"1% 0"}}>© 2021 Inkom, todos los derechos reservados.</p>
        </div>
    )
}

export default Footer;
