import React, { useState } from 'react';
import { BankOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import {
  RetAccountContainer,
  RetAccountForm,
} from './RetirementAccount.style';

const { Option } = Select;

const RetirementAccount = (props) => {
  const [curpValido, setCurpValido] = useState(true);

  const aforeList = [
    'Azteca, S.A. de C.V.',
    'Coppel, S.A. de C.V.',
    'Invercap, S.A. de C.V.',
    'Profuturo AFORE, S.A. de C.V',
    'XXI Banorte, S.A de C.V.',
    'Banamex, S.A. de C.V.',
    'Inbursa, S.A. de C.V.',
    'Sura, S.A. de C.V.',
    'Principal AFORE, S. A. de C.V.',
    'PENSIONISSSTE',
  ];

  const validarInput = (input) => {
    let curp = input.toUpperCase();
    if (curpValida(curp)) {
      setCurpValido(true);
    } else {
      setCurpValido(false);
    }
  };

  /**
   * @param  {string} curp
   * @returns boolean
   */
  const curpValida: (curp: string) => boolean = function(curp): boolean {
    let re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)
      //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      let diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ',
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (let i = 0; i < 17; i++)
        lngSuma =
          lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }
    if (validado[2] != digitoVerificador(validado[1])) return false;

    return true; //Validado
  };

  const handleChangeForm = (e, type = null) => {
    if (type === 'afore') {
      props.setSelectedAfore(e);
    } else {
      validarInput(e.target.value);
      props.setDestinationAccount(e.target.value);
    }
  };

  return (
    <RetAccountContainer>
      <h2>Registra tu CURP para enviar dinero a tu AFORE</h2>
      <p style={{ width: '90%', marginTop: '20px' }}>
        Con tu CURP enviaremos el dinero que ahorres a la cuenta de
        AFORE en la que estés afiliado.
      </p>
      <RetAccountForm>
        <p>CURP</p>
        <div>
          <Input
            placeholder="Introduce tu CURP"
            prefix={<BankOutlined />}
            name="destinationAccount"
            value={
              props.userData?.curp
                ? props.userData?.curp
                : props.destinationAccount
            }
            disabled={props.userData?.curp ? true : false}
            onChange={(e) => handleChangeForm(e, 'curp')}
          />
          {!curpValido && (
            <p style={{ color: 'red' }}>
              Formato de CURP no válido. Revisa si el formato es
              correcto.
            </p>
          )}
          <a
            href="https://www.gob.mx/curp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}
            CONSULTA TU CURP
          </a>
        </div>
        <p>Selecciona tu afore:</p>
        <div>
          <Select
            name="afore"
            value={
              props.userData?.afore
                ? props.userData?.afore
                : props.selectedAfore
            }
            disabled={
              props.userData?.afore ? props.userData?.afore : null
            }
            onChange={(e) => handleChangeForm(e, 'afore')}
          >
            {aforeList?.map((aforeName, i) => {
              return (
                <Option key={i} value={aforeName}>
                  {aforeName}
                </Option>
              );
            })}
          </Select>
          <a
            href="https://www.e-sar.com.mx/PortalEsar/public/consultaAforeInicio.do"
            rel="noopener noreferrer"
            target="_blank"
          >
            CONSULTA TU AFORE
          </a>
        </div>
      </RetAccountForm>
    </RetAccountContainer>
  );
};

export default RetirementAccount;
