/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      federatedId
      email
      identificator
      globalEntity
      name
      lastname1
      lastname2
      birthDate
      isEmployed
      lastFetch
      id_pb_user
      curp
      rfc
      role
      phone
      estimatedIncome
      issuingEntities
      flowCompleted
      advisor
      afore
      pension_mensual_objetivo
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      owner
      userCards {
        items {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userContributions {
        items {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userAccounts {
        items {
          id
          ownerId
          federatedOwner
          account_type
          balance
          currency
          dt_refresh
          hidden
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_user
          is_disable
          keywords
          name
          number
          createdAt
          owner
          syncAllowed
          lastFetch
          needsToken
          percentage
          maxAmount
          periodicity
          updatedAt
        }
        nextToken
      }
      userTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userPayments {
        items {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      federatedId
      email
      identificator
      globalEntity
      name
      lastname1
      lastname2
      birthDate
      isEmployed
      lastFetch
      id_pb_user
      curp
      rfc
      role
      phone
      estimatedIncome
      issuingEntities
      flowCompleted
      advisor
      afore
      pension_mensual_objetivo
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      owner
      userCards {
        items {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userContributions {
        items {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userAccounts {
        items {
          id
          ownerId
          federatedOwner
          account_type
          balance
          currency
          dt_refresh
          hidden
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_user
          is_disable
          keywords
          name
          number
          createdAt
          owner
          syncAllowed
          lastFetch
          needsToken
          percentage
          maxAmount
          periodicity
          updatedAt
        }
        nextToken
      }
      userTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userPayments {
        items {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      federatedId
      email
      identificator
      globalEntity
      name
      lastname1
      lastname2
      birthDate
      isEmployed
      lastFetch
      id_pb_user
      curp
      rfc
      role
      phone
      estimatedIncome
      issuingEntities
      flowCompleted
      advisor
      afore
      pension_mensual_objetivo
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      owner
      userCards {
        items {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userContributions {
        items {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userAccounts {
        items {
          id
          ownerId
          federatedOwner
          account_type
          balance
          currency
          dt_refresh
          hidden
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_user
          is_disable
          keywords
          name
          number
          createdAt
          owner
          syncAllowed
          lastFetch
          needsToken
          percentage
          maxAmount
          periodicity
          updatedAt
        }
        nextToken
      }
      userTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userPayments {
        items {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExecutionError = /* GraphQL */ `
  mutation CreateExecutionError(
    $input: CreateExecutionErrorInput!
    $condition: ModelExecutionErrorConditionInput
  ) {
    createExecutionError(input: $input, condition: $condition) {
      id
      ownerId
      ownerEmail
      origin
      dependency
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateExecutionError = /* GraphQL */ `
  mutation UpdateExecutionError(
    $input: UpdateExecutionErrorInput!
    $condition: ModelExecutionErrorConditionInput
  ) {
    updateExecutionError(input: $input, condition: $condition) {
      id
      ownerId
      ownerEmail
      origin
      dependency
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteExecutionError = /* GraphQL */ `
  mutation DeleteExecutionError(
    $input: DeleteExecutionErrorInput!
    $condition: ModelExecutionErrorConditionInput
  ) {
    deleteExecutionError(input: $input, condition: $condition) {
      id
      ownerId
      ownerEmail
      origin
      dependency
      data
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      account_type
      balance
      currency
      dt_refresh
      hidden
      extra {
        available
        limit
        minimum
        no_interest
        points
        owner
      }
      id_account
      id_account_type
      id_credential
      id_currency
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_user
      is_disable
      keywords
      name
      number
      site {
        avatar
        cover
        id_site
        id_site_organization
        id_site_organization_type
        name
        organization
        small_cover
        time_zone
      }
      createdAt
      owner
      syncAllowed
      lastFetch
      needsToken
      percentage
      maxAmount
      periodicity
      accountOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      accountTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      account_type
      balance
      currency
      dt_refresh
      hidden
      extra {
        available
        limit
        minimum
        no_interest
        points
        owner
      }
      id_account
      id_account_type
      id_credential
      id_currency
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_user
      is_disable
      keywords
      name
      number
      site {
        avatar
        cover
        id_site
        id_site_organization
        id_site_organization_type
        name
        organization
        small_cover
        time_zone
      }
      createdAt
      owner
      syncAllowed
      lastFetch
      needsToken
      percentage
      maxAmount
      periodicity
      accountOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      accountTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      account_type
      balance
      currency
      dt_refresh
      hidden
      extra {
        available
        limit
        minimum
        no_interest
        points
        owner
      }
      id_account
      id_account_type
      id_credential
      id_currency
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_user
      is_disable
      keywords
      name
      number
      site {
        avatar
        cover
        id_site
        id_site_organization
        id_site_organization_type
        name
        organization
        small_cover
        time_zone
      }
      createdAt
      owner
      syncAllowed
      lastFetch
      needsToken
      percentage
      maxAmount
      periodicity
      accountOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      accountTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      ownerId
      accountId
      paymentId
      federatedOwner
      amount
      extra {
        concept
        balance
        spei_concept
        spei_reference_number
        spei_track_number
        spei_transmitter_name
        spei_transmitter_tax_id
        spei_transmitter_account
        spei_beneficiary_account
      }
      attachments
      currency
      description
      dt_deleted
      dt_disable
      dt_refresh
      dt_transaction
      id_account
      id_account_type
      id_credential
      id_currency
      id_disable_type
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_transaction
      id_user
      is_account_disable
      is_deleted
      is_disable
      is_pending
      keywords
      reference
      createdAt
      owner
      transactionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactionAccount {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        accountOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        accountTransactions {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      ownerId
      accountId
      paymentId
      federatedOwner
      amount
      extra {
        concept
        balance
        spei_concept
        spei_reference_number
        spei_track_number
        spei_transmitter_name
        spei_transmitter_tax_id
        spei_transmitter_account
        spei_beneficiary_account
      }
      attachments
      currency
      description
      dt_deleted
      dt_disable
      dt_refresh
      dt_transaction
      id_account
      id_account_type
      id_credential
      id_currency
      id_disable_type
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_transaction
      id_user
      is_account_disable
      is_deleted
      is_disable
      is_pending
      keywords
      reference
      createdAt
      owner
      transactionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactionAccount {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        accountOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        accountTransactions {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      ownerId
      accountId
      paymentId
      federatedOwner
      amount
      extra {
        concept
        balance
        spei_concept
        spei_reference_number
        spei_track_number
        spei_transmitter_name
        spei_transmitter_tax_id
        spei_transmitter_account
        spei_beneficiary_account
      }
      attachments
      currency
      description
      dt_deleted
      dt_disable
      dt_refresh
      dt_transaction
      id_account
      id_account_type
      id_credential
      id_currency
      id_disable_type
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_transaction
      id_user
      is_account_disable
      is_deleted
      is_disable
      is_pending
      keywords
      reference
      createdAt
      owner
      transactionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactionAccount {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        accountOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        accountTransactions {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      ownerId
      contributionId
      federatedOwner
      completeInfo
      amount
      amountPayed
      medium
      periodDate
      period
      kind
      payed
      paymentMedium
      paymentProviderResponse
      payedAt
      createdAt
      owner
      paymentOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      ownerId
      contributionId
      federatedOwner
      completeInfo
      amount
      amountPayed
      medium
      periodDate
      period
      kind
      payed
      paymentMedium
      paymentProviderResponse
      payedAt
      createdAt
      owner
      paymentOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      ownerId
      contributionId
      federatedOwner
      completeInfo
      amount
      amountPayed
      medium
      periodDate
      period
      kind
      payed
      paymentMedium
      paymentProviderResponse
      payedAt
      createdAt
      owner
      paymentOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      nameInCard
      number
      dueDate
      cvv
      hidden
      issuingEntity
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      pb_reference
      id_pb_card
      percentage
      maxAmmount
      type
      periodicity
      createdAt
      owner
      cardOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      nameInCard
      number
      dueDate
      cvv
      hidden
      issuingEntity
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      pb_reference
      id_pb_card
      percentage
      maxAmmount
      type
      periodicity
      createdAt
      owner
      cardOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      ownerId
      federatedOwner
      nameInCard
      number
      dueDate
      cvv
      hidden
      issuingEntity
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      pb_reference
      id_pb_card
      percentage
      maxAmmount
      type
      periodicity
      createdAt
      owner
      cardOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createContribution = /* GraphQL */ `
  mutation CreateContribution(
    $input: CreateContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    createContribution(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      federatedOwner
      goal
      scheduled
      periodicity
      referenceDate {
        kind
        dateData
      }
      ammount
      paymentMedium
      mitLink
      contributionStatus
      type
      payed
      paymentProviderResponse
      createdAt
      owner
      contributionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        cvv
        hidden
        issuingEntity
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        cardOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        updatedAt
      }
      payment {
        id
        ownerId
        contributionId
        federatedOwner
        completeInfo
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentMedium
        paymentProviderResponse
        payedAt
        createdAt
        owner
        paymentOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateContribution = /* GraphQL */ `
  mutation UpdateContribution(
    $input: UpdateContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    updateContribution(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      federatedOwner
      goal
      scheduled
      periodicity
      referenceDate {
        kind
        dateData
      }
      ammount
      paymentMedium
      mitLink
      contributionStatus
      type
      payed
      paymentProviderResponse
      createdAt
      owner
      contributionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        cvv
        hidden
        issuingEntity
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        cardOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        updatedAt
      }
      payment {
        id
        ownerId
        contributionId
        federatedOwner
        completeInfo
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentMedium
        paymentProviderResponse
        payedAt
        createdAt
        owner
        paymentOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteContribution = /* GraphQL */ `
  mutation DeleteContribution(
    $input: DeleteContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    deleteContribution(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      federatedOwner
      goal
      scheduled
      periodicity
      referenceDate {
        kind
        dateData
      }
      ammount
      paymentMedium
      mitLink
      contributionStatus
      type
      payed
      paymentProviderResponse
      createdAt
      owner
      contributionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        cvv
        hidden
        issuingEntity
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        cardOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        updatedAt
      }
      payment {
        id
        ownerId
        contributionId
        federatedOwner
        completeInfo
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentMedium
        paymentProviderResponse
        payedAt
        createdAt
        owner
        paymentOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createMitOperation = /* GraphQL */ `
  mutation CreateMitOperation(
    $input: CreateMitOperationInput!
    $condition: ModelmitOperationConditionInput
  ) {
    createMitOperation(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      status
      reference
      createdAt
      updatedAt
      mitOperationOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMitOperation = /* GraphQL */ `
  mutation UpdateMitOperation(
    $input: UpdateMitOperationInput!
    $condition: ModelmitOperationConditionInput
  ) {
    updateMitOperation(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      status
      reference
      createdAt
      updatedAt
      mitOperationOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMitOperation = /* GraphQL */ `
  mutation DeleteMitOperation(
    $input: DeleteMitOperationInput!
    $condition: ModelmitOperationConditionInput
  ) {
    deleteMitOperation(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      status
      reference
      createdAt
      updatedAt
      mitOperationOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMitOperationRequest = /* GraphQL */ `
  mutation CreateMitOperationRequest(
    $input: CreateMitOperationRequestInput!
    $condition: ModelMitOperationRequestConditionInput
  ) {
    createMitOperationRequest(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      publicDataResponse {
        foliocpagos
        cd_response
        cd_error
        nb_error
        time
        date
        cc_type
        cc_number
        amount
        number_tkn
        id_url
        cc_mask
      }
      mitOperationStatus
      reference
      createdAt
      updatedAt
      mitOperationRequestOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
export const updateMitOperationRequest = /* GraphQL */ `
  mutation UpdateMitOperationRequest(
    $input: UpdateMitOperationRequestInput!
    $condition: ModelMitOperationRequestConditionInput
  ) {
    updateMitOperationRequest(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      publicDataResponse {
        foliocpagos
        cd_response
        cd_error
        nb_error
        time
        date
        cc_type
        cc_number
        amount
        number_tkn
        id_url
        cc_mask
      }
      mitOperationStatus
      reference
      createdAt
      updatedAt
      mitOperationRequestOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
export const deleteMitOperationRequest = /* GraphQL */ `
  mutation DeleteMitOperationRequest(
    $input: DeleteMitOperationRequestInput!
    $condition: ModelMitOperationRequestConditionInput
  ) {
    deleteMitOperationRequest(input: $input, condition: $condition) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      publicDataResponse {
        foliocpagos
        cd_response
        cd_error
        nb_error
        time
        date
        cc_type
        cc_number
        amount
        number_tkn
        id_url
        cc_mask
      }
      mitOperationStatus
      reference
      createdAt
      updatedAt
      mitOperationRequestOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
