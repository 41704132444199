import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createUser } from '../../../graphql/mutations';
import { getUser } from '../../../graphql/queries';
import { errorHandler } from './index.utilities';

export const signUp = async (email, password) =>  {
  try {
    const { user } = await Auth.signUp({
      email,
      password
    });
    return user;
  } catch (error) {
    console.log('error signing up:', error);
    return error;
  };
};

export const confirmSignUp = async (email, code) => {
  try {
    await Auth.confirmSignUp(email, code);
    return true
  } catch (error) {
    console.log('error confirming sign up', error);
    return false;
  };
};

export const signIn = async (email, password) => {
  try {
    const signInResponse = await Auth.signIn(email, password);
    let { data } = await API.graphql(graphqlOperation(getUser, {id: signInResponse.username}))
    if(!data.getUser) {
      const input = {
        id: signInResponse.username,
        email: signInResponse.attributes.email,
        flowCompleted: false,
        owner: signInResponse.username,
        role: "USER",
      };
      let { data } = await API.graphql(graphqlOperation(createUser, { input },{ authMode:"AMAZON_COGNITO_USER_POOLS" }));
      const user = data.createUser;
      return user;
    }
    const user = data.getUser;
    return user;
  } catch (error) {
    return errorHandler(error, resendConfirmationCode, email);
  }
}

export const signOut = async () => {
  try {
      await Auth.signOut();
  } catch (error) {
      console.log('error signing out: ', error);
  };
};

export const signOutAllDevices = async () => {
  try {
      await Auth.signOut({ global: true });
      return true;
  } catch (error) {
      console.log('error signing out: ', error);
      return false;
  };
};

export const resendConfirmationCode = async (email) => {
  try {
    await Auth.resendSignUp(email);
    console.log('code resent successfully');
    return true;
  } catch (err) {
    console.log('error resending code: ', err);
    return false;
  };
};

export const forgotPassword = async (email) => {
  try {
    await Auth.forgotPassword(email);
    return true;
  } catch (error) {
    console.log('error forgot password: ', error);
    return errorHandler(error);
  };
};

export const registerNewPassword = async (email, code, password) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);
    return true;
  } catch (error) {
    console.log('error recover password: ', error);
    return errorHandler(error, resendConfirmationCode, email);
  };
};

