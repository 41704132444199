import 'react-intl-tel-input/dist/main.css';
import React, { useState, useEffect } from 'react';
import { Input, notification } from 'antd';
import IntlTelInput from 'react-intl-tel-input';

const UpdateData = ({ setPhoneProvidedData }) => {

  useEffect(() => {
    notification['warning']({
      message: 'Completa tu información',
      description:
        'Aún no contamos con tu número celular. Éste es un dato necesario para garantizar la seguridad de tus aportaciones.',
    });
  }, [])

  const handleDataChange = (isValid, value, selectedCountryData, fullNumber, extension) => {
    setPhoneProvidedData(prevState => ({
      isValid,
      value: value.split(' ').join('')
    }))
  };
  return (
    <div >
      <h3>Teléfono</h3>
      <IntlTelInput
        format={true}
        placeholder='222 123 4567'
        defaultCountry='mx'
        onlyCountries={['mx']}
        nationalMode={true}
        containerClassName="intl-tel-input"
        inputClassName="form-control"
        onPhoneNumberChange={handleDataChange}
      />
    </div>
  );
};

export default UpdateData;
