import React, { useState, useEffect, useContext } from 'react';

//Local components
import { MainLogin, PasswordRecovery } from './Login.style';
import Button from '../../UI/Button';
import { confirmSignUp, forgotPassword, signIn, registerNewPassword } from '../../../API/Auth/App';

//External Components
import { Input, Modal, Spin } from 'antd';
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';

// Store
import { connect, useDispatch } from 'react-redux';
import alert from '../../UI/Alert';

import { setCurrentUser } from '../../../store/user/user.slice';
import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';

const Login = ({ setAppIsLoading, setAppIsNotLoading }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [ successes, setSuccesses ] = useState({ spin:true });

  let history = useHistory();

  const handleFormChanges = e => data[e.target.name] = e.target.value;

  const sendDataToServer = async () => {
    try {
      if(data.email === undefined || data.password === undefined) {
        alert({type: 'incompleteData', setSuccesses});
      } else {
        setSuccesses({spin:false});
        let user = await signIn(data.email, data.password);
        if(!user.id) {
          setErrors(user);
          alert({ ...user, setSuccesses });
          return;
        };
        setAppIsLoading()
        await dispatch(setCurrentUser(user.id));
        setAppIsNotLoading()
        //- toDelete///////////////////
        localStorage.setItem('user', JSON.stringify(user));
        /////////////////// toDelete-//
        history.push("/user-dashboard");
        return;
      };
    } catch(error) {
      console.log({error});
    }
  };

  const validateCode = async () => {
    let validation = await confirmSignUp(data.email, data.code);
    if(validation) {
      Modal.success({
        title: 'Has validado con éxito tu correo.',
        content: "Inicia sesión",
        onOk() {
          setSuccesses({spin: true});
          setErrors({});
          Modal.destroyAll();
        },
        onCancel() {
          setSuccesses({spin: true});
          setErrors({});
          Modal.destroyAll();
        }
      })
    }
  };

  const handlePasswordRecoveryRequest = () => {
    setErrors({askedPasswordRecovery: true});
  };

  const handlePasswordRecovery = async () => {
    if(!data.email) {
      alert({type: 'incompleteDataRecovery', setSuccesses});
      return;
    }
    let response = await forgotPassword(data.email);
    if(response.type) {
      alert({...response, setSuccesses});
      setErrors(response);
      return;
    };
    alert({type: 'passwordRecovery', setSuccesses});
    setErrors({recoverPassword: true});
  };

  const handlePasswordChange = async () => {
    if(!data.email || !data.code || !data.newPassword) {
      alert({type: 'incompleteDataRecoveryCode', setSuccesses});
      return;
    };
    if(data.newPassword !== data.newPassword2) {
      alert({type: 'passwordsDontMatch', setSuccesses});
      return;
    };

    let response = await registerNewPassword(data.email, data.code, data.newPassword);
    if(response.type) {
      alert({...response, setSuccesses});
      setErrors(response);
      return;
    };
    alert({type: 'passwordRecoverySuccess', setSuccesses});
    setErrors({});
    setData({});
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(result => history.push("/user-dashboard"))
    .catch(err => console.log("No se ha iniciado sesión"))
  }, [])

  return (
    <MainLogin>
      <Spin tip="Cargando..." style={{color:"#FFC200"}} size="large" hidden={successes.spin}/>
      {
        errors.codeConfirmation && (
        <>
          <p>Código de verificación</p>
          <Input name="code" placeholder="Código" onChange={handleFormChanges}/>
          <Button style={{border:"none", width: "100%", backgroundColor:"#FFCE00", color:"#103D56",fontFamily:"IBM Plex Sans", fontWeight:"700",fontSize:".8rem", padding: "3% 0", height: "auto"}} onClick={validateCode}>Verificar código</Button>
        </>
        )
      }
      {
        errors.recoverPassword && (
        <>
          <p>Correo electrónico</p>
          <p>{data.email}</p>
          <p>Nueva contraseña</p>
          <Input.Password style={{boxShadow: "0px 4px 8px #2C27380A"}} name="newPassword" onChange={handleFormChanges}/>
          <p>Confirmar contraseña</p>
          <Input.Password style={{boxShadow: "0px 4px 8px #2C27380A"}} name="newPassword2" onChange={handleFormChanges}/>
          <p>Código de verificación</p>
          <Input name="code" placeholder="Código" onChange={handleFormChanges}/>
          <Button style={{border:"none", width: "100%", backgroundColor:"#FFCE00", color:"#103D56",fontFamily:"IBM Plex Sans", fontWeight:"700",fontSize:".8rem", padding: "3% 0", height: "auto"}} onClick={handlePasswordChange}>Registrar contraseña</Button>
        </>
        )
      }
      {
        errors.askedPasswordRecovery && (
        <>
          <p>Correo electrónico</p>
          <Input style={{boxShadow: "0px 4px 8px #2C27380A"}} type="email" name="email" onChange={handleFormChanges}/>
          <Button onClick={handlePasswordRecovery}>RECUPERAR CONTRASEÑA</Button>
        </>
        )
      }
      {
        !errors.codeConfirmation &&
        !errors.recoverPassword &&
        !errors.askedPasswordRecovery && (
        <>
          <p>Correo electrónico</p>
          <Input style={{boxShadow: "0px 4px 8px #2C27380A"}} type="email" name="email" onChange={handleFormChanges}/>
          <p>Contraseña</p>
          <Input.Password style={{boxShadow: "0px 4px 8px #2C27380A"}} name="password" onChange={handleFormChanges}/>
            <PasswordRecovery>
              <p onClick={handlePasswordRecoveryRequest}>Olvidé mi contraseña</p>
            </PasswordRecovery>
          <Button onClick={sendDataToServer}>INGRESAR</Button>
            <div
              style={{
                display: 'flex',
                width: "100%",
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
            </div>
        </>
        )
      }
    </MainLogin>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect(null, mapDispatchToProps)(Login);
