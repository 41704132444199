import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';

import { SavingsContainer } from './YourSavings.style';
import AccountCard from '../../../layout/AccountCard/AccountCard';
import AccountModal from '../../../layout/AccountModal/AccountModal';
import CardsTable from '../../../layout/CardsTable/CardsTable';
import { selectAllCards } from '../../../../store/cards/cards.slice';

const YourSavings = (props) => {
  const cards = useSelector(selectAllCards);
  const [cardData, setCardData] = useState({});
  const [visible, setVisible] = useState(false);
  const [tableView, setTableView] = useState({});
  const [medium, setMedium] = useState('');

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const addSpecificMedium = (mediumType) => {
    setMedium(mediumType);
    setVisible(true);
  };

  const handleTableVisibility = (tableType) => {
    setTableView(prevState => ({ ...prevState, [tableType]: !tableView[tableType] }))
  };

  return (
    <SavingsContainer>
      <h2>Selecciona cómo quieres ahorrar</h2>
      <p style={{width: '90%', marginTop: '20px'}}>
      Puedes elegir una o varias formas de ahorrar con tus compras. Recuerda que puedes modificar esta selección en el momento que lo desees en la plataforma de Inkom.
      </p>
      {
        visible && (
          <Modal
            title={medium === 'debit' ? "Agregar cuenta de debito" : medium === "credit" ? "Agregar cuenta de crédito" : "Agregar cuenta de socios"}
            visible={visible}
            style={{height: "auto"}}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <AccountModal
              medium={medium}
              registerKind={medium}
              handleOk={handleOk}
              cardData={cardData}
              setCardData={setCardData}
              style={{margin: "1% 0"}}
            />
          </Modal>
        )
      }
        <>
          <AccountCard
            type="partner"
            title="Tarjeta de Socios"
            description="Gastos relacionados a tu cuenta de socio"
            visible={true}
            addSpecificMedium={addSpecificMedium}
            handleTableVisibility={handleTableVisibility}
          />
          {
            cards.length ?
            <CardsTable
              dataTable={cards}
            /> :
            null
          }
      </>
    </SavingsContainer>
  );
};

export default YourSavings;
