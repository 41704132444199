import styled from 'styled-components';
import { DarkGray, GrayWhite, DarkBlue } from '../../utilities/Colors';
import { device } from '../../utilities/media_queries'

export const WhyContainer = styled.div`
  height: auto;
  width: 100%;
  margin-top: 1%;
  padding: 2% 0;
  color: ${DarkGray};
  background-color: ${GrayWhite};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'IBM Plex Sans', sans-serif;
  z-index:0;
  &>div {
    width: 100%;
    &>h1{
      color:${DarkBlue};
      text-align:center;
      align-self:center;
      justify-content: center;
      font-weight:700;
      font-size:3.5rem;
      @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
        font-Size: 1.8rem;
      }
      @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL} {
        font-Size: 3.5rem;
      }
    }
    &>img{
      width: auto;
      height: 5%;
    }
  }
  /* &>h5::before{
    display: block; 
    content: " "; 
    margin-top: -285px; 
    height: 285px; 
    visibility: hidden; 
    pointer-events: none;
  } */
  @media ${device.mobileS},{
    padding-top: 60px; 
	  margin-top: -40px;
  }
  @media ${device.mobileM}{
    padding-top: 55px; 
	  margin-top: -40px;
  }
  @media ${device.mobileL} {
    padding-top: 50px;
	  margin-top: -30px;
  }
  @media ${device.tablet} {
  font-Size: .8rem;
  margin-top: 1%;
  }
  @media ${device.laptop} {
  font-Size: 1rem;
  margin-top: 1%;
  }
  @media ${device.laptopL} {
  font-Size: 1.2rem;
  margin-top: 1%;
  }
  @media ${device.desktop} {
  font-Size: 1.8rem;
  margin-top: 1%;
}
`
export const CardsContainer = styled.div`
  margin-bottom: 2%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &>div{
    display: flex;
    flex-direction:column;
    padding: 6% 5%;
    width: 45%;
    margin:2% 1%;
    &>h2{
      color: ${DarkBlue};
      font-weight: 500;
      font-size: 1rem;
      padding: 0 5% 0 5%;
    }
    &>p{
      text-align: left;
      color:${DarkBlue};
      font-size: .8rem;
      padding: 0 5% 10% 5%;
      margin-bottom: 10%;
    }
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
      width: 90%;
      margin: 5%;
      padding: 0;
    }
    @media ${device.tablet},
      ${device.laptop} {
      padding: 5%;
      margin:1% 2.5%;
      width: 45%;
      &>h2{
        font-size:.95rem;
        font-weight: 700;
      }
      &>p{
        font-size:.7rem;
      }
    }
    @media ${device.laptopL} {
      padding: 5% 2%;
      margin:1% 2.5%;
      &>h2{
        font-size:1.6rem;
        font-weight: 700;
      }
      &>p{
        font-size:.9rem;
      }
    }
  }
`

export const CardOne = styled.div`
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panalVerdeMobile.png');
      background-size:cover;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panal-verde2.svg');
      background-size:cover;
  }
`
export const CardThree = styled.div`
@media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panalRosaMobile.png');
      background-size:cover;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
    background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panal-rosa2.svg');
    background-size:cover;margin-bottom: 2%;
    }
  `
  export const CardTwo = styled.div`
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panalAzulMobile.png');
      background-size:cover;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
  background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panal-azul2.svg');
  background-size:cover;
  }
`
export const CardFour = styled.div`
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panalMoradoMobile.png');
      background-size:cover;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/panal-morado2.svg');
      background-size:cover;
  }
    
  `