import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Input, InputNumber, Pagination } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import {DashboardWelcome, RetirementAccount, History, HistoryData, BackInformation, Contribution,ContributionDetails} from './Ahorro.style';
import { createPayment, selectAllPayments } from '../../../../store/payments/payments.slice';
import { handleTransactionsAccountsData } from './Ahorro.utils';
import { selectAllContributions } from '../../../../store/contributions/contributions.slice';
import { updateTransaction } from '../../../../store/transactions/transactions.slice';
import NextPayments from './NextPayments/NextPayments';

const Ahorro = props => {
  const dispatch =  useDispatch();
  const payments = useSelector(selectAllPayments);
  const contributions = useSelector(selectAllContributions);

  const [ seeDetails, setSeeDetails] = useState(true);
  const [accumulatedSavings, setAccumulatedSavings] = useState(1);
  const [expectedSavings, setExpectedSavings] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchContributionsData()
  }, [contributions]);

  const calculateSavingsSum = (contMap) => {
    let realSum = 0;
    let expectedSum = 0;
    contMap.forEach((cont, i) => {
      if (cont.payed) {
        realSum+=parseFloat(cont.ammount)
      }
      expectedSum+=parseFloat(cont.ammount)
    });
    return {
      expectedSum,
      realSum
    };
  };

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  /**
   * @param  {} accounts
   * @param  {} transactions
   * @param  {} userId
   * @TODO use this function again
   */
  const createPreviousAccountStatements = async (accounts, transactions, userId) => {
    let {transactionsResume, accountsInfo } = await handleTransactionsAccountsData(accounts, transactions);
    for(const month in transactionsResume) {
      // if(month !== '8' || month !== '9') {
      //   continue;
      // }
      let input =  {
        ownerId: userId,
        amount: Math.abs(transactionsResume[month].totalSaving),
        period: month,
        payed: false,
        federatedOwner: JSON.stringify(accountsInfo[month])
      };
      let createdPayment = await dispatch(createPayment(input))
      let thisPeriodTransactions = transactionsResume[month].movements;
      await Promise.all(thisPeriodTransactions.map(async (singleTransaction, i) => {
        const forUpdateTransactionInput = {
          id: singleTransaction.id,
          paymentId: createdPayment.payload.id
        }
        await dispatch(updateTransaction(forUpdateTransactionInput))
      }))
    }
  }

  const currencyFormat = data => {
    return `$ ${data}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  };

  const fetchContributionsData = () => {
    let {expectedSum, realSum} = calculateSavingsSum(contributions);
    props.handleInfoChanges()
    setAccumulatedSavings(realSum);
    setExpectedSavings(expectedSum)
  };

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const showDetails = _=> setSeeDetails(false)
  const hideDetails = _=> setSeeDetails(true)


  return !contributions ?
    <div>Cargando...</div>
    :
    <BackInformation>
    <DashboardWelcome>
      <div>
        <h1>¡Hola!</h1>
        <p>Has elegido la forma más sencilla, segura y rápida de asegurar tu retiro.</p>
      </div>
      <img src="https://aurumresources.s3.amazonaws.com/NewStyles/img-bienvenida%402x.png" alt="img-bienvenida"/>
    </DashboardWelcome>
    <h2>Tu ahorro con Inkom</h2>
    <RetirementAccount>
      <div>
        <p>Ahorro Real</p>
        <h3>{currencyFormat((accumulatedSavings).toFixed(2))}</h3>
        <p>acumulado con Inkom</p>
      </div>
      <div>
        <p>Saldo</p>
        <h3>$0.00</h3>
        <p>disponible al día de hoy</p>
      </div>
      <div>
        <p>Ahorro Esperado</p>
        <h3>{currencyFormat((expectedSavings).toFixed(2))}</h3>
        <p>por aportaciones con Inkom</p>
      </div>
    </RetirementAccount>
    <h2>Últimos períodos</h2>
    <NextPayments payments={payments}/>
    <h2>Historial</h2>
    <History>
      <HistoryData>
        <div>
          <h4>MONTO</h4>
          <h4>FECHA</h4>
          <h4>ESTATUS</h4>
          <h4>MÁS</h4>
        </div>
        <>
        {
          contributions ?
          contributions.length ?
          contributions.slice((page * 5) - 5, page * 5).map((contribution, index) => (
          <>
            <Contribution>
              <InputNumber
                disabled={true}
                placeholder="$0.00"
                value={contribution.ammount}
                formatter={value => formatter.format(value)}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{width:"20%",}}
              />
              <Input
                disabled={true}
                placeholder="00/00/0000"
                value={moment(`${contribution.referenceDate.dateData.slice(0,4)}-${contribution.referenceDate.dateData.slice(5,7)}-${contribution.referenceDate.dateData.slice(8,10)}`).format("YYYY-MM-DD")}
                style={{width:"20%", }}
              />
              <Input
                disabled={true}
                value={contribution.contributionStatus == 2 ? 'Pagado' : contribution.contributionStatus == 3 ? 'Error' : contribution.contributionStatus == 4 ? 'Emisor rechazó' : 'Pendiente'}
                placeholder="Pendiente"
                style={{width:"20%"}}
              />
              {
                !seeDetails ?
                <MinusCircleOutlined onClick={hideDetails} style={{width:"20%", color:"#515151", textAlign:"left"}}/> :
                <PlusCircleOutlined onClick={showDetails} style={{width:"20%", color:"#515151", textAlign:"left"}}/>
              }
            </Contribution>
            <ContributionDetails>
              {
                !seeDetails ?
                <>
                  <h4>Número de cuenta</h4>
                  <h4>Disponibilidad</h4>
                  <Input
                    disabled={true}
                    placeholder="0000-0000-0000-****"
                    value={contribution.reference ? contribution.reference :  "No Aplica"}
                    style={{width:"30%", margin:"0 1%"}}
                  />
                  <Input
                    disabled={true}
                    placeholder="..."
                    value={contribution.goal.toUpperCase()}
                    style={{width:"30%", margin:"0 1%"}}
                  />
                </>
                :
                <></>
              }
              </ContributionDetails>
            </>
          )) :
          <div>
            <h3>Aun no cuentas con estados de cuenta anteriores.</h3>
          </div>
          :
          <div>Cargando...</div>
        }
        <Pagination current={page}  onChange={handlePageChange}  total={contributions.length} defaultPageSize={5} />
        </>
      </HistoryData>
    </History>
  </BackInformation>
};

export default Ahorro;
