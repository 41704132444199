import React, {useEffect} from 'react'
import {AvisoContainer} from './AvisoPrivacidad.style'
import LandingMenu from '../../../LandingPage/LandingMenu/LandingMenu'

export default function AvisoPrivacidad() {
    useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    return (
        <>
        <AvisoContainer>
					<h1>AVISO DE PRIVACIDAD DE INKOM</h1>
					<p>Debido a que la contratación de los servicios ofrecidos por INKOM se realiza a través de su plataforma electrónica, al ingresar sus datos para el acceso a la misma, usted acepta el tratamiento que a sus datos personales dará INKOM de acuerdo con el presente aviso de privacidad.
							En este sentido, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante, LEY), INKOM pone a su disposición el presente Aviso de Privacidad en los siguientes términos:</p>
					<h2>Responsable de los datos personales</h2>
					<p>Neuralium S. de R.L. de C.V. (en adelante, INKOM), con domicilio en Magdalena 137 interior 1, Colonia del Valle, Benito Juárez, CDMX, México, CP 03103, se considera como responsable de la obtención, divulgación, almacenamiento, uso, incluido acción de acceso, manejo, aprovechamiento, transferencia o disposición de datos personales (en adelante el "Tratamiento").
							Los datos personales de los usuarios serán mantenidos en estricta confidencialidad, de conformidad con las medidas de seguridad, administrativas, técnicas y físicas que al efecto INKOM ha implementado y las que en el futuro implemente en sus políticas y procedimientos de seguridad, quedando prohibido su divulgación ilícita y limitando su uso a terceros, conforme a lo previsto en el presente Aviso de Privacidad.
							El Encargado es la persona que trata los datos personales del usuario por cuenta del responsable y será en todo tiempo designado por el representante legal de INKOM.
					</p>
					<h2>Información recabada y finalidades del tratamiento de datos personales</h2>
					<ul>Le informamos que INKOM podrá recopilar su información a través de diferentes fuentes, ya sea de forma personal y/o directamente proporcionados por el titular, ya sea a través de medios físicos o electrónicos, o bien, indirectamente por cualquier otra fuente de información comercialmente disponibles o que sean permitidas por la LEY. En este sentido, la información que INKOM recabará será la siguiente:
						<li>Nombre de usuario</li>
						<li>CURP</li>
						<li>Correo electrónico</li>
						<li>Domicilio</li>
						<li>Teléfono</li>
						<li>Nombre de la Administradora de Fondos para el Retiro (AFORE) en la que el usuario tenga cuenta.</li>
						<li>Información financiera obtenida a través de la plataforma Paybook, Inc, tal como Nombre del banco y/o Institución Financiera; Número de cuenta o en su caso Número de Tarjeta de Crédito, y el historial de transacciones de compra.</li>
					</ul>
					<ul>La colecta y tratamiento de datos personales y financieros proporcionados por el usuario y los que se generen con motivo de los servicios solicitados a INKOM, y salvo que el titular disponga lo contrario mediante el medio descrito en el presente Aviso de Privacidad, tiene como finalidad, entre otras las siguientes:
						<li>Para generar un canal de intermediación que permita realizar las transferencias de recursos entre las instituciones bancarias en las cuales el usuario se encuentre afiliado y la institución encargada de administrar su cuenta de AFORE. Lo anterior permitirá transferir los montos de los cargos periódicos autorizados por el usuario a su cuenta de AFORE, así como las aportaciones ocasionales que se lleguen a generar y que sean autorizadas por el cliente.</li>
						<li>Para el envío de notificaciones sobre movimientos y saldos, únicamente sobre los realizados a través de la plataforma electrónica de INKOM.</li>
						<li>Para poner a su disposición el estado de cuenta que se genere con los saldos y movimientos que se realizan únicamente a través de la plataforma electrónica INKOM. </li>
						<li>Para brindarle información relacionada con los servicios, costos y disponibilidad de los servicios ofrecidos por INKOM.</li>
						<li>Para realizar evaluaciones periódicas de la calidad del servicio prestado por INKOM.</li>
						<li>Prestar servicios de atención al cliente.</li>
						<li>Para la integración de bases de datos con fines estadísticos o administrativos.</li>
						<li>Para la realización de cobros por los servicios contratados con INKOM.</li>
						<li>Para el cumplimiento de las obligaciones contractuales y/o legales que se deriven de la contratación de los servicios ofrecidos por INKOM.</li>
					</ul>
					<h2>Datos sensibles</h2>
					<p>INKOM no solicita datos considerados como sensibles en los términos dispuestos por la LEY.</p>
					<h2>Opciones y medios que INKOM ofrece a los titulares de datos personales para limitar el uso o divulgación de su información</h2>
					<p>En cualquier momento el titular de datos personales puede limitar el uso o divulgación de sus datos personales y, podrá revocar el consentimiento otorgado a INKOM para el tratamiento de sus datos personales (siempre y cuando el tratamiento que se lleve a cabo con los datos personales no sea un tratamiento necesario o que resulte de una relación jurídica). </p>
					<h2>Medios para el ejercicio de derechos del titular</h2>
					<p>El titular de los datos personales recabados por INKOM puede en todo momento, ejercitar los derechos de acceso, rectificación, cancelación y oposición (en adelante, Derechos ARCO) respecto de sus datos personales.
						El ejercicio de los Derechos ARCO se podrá llevar a cabo a través del envío de una solicitud, en los términos establecidos por la LEY, o el envío del formato para el ejercicio de Derechos ARCO, disponible en inkom.mx al domicilio citado a continuación:</p>
					<br/>
					<h4>Neuralium, S. de R.L. de C.V.
							Avenida Magdalena 137, interior 1,
							Alcaldía Benito Juárez C.P. 03103,
							Ciudad de México, México.
					</h4>
					<p>O por correo electrónico a la siguiente dirección:
					<a style={{textDecoration:"none", color:"#2A81BA", fontWeight:"400"}} alt="mail" href="mailto:contacto@inkom.mx">contacto@inkom.mx</a>.</p>
					<ul>La solicitud a que se refiere el presente apartado deberá contener la siguiente información:
						<li>(i) El nombre completo del titular de los datos personales, domicilio y cuenta de correo electrónico, para poder comunicarle la respuesta a su solicitud; </li>
						<li>(ii) Los documentos que acrediten la identidad y en su caso la representación legal del titular de los datos personales para lo cual deberá adjuntar una fotocopia de una identificación oficial y, en caso actuar en representación de un tercero, deberá presentar copia de la escritura pública del poder o carta poder simple otorgada al representante legal del titular, según corresponda, así como su identificación oficial correspondiente;</li>
						<li>(iii) La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados;</li>
						<li>(iv) Cualquier otro elemento o documento que facilite la localización de los datos personales; y </li>
						<li>(v) En el caso de solicitudes de rectificación de datos personales, el titular, deberá indicar, las modificaciones a realizarse y aportar la documentación que sustente su petición.</li>
					</ul>
					<p>Adicionalmente,  puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de su información personal (siempre y cuando el tratamiento que se lleve a cabo con los datos personales no sea un tratamiento necesario o que resulte de una relación jurídica).
					</p>
					<h2>Transferencia de datos personales</h2>
						<p>El titular de datos personales que presenta cualquier solicitud para entablar una relación jurídica con INKOM o por cualquiera de sus empresas, acepta la transferencia de sus datos personales, en los términos descritos en el presente Aviso de Privacidad.
								En este sentido, INKOM podrá realizar la transferencia de datos a: (i) Las empresas afiliadas y subsidiarias de INKOM; (ii) Terceros proveedores de servicios para el cumplimiento de las obligaciones legales adquiridas por INKOM o por cualquiera de sus empresas afiliadas y subsidiarias; (iii) Terceros proveedores de servicios de investigación, análisis de datos, envío de información enfocada a las necesidades del titular de datos personales, así como en la realización de otros servicios necesarios o requeridos por el titular de datos personales; (iv) Socios comerciales con los que INKOM o cualquiera de sus empresas lleve a cabo convenios o contratos enfocados al desarrollo de nuevos productos y/o servicios enfocados al mejoramiento y de acuerdo a sus necesidades e investigación, siempre y cuando se encuentren relacionadas con los servicios ofrecidos por INKOM; (v) Terceros derivado de un reestructura corporativa, incluyendo, la fusión, consolidación, venta, liquidación o transferencia de activos; y (vi) Otras transmisiones previstas en la LEY y su Reglamento.
								Los terceros y las entidades receptoras de datos personales, asumen las mismas obligaciones y/o responsabilidades de INKOM, de conformidad con lo descrito en el presente Aviso de Privacidad.
								Le informamos que sus datos personales no se venderán o transferirán a terceros, nacionales o extranjeros, que no guarden relación con o actúen conforme a las instrucciones de INKOM, si no se cuenta con su consentimiento previo.
						</p>
					<h2>Cambios al aviso de privacidad</h2>
					<p>INKOM se reserva el derecho de realizar modificaciones al presente aviso de privacidad, las cuales se verán reflejadas en su sitio web, por lo que recomendamos que revise el mismo con regularidad para conocer cualquier cambio.
							Sin perjuicio de lo anterior también podrá ser informado de dichos cambios o modificaciones a través de cualquiera de los siguientes medios: (i) Avisos en las oficinas de INKOM; (ii) Avisos publicados en el sitio web de INKOM www.inkom.mx, y (iii) Cualquier otro medio de comunicación público o privado previstos en los contratos que amparan las operaciones celebradas entre INKOM o por cualquiera de sus empresas y el usuario.
					</p>
					<h5>Fecha de emisión o última actualización: 28 de agosto de 2020</h5>
        </AvisoContainer>
        </>
    )
}
