import styled from 'styled-components'

const DashboardAdminPrincipalS = styled.section`
  font-family: 'Rambla';
  font-size: 1.3rem;
  display: flex;
  margin: 1% 0 5% 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 5% 0 0 0;
  /* margin-top: 10%; */
  color: black;
  background-color: #ffffff;
  &>img{
    height: 30vh;
    width: auto;
  }
@media only screen and (max-width:600px) {
  /* margin : 27% 0 1% 0; */
}
@media only screen and (min-width:600px) {
  /* margin : 15% 0 1% 0; */
}
@media only screen and (min-width: 768px) {
  /* margin : 12% 0 1% 0; */
}
@media only screen and (min-width:922px) {
  /* margin : 12% 0 1% 0; */
}
@media only screen and (min-width: 1200px) {
  /* margin : 9% 0 1% 0; */
}
`

const DashboardAdminContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2%;
  flex-wrap: wrap;
  width: 80vw;
  height: auto;
  &>button{
    background-color: gray;
    border-radius: 10px;
    color: white;
  }
  &>button:hover{
    cursor: pointer;
    background-color: #E63200;
    border-radius: 10px;
  }
`

export {DashboardAdminPrincipalS, DashboardAdminContainer}