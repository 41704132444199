import React, {useEffect, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../../store/user/user.slice';
import InkomLoader from '../../../assets/InkomLoader.png';
import { useHistory } from 'react-router-dom';
import { GeneralLoader } from './Checker.style';
import validateFederated from '../../utilities/validateForFederatedProviders';
import checkAuthenticatedUser from '../../utilities/checkAuthenticatedUser';
// import { setCurrentUser } from '../../../store/user/user.actions';
import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';

const Loader = (props) => {
  const dispatch = useDispatch();
  let {
    setAppIsLoading,
    setAppIsNotLoading
  } = props;

  let history = useHistory();

  useEffect(() => {
    checkFederatedUser()
  }, [])

  const checkFederatedUser = async () => {
    try {
      let userData = await checkAuthenticatedUser(history)
      setAppIsLoading()
      await dispatch(setCurrentUser(userData.id));
      setAppIsNotLoading()
      //- toDelete///////////////////
      // dispatch({ type: 'set_user', payload: { user }});
      localStorage.setItem('user', JSON.stringify(userData));
      /////////////////// toDelete-//
      history.push("/user-dashboard");
      return;
    } catch(error) {
      console.log({error});
    }
  };

  return (
    <>
      <GeneralLoader {...props}>
        <img src={InkomLoader} alt="Cargando página"/>
        <h1>Validando usuario</h1>
      </GeneralLoader>
      <div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect(null, mapDispatchToProps)(Loader);
