import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Spin, message, Modal, Input } from "antd";

import { setAppLoading, setAppNotLoading } from '../../../store/app/app.actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setCurrrentDashboardPage, updateCurrentUser, userSelector, pageSelector } from '../../../store/user/user.slice';
import { listAllAccounts } from '../../../store/accounts/accounts.slice';
import { listAllCards } from '../../../store/cards/cards.slice';
import { listAllContributions } from '../../../store/contributions/contributions.slice';
import { listAllTransactions } from '../../../store/transactions/transactions.slice';

import { SyncOutlined } from '@ant-design/icons';
import { DashboardUserContainer, GeneralDashboard, TopMenuD, AgenteButton } from './UserDashboard.style';
import SideMenuD from './DashboardMenu/DashboardMenu';

import Ahorro from './Ahorro/Ahorro';
import Recommendation from './Recommendation/Recommendation';
import Profile from './Profile/Profile';
import TraditionalSaving from './AhorroTradicional/TraditionalSaving';
import BuyingSaving from './AhorroCompras/BuyingSaving';
import DataModal from '../../layout/DataModal/DataModal.js';
import PartnerCard from './AhorroCompras/PartnerCard/PartnerCard';
import checkAuthenticatedUser from '../../utilities/checkAuthenticatedUser';
import DetalleInkom from './DetalleInkom/DetalleInkom';
import UpdateWidget from './UpdateWidget';
import UrgentNotifications from '../../layout/UrgentNotifications';
import { listAllPayments } from '../../../store/payments/payments.slice';

const antIcon = <SyncOutlined style={{ fontSize: 24 }} spin />;

const UserDashboard = ({
  setAppIsLoading,
  setAppIsNotLoading,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const dashboardPage = useSelector(pageSelector);
  const [infoChanges, setInfoChanges] = useState(false);
  const [userLoggedData, setUserLoggedData] = useState(false);
  const [completeProfile, setCompleteProfile] = useState(false);
  const [visible, setVisible] = useState(false);
  const [agenteVisible, setAgenteVisible] = useState(false);
  const [userAdvisor, setUserAdvisor] = useState(null);

  const [isUrgentNotificationVisible, setIsUrgentNotificationVisible] = useState(false);
  const [urgentNotificationContent, setUrgentNotificationContent] = useState(null);
  const [widgetAction, setWidgetAction] = useState(null);
  const [widgetVisible, setWidgetVisible] = useState(true);

  let history = useHistory();
  const openAgenteModal = () => { setAgenteVisible(true)}
  const handleAdvisor = e => {
    let advisorCopy = userAdvisor;
    advisorCopy= e.target.value;
    setUserAdvisor(prevState => advisorCopy);
  };

  const handleOk = async () => {
    let input = {
      id: user.id,
      advisor: userAdvisor
    };
    try {
      dispatch(updateCurrentUser(input))
      setAgenteVisible(false)
    } catch(error) {
      console.log({error})
    }
  }
  const handleCancel = () => { setAgenteVisible(false)}

  const infoNotCompleted = () => {
    message.error({
      content: 'Aún nos hace falta tu CURP. Ve a "Usuario" para registrar esta info',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  }

  const showModal = (kind) => {
    setVisible(true);
  };

  const changePage = (pageName) => {
    setAppIsLoading();
    setTimeout(() => {
      setAppIsNotLoading();
    }, 800)
    dispatch(setCurrrentDashboardPage(pageName));
  };

  const checkIfThereisUser = async () => {
    checkAuthenticatedUser(history);
    console.log("Inicio de sesión exitoso.")
  };

  const handleInfoChanges = () => {
    setInfoChanges(prevstate => (!prevstate))
  };

  const check = async () => {
    Auth.currentAuthenticatedUser()
      .then(result => {
        // console.log("Data synchronization")
        setUserLoggedData(result)
      })
      .catch(err => {
        console.log({err})
        history.push('/')
      })
    if(user) {
      try {
        await dispatch(listAllAccounts(user.id))
        await dispatch(listAllCards(user.id));
        await dispatch(listAllContributions(user.id));
        await dispatch(listAllTransactions(user.id));
        await dispatch(listAllPayments(user.id));
      } catch(err) {
        console.log({err})
      }
    }
  }

  useEffect(() => {
    check()
  }, [user])

  useEffect(() => {
    checkIfThereisUser()
  },[ completeProfile, infoChanges])

  useEffect(() => {
    checkIfThereisUser();
  }, [user])

  useEffect(() => {
    setWidgetVisible(widgetAction)
  }, [widgetAction])

  return (
    !user ?
    <Spin indicator={antIcon} />
    : <div>
      <TopMenuD>
      {console.log({userLoggedData})}
      {/* USER ADMIN WILL REMAIN PENDANT TILL COMPLETED THIS FIRST SECTION */}
        {
          userLoggedData?.signInUserSession?.accessToken?.payload['cognito:groups'].includes('Admin') ?
          <h2 style={{cursor: 'pointer'}} onClick={() => history.push('/admin-dashboard')}>Dashboard administrador</h2> :
          null
        }
        {
          widgetVisible && (
            <UpdateWidget
              widgetAction={widgetAction}
              setWidgetAction={setWidgetAction}
              setIsUrgentNotificationVisible={setIsUrgentNotificationVisible}
              setUrgentNotificationContent={setUrgentNotificationContent}
            />
          )
        }
        {/* <div style={{display: 'flex'}}> */}
          {/* <h2 style={{cursor: 'pointer'}} onClick={openAgenteModal}>REPORTE SEMANAL</h2> */}
          <h2 style={{cursor: 'pointer'}} onClick={openAgenteModal}>AGENTE XXI BANORTE</h2>
        {/* </div> */}
          <Modal
            visible={agenteVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{fontFamily:"IBM Plex Sans", fontSize:"1rem", color: '#103d56'}}
          >
            <h2 style={{fontFamily:"IBM Plex Sans", color: '#103d56'}}>Registro de clave</h2>
            <p style={{fontFamily:"IBM Plex Sans", color: '#103d56'}}>Clave de agente comercial</p>
            {
              user?.advisor ?
              <Input name="advisor" value={user.advisor} onChange={handleAdvisor} style={{fontFamily:"IBM Plex Sans", color: 'rgba(16, 61, 86, .6)', marginBottom: '5%'}}/>
            : <Input name="advisor" placeholder="Ingresa clave de asesor" onChange={handleAdvisor} style={{fontFamily:"IBM Plex Sans", color: 'rgba(16, 61, 86, .6)', marginBottom: '5%'}}/>
            }
            <AgenteButton>
              <button >Guardar</button>
            </AgenteButton>
          </Modal>
      </TopMenuD>

      <GeneralDashboard>
      {
        isUrgentNotificationVisible && (
          <UrgentNotifications
            setWidgetAction={setWidgetAction}
            urgentNotificationContent={urgentNotificationContent}
            setIsUrgentNotificationVisible={setIsUrgentNotificationVisible}
          />
        )
      }
        <SideMenuD completeProfile={completeProfile} setCompleteProfile={setCompleteProfile} showModal={showModal} changePage={changePage}/>
          {
            dashboardPage === "ahorro" ?
              <Ahorro  changePage={changePage} handleInfoChanges={handleInfoChanges}/> :
            dashboardPage === "recommendations" ?
              <Recommendation changePage={changePage}/> :
            dashboardPage === "buyingSaving" ?
              <BuyingSaving changePage={changePage}/> :
            dashboardPage === "myAccounts" ?
              <DetalleInkom changePage={changePage}/> :
            <Profile />
          }
        <DashboardUserContainer>
          {
            user.flowCompleted ?
            null :
            <DataModal userData={user} visible={visible}/>
          }
        </DashboardUserContainer>
      </GeneralDashboard>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading())
  };
};

export default connect(null, mapDispatchToProps)(UserDashboard);
