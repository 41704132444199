import styled from 'styled-components'
import {DarkBlue, Yellow,GrayWhite, Orange} from '../../../utilities/Colors'

export const AvisoContainer = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: ${GrayWhite};
    margin: 5% 3%;
    &>p{
        margin: 0 3%;
    }
    &>h2{
        color:${Yellow};
        text-align: center;
        font-weight:600;
    }
    &>h1{
        color:${DarkBlue};
        text-align: center;
        font-weight:700;
    }
    &>h4{
        color:${Orange};
        margin:0 3%;
        font-weight:600;
    }
    &>h5{
        color: black;
        margin:5% 0;
        text-align: right;
    }
`