export const howSteps = {
  controls: {
    plus: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-mas.svg',
    minus: 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-menos.svg'
  },
  one : {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected01-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected01-03.png',
    isSelected: false,
  },
  two : {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected02-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected02-03.png',
    isSelected: false,
  },
  three : {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected03-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected03-03.png',
    isSelected: false,
  },
  four: {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected04-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected04-03.png',
    isSelected: false,
  },
  five: {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected05-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected05-03.png',
    isSelected: false,
  },
  six: {
    selected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepSelected06-03.png',
    nonSelected: 'https://aurumresources.s3.amazonaws.com/NewStyles/StepNoSelected06-03.png',
    isSelected: false,
  }
}

export const bannerImgHowWorks = 'https://aurumresources.s3.amazonaws.com/NewStyles/como1-img.png';