import React from 'react';
import { Auth } from 'aws-amplify';
import { FaFacebook, FaGoogle, } from 'react-icons/fa';

const Buttons = (props) => {

  const loginWithFacebook = () => {
    Auth.federatedSignIn({provider: 'Facebook'})
    .then(credentials => {
      console.log("Federated user logged: Facebook")
    })
    .catch(e => console.log("Hubo un error, vuelve a intentarlo.", e));
  };

  const loginWithGoogle = () => {
    Auth.federatedSignIn({provider: 'Google'})
    .then(cred => {
      console.log("Federated user logged: Google")
    })
    .catch(e => console.log("Hubo un error, veulve a intentarlo.", e))
  };

  return (
    <div>
      <div style={styles.container}>
        <button
          style={{ ...styles.button, ...styles.facebook, marginTop: "auto" }}
          onClick={loginWithFacebook}
        >
          <FaFacebook color='white' />
          <p style={styles.text}>Iniciar sesión con Facebook</p>
        </button>
        <button
          style={{ ...styles.button, ...styles.google, marginTop: "auto" }}
          onClick={loginWithGoogle}
        >
          <FaGoogle color='red' />
          <p style={{...styles.text, ...styles.grayText}}>Iniciar sesión con Google</p>
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  button: {
    width: '40%',
    maxWidth: 250,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 16px',
    borderRadius: 2,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    minHeight: 40
  },
  facebook: {
    backgroundColor: "#3b5998"
  },
  email: {
    backgroundColor: '#db4437'
  },
  checkAuth: {
    backgroundColor: '#02bd7e'
  },
  hostedUI: {
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
  signOut: {
    backgroundColor: 'black'
  },
  withAuthenticator: {
    backgroundColor: '#FF9900'
  },
  icon: {
    height: 16,
    marginLeft: -1
  },
  text: {
    color: 'white',
    fontSize: 12,
    margin: "0 auto",
    marginLeft: 10,
    fontWeight: 'bold'
  },
  blackText: {
    color: 'black'
  },
  grayText: {
    color: 'rgba(0, 0, 0, .75)'
  },
  orangeText: {
    color: '#FF9900'
  }
};

export default Buttons;
