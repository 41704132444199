import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ProfileInfo, GeneralInfo, AditionalInfo, PrincipalInfo} from './Profile.style';
import EditModal from '../../EditModal/EditModal';
import { userSelector } from '../../../../store/user/user.slice';

const Profile = () => {
  const user = useSelector(userSelector);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editCase, setEditCase] = useState(null);
	const editIconImage = 'https://aurumresources.s3.amazonaws.com/NewStyles/icon-edit.svg';


	const showModal = e => {
		setEditCase(e.target.name);
		setIsModalVisible(true)
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
  };

  const getNombreCompletoFormat = () => {
    let completeName = [];
    console.log('llamando a getNombreCompletoFormat()');
    if (!user?.name) return 'Aún sin proporcionar';

    if (user?.name) {
      completeName.push(user.name)
    }
    if (user?.lastname1) {
      completeName.push(user.lastname1)
    }
    if (user?.lastname2) {
      completeName.push(user.lastname2)
    }
    console.log(completeName.join(' '))
    return completeName.join(' ');
  };

	return (
		<ProfileInfo>
    {
      isModalVisible && (
			<EditModal
				user={user}
				editCase={editCase}
				isModalVisible={isModalVisible}
				handleOk={handleOk}
				handleCancel={handleCancel}
			/>
      )
    }
			<h1>MI PERFIL</h1>
			<GeneralInfo>
				<h2>Datos personales</h2>
				<PrincipalInfo>
					<div>
						<p>Nombre Completo</p>
						<img name='name' src={editIconImage} alt='Edit button' onClick={showModal}/>
						<h3>{getNombreCompletoFormat()}</h3>
					</div>
					<div style={{cursor: 'not-allowed'}}>
						<p>Correo eletcrónico</p>
						<img name='email' src={editIconImage} alt='Edit button' onClick={showModal} style={{pointerEvents: 'none'}}/>
						{
							user?.email ?
							<h4>{user.email}</h4>
							:
							<h4>Aún sin proporcionar</h4>
						}
					</div>
					<div style={{cursor: 'not-allowed'}}>
						<p>Contraseña</p>
						<img name='password' src={editIconImage} alt='Edit button' onClick={showModal} style={{pointerEvents: 'none'}}/>
						{
							user?.password ?
							<h4>{user.password}</h4>
							:
							<h4>• • • • • • • • • • • •</h4>
						}
					</div>
					<div>
						<p>CURP</p>
						<img name='curp' src={editIconImage} alt='Edit button' onClick={showModal}/>
						{
							user?.curp ?
							<h4>{user.curp}</h4>:
							<h4>Aún sin proporcionar</h4>
						}
					</div>
					<div>
						<p>Teléfono</p>
						<img name='phone' src={editIconImage} alt='Edit button' onClick={showModal}/>
						{
							user?.phone ?
							<h4>{user.phone}</h4>:
							<h4>Aún sin proporcionar</h4>
						}
					</div>
				</PrincipalInfo>
				<h2>AFORE</h2>
				<AditionalInfo>
					<div>
						<p>Proveedor</p>
						<img name='afore' src={editIconImage} alt='Edit button' onClick={showModal}/>
						{
							user?.afore ?
							<h3>{user.afore}</h3>
							:
							<h3>AFORE Nombre</h3>
						}
						</div>
				</AditionalInfo>
			</GeneralInfo>
		</ProfileInfo>
	)
}

export default Profile;
