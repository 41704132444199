import styled from 'styled-components';
import { device } from '../../utilities/media_queries';
import {DarkBlue} from '../../utilities/Colors';

export const MainNewRegister = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &>.ant-modal-content {
    border-radius: 15px;
  }
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
  }
`;

export const OptionsSection = styled.div`
  width: 90%;
`;

export const Option = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${props => props.selected ? 'rgba(255, 206, 0, .1)' : '#fff' };
  border: ${props => props.selected ? '2px solid #FFCE00' : '1px solid #DBE2EA' };;
  border-radius: 10px;
  padding: 1% 3%;
  margin: 1% 0;
  &>img {
    width: 45px;
    height: auto;
    margin-right: 15px;
  }
  &>span{
    display: flex;
    align-items: center;
    &>h4 {
      color: #103D56;
      font-weight: 700;
    }
  }
  &:hover {
    background-color: rgba(255, 206, 0, .1);
    border: 1px solid #FFCE00;
  }
`;