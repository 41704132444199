import styled from 'styled-components';
import {DarkBlue, White, Yellow, Black} from '../../utilities/Colors'
import {device} from '../../utilities/media_queries'

export const FooterS = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    bottom: 0;
    right: 0%;
    left: 0;
    width: 100%;
    padding:4% 0;
    margin: 0;
    &>p{
      color: ${Black};
      font-size:.6rem;
      text-align: center;
    }
  @media ${device.mobileS} {
    background-size: cover;
    background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/FooterMobile.png')
  }
  @media ${device.tablet} {
    background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/FooterBack-Lap-02.png');
    background-size: cover;
    font-Size: .8rem;
  }
  @media ${device.laptop} {
    background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/FooterBack-Lap-01.png');
    background-size: cover;
    font-Size: .9rem;
  }
  @media ${device.laptopL} {
    background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/FooterBack-LapL-03.png');
    font-Size: .9rem;
  }
  @media ${device.desktop} {
    font-Size: 1.5rem;
  }
`
export const FooterSec1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
    width: 35%;
    padding: 0 0 0 3%;
    &>div{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 35%;
      &>a{
        width: 33%;
          &>img{
            width: 18px;
            height: auto;
            }
        }
      }
    &>h3{
      color: ${White};
      font-weight:400;
      font-size: .8rem;
      width: 70%;
    }
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
      flex-direction: row;
      width: 90%;
      flex-wrap: wrap;
      margin: 2% 5% 0 5%;
      justify-content: center;
    }
    @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      width:33%;
      margin: 0;
      justify-content:flex-start;
    }
`
export const FooterSec2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  width: 33%;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
    width: 90%;
    margin: 2% 5%;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
    width:33%;
    margin: 0;
  }
  &>h3{
    color:${White};
  }
  &>div{
    width: 70%;
    justify-content: space-evenly;
    flex-direction: row;
    padding-top: 8%;
    &>h4{
      color:${White};
      font-weight:200;
      font-size: .8rem;
      &>img{
        margin-right: 2%;
      }
    }

  }
`
export const FooterSec3 = styled.div`
  width: 32%;
  &>h4{
    font-weight:500;
    &>Link{
      color: ${Yellow};
      text-decoration: none;
    }
    }
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
    width: 90%;
    margin: 3% 5% 0 5%;
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
    width:33%;
    margin: 0;
  }
`
export const FooterHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media ${device.mobileS},
  ${device.mobileM},
  ${device.mobileL} {
    width:90%;
    margin: 0 5%;
    justify-content: space-evenly;
    &>h2{
    font-size:1rem;
    width: 30%;
    margin: 0 12%;
    color:${White};
    font-weight:600;
    }
  }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
    width:100%;
    margin: 0;
    justify-content: flex-start;
    &>h2{
      font-size:1.2rem;
      width: 33%;
      color:${White};
      font-weight:600;
      margin: 0;
    }
  }
  &>div{
    width: 33%;
    &>img{
      width: 150px;
      height: auto;
    }
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL} {
      width: 50%;
      &>img{
        width: 150px;
        height: auto;
      }
    }
    @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL} {
      width: 33%;
      &>img{
        width: 221px;
        height: auto;
      }
    }
  }
`