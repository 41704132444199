import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

import rootReducer from './root-reducer';

const middlewares = [];

if(process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

// const store = createStore(rootReducer, applyMiddleware(...middlewares));
const store = configureStore({
  reducer: rootReducer,
  middleware: [...customizedMiddleware],
  devTools: process.env.NODE_ENV !== 'production'
})

const persistor =  persistStore(store);

export { store, persistor };