import React, { useState, useEffect } from 'react';
import { Card, InputNumber, Select, Checkbox } from 'antd';
import { CreditCardOutlined, DollarCircleOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { updateAccount, selectAllAccounts, selectAccountById } from '../../../store/accounts/accounts.slice';

const { Meta } = Card;

const AccountSelection = ({
  // accounts,
  // isItDisabled,
  // syncAccounts,
  // setGlobalAccounts,
  location
}) => {
  const dispatch = useDispatch();
  const accounts = useSelector(selectAllAccounts);

  const [selectedAccountId, setSelectedAccountId ] = useState(0);
  const [accountsDataSync, setAccountsDataSync ] = useState({});
  const [syncOptions, setSyncOptions] = useState({});

  const { Option } = Select;

  const selectedAccount = useSelector(state => selectAccountById(state, selectedAccountId));

  // const handleInputNumber = (value, name, index) => {
  //   let accountsToSyncCopy = { ...accountsDataSync, [index] : {...accountsDataSync[index], [name]: value} };
  //   setAccountsDataSync(accountsToSyncCopy);
  //   let accountsArray = [];
  //   for(let x in accountsToSyncCopy) {
  //     accountsArray.push(accountsToSyncCopy[x])
  //   }
  //   setGlobalAccounts(accountsArray)
  //   // setGlobalAccounts(accountsToSyncCopy)
  // };

  useEffect(() => {
    if(selectedAccount){
      handleAddAccountToSync();
    }
  }, [selectedAccountId]);

  const onChangeAccountToSync = (id) => {
    setSelectedAccountId(id);
  };

  const handleAddAccountToSync =  async () => {
    const modifiedAccount = {
      id: selectedAccount.id,
      syncAllowed: !(!!selectedAccount.syncAllowed)
    }
    try {
      await(dispatch(updateAccount(modifiedAccount)));
    } catch(error) {
      console.log({error});
    }
  };

  const accountsSectionStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignContent: 'space-evenly',
    width: '90%',
    marginBottom: "3rem"
  };

  return (
    <>
      {
        accounts.length && (
          <>
            {
              location === 'dashboard' ? null : <h3>Selecciona las cuentas que deseas sincronizar.</h3>
            }
            <div
              style={{...accountsSectionStyle}}
            >
              {
                accounts.map((account, i) =>
                  <div key={account.id} style={{ width: "42%"}} >
                    <Checkbox onChange={() => onChangeAccountToSync(account.id)} checked={account.syncAllowed}></Checkbox>
                    <Card
                      style={{marginBottom: "1rem"}}
                      cover={
                        <img
                          style={{width: "50%", margin: "auto"}}
                          alt={account.name}
                          src={require(`../../../assets/images/${account.site.organization.toLowerCase().split(' ').join('_')}.jpg`)}
                        />
                      }
                      actions={ null }
                      //   syncOptions[i] ? [
                      //   <InputNumber
                      //     defaultValue={0}
                      //     min={0}
                      //     max={100}
                      //     formatter={value => `${value}%`}
                      //     parser={value => value.replace('%', '')}
                      //     name="percentage"
                      //     onChange={x => handleInputNumber(x, 'percentage', i)}
                      //     placeholder='Porcentaje de tus compras'
                      //   />,
                      //   <InputNumber
                      //     name="maxAmount"
                      //     formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      //     parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      //     onChange={x => handleInputNumber(x, 'maxAmount', i)}
                      //     placeholder='Tope máximo'
                      //   />]
                      // : null }
                    >
                      <Meta
                        style={{ padding: 0 }}
                        avatar={account.account_type === "Checking" ? <DollarCircleOutlined /> : <CreditCardOutlined />}
                        title={account.name}
                        description={account.account_type === "Checking" ? "Cuenta débito" : "Tarjeta crédito"}
                      />
                    </Card>
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </>
  );
};

export default AccountSelection;
