import styled from 'styled-components'
import { DarkGray, DarkBlue,GrayWhite, White, Yellow, Green,BabyYellow, BabyBlue} from '../../../utilities/Colors'
import { device } from '../../../utilities/media_queries';

export const BackInformation = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  &>h2{
    color: ${DarkBlue};
    width: 96%;
    margin: 0 2% 1% 0;
    text-align: left;
    border-left: 5px solid ${Yellow};
    padding-left: 2%;
    font-weight: 700;
  }
  &>p{
    color:${DarkBlue};
  }
  @media ${device.mobileS} {
    font-Size: .8rem;
    width: 90%;
    padding:0;
    margin: 3% 5%;
  }
  @media ${device.mobileM} {
    font-Size: .8rem;
    width: 90%;
    padding:0;
    margin: 3% 5%;
  }
  @media ${device.mobileL} {
    font-Size: .8rem;
    width: 90%;
    padding:0;
    margin: 3% 5%;
  }
  @media ${device.tablet} {
  font-Size: .8rem;
  width: 90%;
  padding:0;
  margin: 3% 5%;
}
  @media ${device.laptop} {
  width: 90%;
  padding: 0;
  margin: 2% 2%;
  font-Size: 1rem;
}
@media ${device.laptopL} {
  width: 90%;
  padding: 0;
  margin: 2% 2%;
  font-Size: 1.2rem;
}
  @media ${device.desktop} {
  width: 86%;
  padding: 0;
  margin: 2% 7%;
  font-Size: 1.5rem;
}
`
export const DashboardWelcome = styled.div`
  width: 96%;
  margin: 2%;
  display: flex;
  flex-direction: row;
  align-items:center;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  background-color:${White};
  border-radius:10px;
  &>div{
    width:60%;
    text-align: left;
    padding: 0 2%;
    &>h1{
      color:${DarkBlue};
      font-size:4rem;
      font-weight: 700;
    }
  }
  &>img{
    width: 40%;
    height: auto;
    z-index: 1;
  }
`

export const RetirementAccount = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 96%;
  padding: 1% 0;
  margin: 2%;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  background-color:${White};
  @media ${device.mobileS} {
    font-Size: .8rem;
    width:90%;
    }
    @media ${device.mobileM} {
    font-Size: .8rem;
    width:90%;
    }
    @media ${device.mobileL} {
      font-Size: .8rem;
      width:90%;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        font-Size: .8rem;
        width:96%;
    }
  &>div{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    width:30%;
    margin: 2% 0;
    padding: 2% 0;
    text-align: center;
    color:${DarkBlue};
    &>p{
      font-size:1rem;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 1%;
      border-bottom:2px solid ${GrayWhite};
      font-family: 'IBM Plex Sans', sans-serif;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL},
        ${device.tablet}{
        font-size: .7rem;
      }
      @media ${device.laptop},
      ${device.laptopL} {
        font-Size: 1rem;
      }
      @media ${device.desktop} {
        font-size:1.5rem;
      }

    }
    &>h3{
      color: ${DarkBlue};
      font-size: 1.7rem;
      font-weight: 700;
    }
  }
    &>div:nth-child(1){
      background-color:${Green};
    }
    &>div:nth-child(2){
      background-color:${BabyBlue};
    }
    &>div:nth-child(3){
      background-color:${BabyYellow};
    }
`
export const Benefits = styled.div`
  display: flex;
  background-color: purple;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-top: 5px solid ${Yellow};
  border-bottom: 5px solid ${Yellow};
  width: 30%;
  margin: 1%;
  @media ${device.mobileS} {
      font-Size: .8rem;
      width:90%;
      margin: 0;
    }
    @media ${device.mobileM} {
      font-Size: .8rem;
      width:90%;
      margin: 0;
    }
  @media ${device.mobileL} {
      font-Size: .8rem;
      width:90%;
      margin: 0;
    }
    @media ${device.tablet} {
      font-Size: .8rem;
      width:30%;
      margin: 0;
    }
    @media ${device.laptop} {
      font-Size: .8rem;
      width:30%;
      margin: 0;
    }
    @media ${device.laptopL} {
      font-Size: .8rem;
      width:30%;
      margin: 0;
    }
  &>div{
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width:100%;
    &>Button{
    background-color: white;
    border-radius: none;
    border:1px solid ${Yellow};
    color:${Yellow};
    font-size:.9rem;
    height: auto;
    width: auto;
    padding: 0 1%;
    transition: .5s ease-in;

    @media ${device.mobileS} {
      font-size: .7rem;
      margin: 2%;
      border-radius: none;
      }
      @media ${device.mobileM} {
      font-size: .7rem;
      margin: 2%;
      border-radius: none;
      }
    @media ${device.mobileL} {
      font-size: .7rem;
      margin: 2%;
      border-radius: none;
      }
    @media ${device.tablet} {
      font-size: .7rem;
      margin: 15% 2%;
      border-radius: none;
      }
      @media ${device.laptop} {
        font-size: .9rem;
        margin: 12% 0%;
        border-radius: none;
        width: 60%;
      }
      @media ${device.laptopL} {
      font-size: 1.1rem;
      margin: 6% 1%;
      width: 50%;
      }
      @media ${device.desktop} {
      font-size: 1.6rem;
      margin: 4.5% 1%;
      }
    }


    &>Button:hover{
      background-color: ${Yellow};
      border-radius: none;
      color:white;
      border:1px solid ${Yellow};
      transition: 1s ease-out;
      @media ${device.tablet} {
      font-size: .8rem;
      margin: 12% 1%;
      border-radius: none;
      width: 65%;
      }
      @media ${device.laptop} {
        font-size: .9rem;
        margin: 9% 1%;
        border-radius: none;
        width: 35%;
      }
      @media ${device.laptopL} {
      font-size: .9rem;
      margin: 6% 1%;
      width: 38%;
      }
      @media ${device.desktop} {
      font-size: 1.6rem;
      margin: 4.5% 1%;
      }
    }
  }
`
export const History = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:96%;
  border-radius: 10px;
  box-shadow:5px 10px 5px #2C273814;
  background-color:${White};
  padding: 2%;
  margin: 0 1% 2% 1%;
  @media ${device.mobileS} {
}
  @media ${device.tablet} {
    font-size:1rem;

}
  @media ${device.laptopL} {
    font-size:1.2rem;
}
  @media ${device.desktop} {
  font-size:1.4rem;
}
`
export const HistoryData = styled.div`
  height: auto;
  color: ${DarkBlue};
  width: 96%;
  margin:0 2%;
  font-family: 'IBM Plex Sans', sans-serif;
  /* background-color: blue; */
  &>div:nth-child(1){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: auto;
    /* background-color: yellow; */
    text-align: left;
    &>h4{
      width: 20%;
      display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: pink; */
      font-weight:700;
      color: ${DarkBlue};
      font-size:.9rem;
    }
  }
  &>div:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: auto;
    background-color:#F9F9F9;
    &>h3{
      padding: 2% 5%;
      color: ${DarkBlue};
    }
  }
  @media ${device.mobileS} {
}
  @media ${device.tablet} {

}
  @media ${device.laptop} {

}
  @media ${device.desktop} {

}
`

export const Contribution = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  margin: 2% 0;
  background-color:#FFFBED;

  .ant-input, .ant-input-number{
    border:none;
    background-color:rgba(255,255,255,0);
    color:${DarkBlue};
  }
`
export const ContributionDetails = styled.div`
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 96%;
    margin: 2%;
    &>h4{
      width: 50%;
    }
`