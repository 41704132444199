import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Steps, message, Tooltip, } from 'antd';

import { GeneralContainer, StepsAction } from './DataModal.style';

import RetirementAccount from '../../Dashboard/Steps/1_RetirementAccount/RetirementAccount';
import YourSavings from '../../Dashboard/Steps/2_YourSavings/YourSavings';
import SafeSavings from '../../Dashboard/Steps/3_SafeSavings/SafeSavings';
import TraditionalSavings from '../../Dashboard/Steps/4_TraditionalSavings/TraditionalSavings';
import Benefits from '../../Dashboard/Steps/5_Benefits/Benefits';
import { updateCurrentUser, userSelector } from '../../../store/user/user.slice';

const DataModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [visible, setVisible] = useState(false);
  const [visibleElements, setVisibleElements] = useState([]);
  const [cardDisabled, setCardDisabled] = useState(true);
  const [destinationAccount, setDestinationAccount] = useState(null);
  const [selectedAfore, setSelectedAfore] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [globalScheme, setGlobalScheme] = useState({
    tuafore: true,
    tuahorro: true,
    commitedSavings: false,
    traditionalSavings: true,
    beneficios: true,
  });

  const { Step } = Steps;

  const [steps, setSteps] = useState([
    {
      description: 'TU AFORE',
      infoStep:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper tincidunt lacinia. Cras ac magna sed erat ultricies tincidunt. Curabitur.',
      value: 0,
      available: globalScheme.tuafore,
    },
    {
      description: 'TU AHORRO',
      infoStep:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in purus justo. Cras sagittis a velit in dignissim. Donec ultrices.',
      value: 1,
      available: globalScheme.tuahorro,
    },
    {
      description: 'AHORRO SEGURO',
      infoStep:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus efficitur odio et elementum ullamcorper. Integer imperdiet in neque et eleifend.',
      disabled: { cardDisabled },
      available: globalScheme.commitedSavings,
      value: 2,
    },
    {
      description: 'BENEFICIOS',
      infoStep:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu congue neque, eget ullamcorper enim. Donec pellentesque lobortis elit, vitae.',
      value: 3,
      available: globalScheme.beneficios,
    },
    {
      description: 'AHORRO TRADICIONAL',
      infoStep:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pharetra, libero eu ultricies ultrices, lorem ante accumsan leo, in convallis.',
      available: globalScheme.traditionalSavings,
      value: 4,
    },
  ]);

  // Changing to the preview or next part of the form
  const nextAccountRegister = async () => {
    try {
      let input = {
        id: user.id,
        curp: destinationAccount,
        afore: selectedAfore,
      };

      if (destinationAccount) {
        await dispatch(updateCurrentUser(input));
      }

      const current = currentPageIndex + 1;
      setCurrentPageIndex(current);
    } catch (error) {
      console.log({ error });
    }
  };

  const syncAccounts = async (accounts) => {};

  const next = () => {
    if (currentPageIndex === 2) {
      syncAccounts();
    }
    const current = currentPageIndex + 1;
    setCurrentPageIndex(current);
  };

  const prev = () => {
    const current = currentPageIndex - 1;
    setCurrentPageIndex(current);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleContributionRegister = async () => {
    try {
      message.success(
        'Haz concluido con éxito el llenado de tu información y el registro de tu primera aportación.'
      );
      const input = {
        id: user.id,
        flowCompleted: true,
      };
      await dispatch(updateCurrentUser(input));
    } catch (error) {
      console.log({ error });
    }
    setVisible(false);
  };

  useEffect(() => {
    if (!user.completeProfile) {
      setVisible(true);
    }
    let visibleObjects = steps.filter((item) => item.available);
    setVisibleElements(visibleObjects);
  }, [user, steps, globalScheme]);

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{ padding: '2%' }}
      footer={null}
      width="80%"
    >
      <GeneralContainer>
        <div>
          <img
            alt="Inkom-logo"
            src="https://aurumresources.s3.amazonaws.com/inkom/inkom_logo.png"
          />
          <Steps
            direction="vertical"
            style={{ height: '90%' }}
            current={currentPageIndex}
          >
            {steps.map((item, index) => (
              <Step
                key={index}
                description={item.description}
                value={item.value}
                disabled={item.available}
              />
            ))}
          </Steps>
        </div>

        <div style={{ height: '100%' }}>
          {currentPageIndex === 0 ? (
            <RetirementAccount
              setDestinationAccount={setDestinationAccount}
              setSelectedAfore={setSelectedAfore}
              selectedAfore={selectedAfore}
              userData={user}
              destinationAccount={destinationAccount}
            />
          ) : currentPageIndex === 1 ? (
            <YourSavings setGlobalScheme={setGlobalScheme} userData={user} />
          ) : currentPageIndex === 2 ? (
            <SafeSavings
              setCurrentPageIndex={setCurrentPageIndex}
              syncAccounts={syncAccounts}
            />
          ) : currentPageIndex === 3 ? (
            <Benefits />
          ) : (
            <TraditionalSavings />
          )}
          <StepsAction>
            <div>
              {currentPageIndex > 0 && (
                <Button
                  style={{
                    bottom: 0,
                    backgroundColor: '#FFF',
                    fontFamily: 'IBM Plex Sans',
                    borderColor: '#FFCE00',
                    color: '#103D56',
                    fontSize: '12px',
                    height: 'auto',
                    fontWeight: '600',
                    padding: '14px 38px',
                    margin: '2% 0',
                  }}
                  onClick={() => prev()}
                >
                  ANTERIOR
                </Button>
              )}
            </div>
            <div>
              {currentPageIndex < steps.length - 1 && currentPageIndex !== 0 && (
                <Tooltip
                  title={
                    currentPageIndex === 1
                      ? 'Para poder empezar a ahorrar con base en los cargos a tus cuentas. Es necesario que des de alta las cuentas que quieres tomemos como referencia. Este paso puedes completarlo en tu dashboard en la sección "Ahorro cuando compras".'
                      : currentPageIndex === 2
                      ? 'Para lograr calcular el monto de tu ahorro, trabajamos con Paybook. Es necesario que sincronices la información de cuenta antes de continuar. Si lo deseas puedes completarlo en tu dashboard en la sección "Ahorro cuando compras".'
                      : currentPageIndex === 3
                      ? 'Contamos con tres horizontes de ahorro. Es importante seleccionar uno cada que programes una aportación'
                      : 'Si optas por esta opción de ahorro es necesario nos indiques, cantidad, si es recurrente y la fecha en la que se aplicará tu aportación.  Si lo deseas puedes dar de alta este tipo de aportaciones en tu dashboard en la sección "Ahorro tradicional".'
                  }
                >
                  <Button
                    type="primary"
                    style={{
                      bottom: 0,
                      backgroundColor: '#FFC200',
                      fontFamily: 'IBM Plex Sans',
                      borderColor: '#FFC200',
                      color: '#103D56',
                      fontSize: '12px',
                      height: 'auto',
                      fontWeight: '600',
                      padding: '14px 38px',
                      margin: '2% 0',
                    }}
                    onClick={() => next()}
                  >
                    SIGUIENTE
                  </Button>
                </Tooltip>
              )}
              {currentPageIndex === 0 && (
                <Button
                  type="primary"
                  style={{
                    bottom: 0,
                    backgroundColor: '#FFC200',
                    fontFamily: 'IBM Plex Sans',
                    borderColor: '#FFC200',
                    color: '#103D56',
                    fontSize: '12px',
                    height: 'auto',
                    fontWeight: '600',
                    padding: '14px 38px',
                    margin: '2% 0',
                  }}
                  onClick={() => {
                    nextAccountRegister();
                  }}
                >
                  SIGUIENTE
                </Button>
              )}
              {currentPageIndex === steps.length - 1 && (
                <Tooltip title='Si optas por esta opción de ahorro es necesario nos indiques, cantidad, si es recurrente y la fecha en la que se aplicará tu aportación.  Si lo deseas puedes dar de alta este tipo de aportaciones en tu dashboard en la sección "Ahorro tradicional".'>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#FFC200',
                      fontFamily: 'IBM Plex Sans',
                      borderColor: '#FFC200',
                      color: '#103D56',
                      fontSize: '12px',
                      height: 'auto',
                      fontWeight: '600',
                      padding: '14px 38px',
                      margin: '2% 0',
                    }}
                    onClick={handleContributionRegister}
                  >
                    FINALIZAR
                  </Button>
                </Tooltip>
              )}
            </div>
          </StepsAction>
        </div>
      </GeneralContainer>
    </Modal>
  );
};

export default DataModal;
