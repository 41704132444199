import styled from 'styled-components';
import { device } from '../../../utilities/media_queries';
import {DarkBlue} from '../../../utilities/Colors'

export const MainSavings = styled.section`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  @media ${device.mobileS} {

  }
  @media ${device.tablet} {

  }
  @media ${device.laptop} {

  }
  @media ${device.desktop} {
  }
`;

export const SavingsContainer  = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  @media ${device.mobileS},
    media ${device.mobileM}, {
    media ${device.mobileL}
      width: 100%;
      padding: 0;
      margin: 0;
      align-items: center;
  }
  @media ${device.tablet},
    media ${device.laptop}, {
    media ${device.laptopL}
      width: 85%;
      padding: 0 3% 0 7%;
      margin: 0 0 0 5%;
  }
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
    @media ${device.mobileS},
    media ${device.mobileM}, {
    media ${device.mobileL}
      font-size:1rem;
    }
    @media ${device.laptop} {
      font-size:1.4rem;
    }
    @media ${device.desktop} {
      font-size:1.2rem;
    }
  }
  &>p{
    color:${DarkBlue};
    font-weight: 300;
    @media ${device.laptop} {
      font-size:.9rem;
    }
    @media ${device.desktop} {
      font-size:1rem;
    }
  }
  &>Button{
  margin-top: 5vh;
  margin-left: 15vw;
}
`;

export const SavingsForm = styled.div`
  margin: 0 2%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
&>p{
  margin: 2% 5%;
}
`;
export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  margin: 1% 0;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &>Button{
    margin: 3% 3%;
  }
`;