import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SavingGeneral, Accounts, BankCard, SecureDataDisclaimer } from './DetalleInkom.style';

import { setAppLoading, setAppNotLoading } from '../../../../store/app/app.actions';
import { getImageURL, ccFormat } from './DetalleInkom.utils';
import { userSelector } from '../../../../store/user/user.slice';
import { selectAllAccounts, updateAccountInteraction } from '../../../../store/accounts/accounts.slice'
import { selectAllCards } from '../../../../store/cards/cards.slice'
import { selectAllContributions, uniqueScheduledContribution } from '../../../../store/contributions/contributions.slice'
import { selectAllTransactions } from '../../../../store/transactions/transactions.slice';
import { updateUserToken } from '../../../../middlewares/communication/user/user.communication';
import { updateTransactionsList } from '../../../../store/transactions/transactions.slice';
import TransactionsList from './TransactionsList';

const DetalleInkom = ({
  setAppIsLoading,
  setAppIsNotLoading,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const accounts = useSelector(selectAllAccounts);

  const cards = useSelector(selectAllCards);
  const contributions = useSelector(selectAllContributions);
  const uniqueScheduled = useSelector(uniqueScheduledContribution);
  const transactions = useSelector(selectAllTransactions);

  const [userAccounts, setUserAccounts] = useState(null);
  const [isTransactionsListVisible, setIsTransactionsListVisible] = useState(false);
  const [transactionsToDisplayInModal, setTransactionsToDisplayInModal] = useState(false);
  const [newUpdatedToken, setNewUpdatedToken] = useState(null);

  useEffect(() => {
    if(user.id_pb_user && accounts.length){
      getUpdatedData()
    }
  }, []);

  useEffect( () => {
    setUserAccounts([...accounts]);
  }, [cards, accounts, contributions, uniqueScheduled])

  const getUpdatedData = async () => {
    const thisMoment =  Date.now().toString().slice(0,10);
    setAppIsLoading();
    const updatedToken = await updateUserToken(user.id_pb_user);
    setNewUpdatedToken(updatedToken);
    accounts.map(async accountElement => {
      if(!accountElement.lastFetch || Number(thisMoment) - Number(accountElement.lastFetch) > 86400) {
        dispatch(updateAccountInteraction({account: accountElement,  id_user: user.id_pb_user}));
        dispatch(updateTransactionsList({account: accountElement, user}));
      } else {
        console.log(`No han pasado 24 hrs para consultar de nuevo la cuenta ${accountElement.number}`)
      }
    });
    setAppIsNotLoading();
  }

  const handleTransactionsListVisibility = (accountId) => {
    let filteredTransactions = transactions.filter(transaction => {
      return transaction.accountId === accountId
    })
    setTransactionsToDisplayInModal(filteredTransactions)
    setIsTransactionsListVisible(true)
  }

  return (
    <SavingGeneral>
    <Modal
      title="Últimos Movimientos"
      visible={isTransactionsListVisible}
      onOk={() => {setIsTransactionsListVisible(false)}}
      onCancel={() => {setIsTransactionsListVisible(false)}}
      width={'70%'}
    >
      <TransactionsList transactions={transactionsToDisplayInModal}/>
    </Modal>
      <div>
        <h1>DETALLE INKOM</h1>
      </div>
      <SecureDataDisclaimer>
        <p><b>NOTA:&nbsp;</b>Nuestro sitio web y aplicación movil no utiliza ningún software de seguimiento invasivo. Usamos su información personal sólo con el propósito que se describe en nuestro <Link to="/aviso-de-privacidad" style={{color:"#FFCE00", fontWeight:"600"}}>AVISO DE PRIVACIDAD</Link>.<br/>
        <br/>En ese sentido, la información de sus cuentas está segura. Proteger su privacidad se trata de tener control sobre sus datos. En Inkom, te ayudamos a controlar y proteger lo que es tuyo.</p>
      </SecureDataDisclaimer>
    <h2>Cuentas bancarias</h2>
    {
      userAccounts ?
      <Accounts>
      {
      userAccounts.map((acc, i) => {
        if(
          acc && (
            (acc.site?.organization && acc.site?.organization !== 'Liverpool') ||
            acc.account_type === 'Checking' ||
            acc.account_type === 'Credit Card'
          )) {
            return (
            <BankCard key={acc.id} style={acc.hidden ? {display: 'none'} : {}}>
              <div>
                <img src={getImageURL(acc.site.organization)} alt={acc.site.organization}/>
              </div>
              <div>
                <h3>Número de tarjeta o cuenta</h3>
                <p>{ ccFormat(acc.number) }</p>
                <h3>Porcentaje de los cargos</h3>
                <p>{ acc.percentage ? `${acc.percentage}%` : 'Aún sin asignar'}</p>
              </div>
              <div>
                <h2>SALDO</h2>
                <p>{acc.balance ? `$ ${acc.balance}` : 'Aún sin información'}</p>
              </div>
              <div>
                <Tooltip placement="right" title="Revisar movimientos">
                  <h2 onClick={() => handleTransactionsListVisibility(acc.id)}>
                    <PlusOutlined />
                  </h2>
                </Tooltip>
              </div>
            </BankCard>
          )}
        })
      }
      </Accounts> :
      <div>No has registrado cuentas. Ve a la opción AGREGAR AHORRO</div>
    }
    </SavingGeneral>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading())
  };
};

export default connect(null, mapDispatchToProps)(DetalleInkom);
