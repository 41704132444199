import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import UserDashboard from './components/Dashboard/UserDashboard/UserDashboard';
import LandingPage from './components/LandingPage/LandingPage'
import Faqs from './components/layout/FAQs/Faqs';
import AvisoPrivacidad from './components/layout/Legal/AvisoPriv/AvisoPrivacidad';
import Terminos from './components/layout/Legal/TerminosyCond/Terminos'
import ContactUs from './components/temp/ContactUs'
import Signup from './components/Auth/Signup/Signup'
import HowWorks from './components/LandingPage/LandingMenu/HowItWorks/HowWorks'
import DashboardAdminPrincipal from './components/Admin/DashboardAdmin/DashboardAdminPrincipal';
import Checker from './components/UI/Checker';

export default () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage}/>
      <Route exact path="/faqs" component={Faqs}/>
      <Route exact path="/contact-us" component={ContactUs}/>
      <Route exact path="/signup" component={Signup}/>
      <Route path="/user-dashboard" component={UserDashboard}/>
      <Route path="/validating/" exact component={Checker}/>
      <Route path="/admin-dashboard" component={DashboardAdminPrincipal}/>
      <Route exact path="/aviso-de-privacidad" component={AvisoPrivacidad}/>
      <Route exact path="/terminos-y-condiciones" component={Terminos}/>
      <Route exact path="/how-works" component={HowWorks}/>
      <Route path="/*">
        <Redirect to="/user-dashboard" />
      </Route>
    </Switch>
  );
};
