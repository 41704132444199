import styled from 'styled-components';
import { device } from '../../../utilities/media_queries';
import {DarkBlue} from '../../../utilities/Colors'

export const MainSafeSavings = styled.section`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  @media ${device.mobileS} {

  }
  @media ${device.tablet} {

  }
  @media ${device.laptop} {

  }
  @media ${device.desktop} {

  }
`;

export const SSavingsContainer  = styled.div`
  height: auto;
  width: 90%;
  padding: 0 3%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
  }
  &>p{
    color:${DarkBlue};
    font-weight: 300;
    width: '90%';
    margin-top: '20px';
  }
`;

export const SavingsForm = styled.div`
  padding: 5% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

`;

export const SecureDataDisclaimer = styled.div`
  height: auto;
  width: 90%;
  padding: 0 3%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
  }
  &>p{
    color:${DarkBlue};
    font-size: 12px;
    font-weight: 300;
    width: '90%';
    margin-top: '20px';
  }
`;
export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  margin: 1% 0;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &>Button{
    margin: 3% 3%;
  }
`;