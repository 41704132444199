import { API, graphqlOperation } from 'aws-amplify';
import { getMyMitOperations } from '../../../graphql/customqueries';
import { createMitOperationRequest, updateMitOperationRequest } from '../../../graphql/mutations';
import { getMitOperationRequest } from '../../../graphql/queries';

export const createNewMitOperationRequest = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createMitOperationRequest, {input}));
  return rawResponse.data.createMitOperationRequest;
};

export const getSpecificMitOperationRequest = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getMitOperationRequest, {id}));
  return rawResponse.data.getMitOperationRequest;
};

export const updateExistentMitOperationRequest = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updateMitOperationRequest, {input}));
  return rawResponse.data.updateMitOperationRequest;
};

export const listMitOperationRequests = async id => {
  try {
    let rawResponse = [];
    const getData = async (token = null) => {
      let apiResponse;
      apiResponse = await API.graphql(graphqlOperation(getMyMitOperations, {ownerId: id, nextToken: token}));
      rawResponse.push(...apiResponse.data.listMitOperationRequests.items);
      let { nextToken } = apiResponse.data.listMitOperationRequests
      if(nextToken) {
        await getData(nextToken)
      }
      return
    }
    await getData()
    return rawResponse;
  } catch (error) {
    console.log({error})
  }
};
