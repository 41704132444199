import styled from 'styled-components';
import { DarkBlue, Yellow } from '../../utilities/Colors';
import { device } from '../../utilities/media_queries';

export const MainLogin = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  font-family: 'IBM Plex Sans', sans-serif;
  width: 90%;
  height: 100%;
  &>p{
    color:${DarkBlue};
    font-size:.7rem;
    text-align:left;
    margin: 1% 0;
    opacity:0.7;
    font-weight: 500;
    @media ${device.laptop} {
      font-size:.7rem;
    }
    @media ${device.laptopL} {
      font-size:1rem;
    }
  }

  .ant-spin-dot-item {
    background-color:${Yellow}
  }
  .ant-spin-lg .ant-spin-dot i{
    width:20px;
    height: 20px;
  }

  @media ${device.mobileS},
  ${device.mobileM},
  ${device.mobileL} {
    width: 100%;
    &>Button{
    margin: 10% 0;
    }
  }
  @media ${device.tablet} {

    &>Button{
    margin: 25% 0;
    }
  }
  @media ${device.laptop} {

    &>Button{
      margin: 10% 0;
    }
  }
  @media ${device.laptopL} {

    &>Button{
      margin: 5% 0;
    }
  }
`;

export const PasswordRecovery = styled.span`
  width: 100%;
  color: ${DarkBlue};
  cursor: pointer;
  &>p{
    text-align: right;
    font-size: .8rem;
  }
`;