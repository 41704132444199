import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getUserByEmail } from '../../graphql/customqueries';
import { createUser, updateUser } from '../../graphql/mutations';
import { getUser, listUsers } from '../../graphql/queries';
import { createSyncUser, initUserSession } from '../../API/Sync'
import alert from '../UI/Alert';
import validateForFederatedProviders from './validateForFederatedProviders';

const checkAuthenticatedUser = async (history) => {
  try {
    const userCognitoData = await Auth.currentAuthenticatedUser()
      if(!userCognitoData.attributes.sub) {
        await Auth.signOut()
        return;
      }

      if(history.location.pathname.includes('validating')) {
        let { data } = await API.graphql(graphqlOperation(getUserByEmail, {email: userCognitoData.attributes.email}))
        let filteredNormalUsers = data.listUsers.items.filter(user => !user.federatedId);
        let filteredFederatedUsers = data.listUsers.items.filter(user => user.federatedId);
        if(
          filteredNormalUsers.length &&
          filteredFederatedUsers.length
        ){
          // filteredNormalUsers[0]
          let info = await API.graphql(graphqlOperation(getUser, {id: filteredNormalUsers[0].id}))
          return info.data.getUser
        } else if(
          !filteredNormalUsers.length &&
          filteredFederatedUsers.length
        ){
          let info = await API.graphql(graphqlOperation(getUser, {id: filteredFederatedUsers[0].id}))
          return info.data.getUser
        } else if(
          filteredNormalUsers.length &&
          !filteredFederatedUsers.length
        ) {
          let { data } = await registerNewFederatedUser(userCognitoData)
          let userCreated = data.createUser;
          let info = await API.graphql(graphqlOperation(getUser, {id: filteredNormalUsers[0].id}))

          return info.data.getUser
        } else {
          let { data } = await registerNewFederatedUser(userCognitoData)
          let userCreated = data.createUser;
          const user = {
            email: userCreated.email,
            password: userCreated.id + Math.random() * 9999,
            id_ext: userCreated.id
          };

          let syncUser = await createSyncUser({...user, id_ext: user.id})
          if(syncUser.data.code === 200) {
            const input = {
              id: userCreated.id,
              id_pb_user: syncUser.data.response.id_user
            }
            let { data } = await API.graphql(graphqlOperation(updateUser, { input }, { authMode:"AMAZON_COGNITO_USER_POOLS" }))
            return data.updateUser

            // return initUserSession(syncUser.data.response.id_user)
            // .then(async responseSession =>  {
            //   const { token } = responseSession.data.response;
            //   const input = {
            //     id: userCreated.id,
            //     id_pb_user: syncUser.data.response.id_user
            //   }
            //   let { data } = await API.graphql(graphqlOperation(updateUser, { input }, { authMode:"AMAZON_COGNITO_USER_POOLS" }))
            //   return data.updateUser
            // })
          }
        }
      }
  } catch( err ) {
    console.log("Error de autenticación.", err)
    Auth.signOut()
  }
};

const validatForFederated = async (data) => {
  let registrationStatus = await validateForFederatedProviders(data)
  return registrationStatus;
};

const registerNewFederatedUser =  async (data) => {
  try {
    let input = {
      id: data.attributes.sub,
      email: data.attributes.email,
      federatedId: data.username,
      role: 'USER',
      flowCompleted: false,
      owner: data.attributes.sub
    }
    return await API.graphql(graphqlOperation(createUser, { input }))
  } catch(error) {
    return error;
  }
};

const validateFederatedCase = async (cognitoData, status) => {
  try {
    let { sub } = cognitoData.attributes;
    let { data } = await API.graphql(graphqlOperation(getUser, {id: sub}));
    if(!status.email_verified && !data.getUser) {
      return {
        federatedCase: 'FirstTime',
        error: null
      };
    } else if(!status.email_verified && !data.getUser) {
      return {
        federatedCase: 'SignIn',
        error: null
      };
    } else if(status.email_verified && !data.getUser) {
      return {
        federatedCase: 'SignOutCode',
        error: null
      };
    } else if(status.email_verified && data.getUser) {
      return {
        federatedCase: 'SignOut',
        error: null
      };
    };
    return {
      federatedCase: 'NoScenarioFound',
      error: new Error('No Scenario Found')
    }
  } catch(error) {
    return {
      federatedCase: 'UnknownError',
      error
    };
  }
};

export default checkAuthenticatedUser;
