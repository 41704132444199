/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      federatedId
      email
      identificator
      globalEntity
      name
      lastname1
      lastname2
      birthDate
      isEmployed
      lastFetch
      id_pb_user
      curp
      rfc
      role
      phone
      estimatedIncome
      issuingEntities
      flowCompleted
      advisor
      afore
      pension_mensual_objetivo
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      owner
      userCards {
        items {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userContributions {
        items {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userAccounts {
        items {
          id
          ownerId
          federatedOwner
          account_type
          balance
          currency
          dt_refresh
          hidden
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_user
          is_disable
          keywords
          name
          number
          createdAt
          owner
          syncAllowed
          lastFetch
          needsToken
          percentage
          maxAmount
          periodicity
          updatedAt
        }
        nextToken
      }
      userTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      userPayments {
        items {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExecutionError = /* GraphQL */ `
  query GetExecutionError($id: ID!) {
    getExecutionError(id: $id) {
      id
      ownerId
      ownerEmail
      origin
      dependency
      data
      createdAt
      updatedAt
    }
  }
`;
export const listExecutionErrors = /* GraphQL */ `
  query ListExecutionErrors(
    $filter: ModelExecutionErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExecutionErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        ownerEmail
        origin
        dependency
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      ownerId
      federatedOwner
      account_type
      balance
      currency
      dt_refresh
      hidden
      extra {
        available
        limit
        minimum
        no_interest
        points
        owner
      }
      id_account
      id_account_type
      id_credential
      id_currency
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_user
      is_disable
      keywords
      name
      number
      site {
        avatar
        cover
        id_site
        id_site_organization
        id_site_organization_type
        name
        organization
        small_cover
        time_zone
      }
      createdAt
      owner
      syncAllowed
      lastFetch
      needsToken
      percentage
      maxAmount
      periodicity
      accountOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      accountTransactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        accountOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        accountTransactions {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      ownerId
      accountId
      paymentId
      federatedOwner
      amount
      extra {
        concept
        balance
        spei_concept
        spei_reference_number
        spei_track_number
        spei_transmitter_name
        spei_transmitter_tax_id
        spei_transmitter_account
        spei_beneficiary_account
      }
      attachments
      currency
      description
      dt_deleted
      dt_disable
      dt_refresh
      dt_transaction
      id_account
      id_account_type
      id_credential
      id_currency
      id_disable_type
      id_external
      id_site
      id_site_organization
      id_site_organization_type
      id_transaction
      id_user
      is_account_disable
      is_deleted
      is_disable
      is_pending
      keywords
      reference
      createdAt
      owner
      transactionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactionAccount {
        id
        ownerId
        federatedOwner
        account_type
        balance
        currency
        dt_refresh
        hidden
        extra {
          available
          limit
          minimum
          no_interest
          points
          owner
        }
        id_account
        id_account_type
        id_credential
        id_currency
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_user
        is_disable
        keywords
        name
        number
        site {
          avatar
          cover
          id_site
          id_site_organization
          id_site_organization_type
          name
          organization
          small_cover
          time_zone
        }
        createdAt
        owner
        syncAllowed
        lastFetch
        needsToken
        percentage
        maxAmount
        periodicity
        accountOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        accountTransactions {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        accountId
        paymentId
        federatedOwner
        amount
        extra {
          concept
          balance
          spei_concept
          spei_reference_number
          spei_track_number
          spei_transmitter_name
          spei_transmitter_tax_id
          spei_transmitter_account
          spei_beneficiary_account
        }
        attachments
        currency
        description
        dt_deleted
        dt_disable
        dt_refresh
        dt_transaction
        id_account
        id_account_type
        id_credential
        id_currency
        id_disable_type
        id_external
        id_site
        id_site_organization
        id_site_organization_type
        id_transaction
        id_user
        is_account_disable
        is_deleted
        is_disable
        is_pending
        keywords
        reference
        createdAt
        owner
        transactionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactionAccount {
          id
          ownerId
          federatedOwner
          account_type
          balance
          currency
          dt_refresh
          hidden
          id_account
          id_account_type
          id_credential
          id_currency
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_user
          is_disable
          keywords
          name
          number
          createdAt
          owner
          syncAllowed
          lastFetch
          needsToken
          percentage
          maxAmount
          periodicity
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      ownerId
      contributionId
      federatedOwner
      completeInfo
      amount
      amountPayed
      medium
      periodDate
      period
      kind
      payed
      paymentMedium
      paymentProviderResponse
      payedAt
      createdAt
      owner
      paymentOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactions {
        items {
          id
          ownerId
          accountId
          paymentId
          federatedOwner
          amount
          attachments
          currency
          description
          dt_deleted
          dt_disable
          dt_refresh
          dt_transaction
          id_account
          id_account_type
          id_credential
          id_currency
          id_disable_type
          id_external
          id_site
          id_site_organization
          id_site_organization_type
          id_transaction
          id_user
          is_account_disable
          is_deleted
          is_disable
          is_pending
          keywords
          reference
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        contributionId
        federatedOwner
        completeInfo
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentMedium
        paymentProviderResponse
        payedAt
        createdAt
        owner
        paymentOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCard = /* GraphQL */ `
  query GetCard($id: ID!) {
    getCard(id: $id) {
      id
      ownerId
      federatedOwner
      nameInCard
      number
      dueDate
      cvv
      hidden
      issuingEntity
      address {
        street
        extNum
        intNum
        zip
        suburb
        townHall
        state
      }
      pb_reference
      id_pb_card
      percentage
      maxAmmount
      type
      periodicity
      createdAt
      owner
      cardOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards(
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        cvv
        hidden
        issuingEntity
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        cardOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContribution = /* GraphQL */ `
  query GetContribution($id: ID!) {
    getContribution(id: $id) {
      id
      ownerId
      paymentId
      federatedOwner
      goal
      scheduled
      periodicity
      referenceDate {
        kind
        dateData
      }
      ammount
      paymentMedium
      mitLink
      contributionStatus
      type
      payed
      paymentProviderResponse
      createdAt
      owner
      contributionOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        id
        ownerId
        federatedOwner
        nameInCard
        number
        dueDate
        cvv
        hidden
        issuingEntity
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        pb_reference
        id_pb_card
        percentage
        maxAmmount
        type
        periodicity
        createdAt
        owner
        cardOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        updatedAt
      }
      payment {
        id
        ownerId
        contributionId
        federatedOwner
        completeInfo
        amount
        amountPayed
        medium
        periodDate
        period
        kind
        payed
        paymentMedium
        paymentProviderResponse
        payedAt
        createdAt
        owner
        paymentOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listContributions = /* GraphQL */ `
  query ListContributions(
    $filter: ModelContributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContributions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMitOperation = /* GraphQL */ `
  query GetMitOperation($id: ID!) {
    getMitOperation(id: $id) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      status
      reference
      createdAt
      updatedAt
      mitOperationOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMitOperations = /* GraphQL */ `
  query ListMitOperations(
    $filter: ModelmitOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMitOperations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        paymentId
        contributionId
        rawDataRequest
        rawDataResponse
        status
        reference
        createdAt
        updatedAt
        mitOperationOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMitOperationRequest = /* GraphQL */ `
  query GetMitOperationRequest($id: ID!) {
    getMitOperationRequest(id: $id) {
      id
      ownerId
      paymentId
      contributionId
      rawDataRequest
      rawDataResponse
      publicDataResponse {
        foliocpagos
        cd_response
        cd_error
        nb_error
        time
        date
        cc_type
        cc_number
        amount
        number_tkn
        id_url
        cc_mask
      }
      mitOperationStatus
      reference
      createdAt
      updatedAt
      mitOperationRequestOwner {
        id
        federatedId
        email
        identificator
        globalEntity
        name
        lastname1
        lastname2
        birthDate
        isEmployed
        lastFetch
        id_pb_user
        curp
        rfc
        role
        phone
        estimatedIncome
        issuingEntities
        flowCompleted
        advisor
        afore
        pension_mensual_objetivo
        address {
          street
          extNum
          intNum
          zip
          suburb
          townHall
          state
        }
        owner
        userCards {
          nextToken
        }
        userContributions {
          nextToken
        }
        userAccounts {
          nextToken
        }
        userTransactions {
          nextToken
        }
        userPayments {
          nextToken
        }
        createdAt
        updatedAt
      }
      contribution {
        id
        ownerId
        paymentId
        federatedOwner
        goal
        scheduled
        periodicity
        referenceDate {
          kind
          dateData
        }
        ammount
        paymentMedium
        mitLink
        contributionStatus
        type
        payed
        paymentProviderResponse
        createdAt
        owner
        contributionOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        reference {
          id
          ownerId
          federatedOwner
          nameInCard
          number
          dueDate
          cvv
          hidden
          issuingEntity
          pb_reference
          id_pb_card
          percentage
          maxAmmount
          type
          periodicity
          createdAt
          owner
          updatedAt
        }
        payment {
          id
          ownerId
          contributionId
          federatedOwner
          completeInfo
          amount
          amountPayed
          medium
          periodDate
          period
          kind
          payed
          paymentMedium
          paymentProviderResponse
          payedAt
          createdAt
          owner
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
export const listMitOperationRequests = /* GraphQL */ `
  query ListMitOperationRequests(
    $filter: ModelMitOperationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMitOperationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        paymentId
        contributionId
        rawDataRequest
        rawDataResponse
        publicDataResponse {
          foliocpagos
          cd_response
          cd_error
          nb_error
          time
          date
          cc_type
          cc_number
          amount
          number_tkn
          id_url
          cc_mask
        }
        mitOperationStatus
        reference
        createdAt
        updatedAt
        mitOperationRequestOwner {
          id
          federatedId
          email
          identificator
          globalEntity
          name
          lastname1
          lastname2
          birthDate
          isEmployed
          lastFetch
          id_pb_user
          curp
          rfc
          role
          phone
          estimatedIncome
          issuingEntities
          flowCompleted
          advisor
          afore
          pension_mensual_objetivo
          owner
          createdAt
          updatedAt
        }
        contribution {
          id
          ownerId
          paymentId
          federatedOwner
          goal
          scheduled
          periodicity
          ammount
          paymentMedium
          mitLink
          contributionStatus
          type
          payed
          paymentProviderResponse
          createdAt
          owner
          updatedAt
        }
      }
      nextToken
    }
  }
`;
