import styled from 'styled-components';
import { DarkBlue, White, BabyYellow2 } from '../../utilities';
import {device} from '../../utilities/media_queries'

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 75vh;
  right: 0;
  margin: 0;
  @media ${device.mobileS},
    ${device.mobileM}
    ${device.mobileL} {
      height: auto;
    }
  @media ${device.tablet},
    ${device.laptop}
    ${device.laptopL} {
      height: 75vh;
    }
  &>div:nth-child(1){
    display: flex;
    background-color:${BabyYellow2};
    border-radius:0 10px 10px 0;
    width: 25%;
    padding: 5% 2.5%;
    margin:0;
    flex-wrap: wrap;
    height:inherit;
    justify-content: center;
    align-content:space-between;
    &>img{
      margin-top:0;
      width:80%;
    }
    @media ${device.mobileS},
      ${device.mobileM}
      ${device.mobileL} {
        width: 100%;
        height:auto;
      }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        width: 25%;
        height: inherit;
      }
    }

    //Background selected
    .ant-steps-item-process .ant-steps-item-icon{
      background-color: ${DarkBlue};
      color:${White};
      border-color:${DarkBlue};
    }
    .ant-steps-item-finish .ant-steps-item-icon{
      background-color: #20CB81;
      border-color: #20CB81;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
      color:${White};
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
      color:${DarkBlue};
      font-weight:600;
    }
    //Background-non-selected
    .ant-steps-item-icon {
      color:${DarkBlue}
    }
    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: rgba(255,55,255,0);
      border-color:${DarkBlue};
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
      color:${DarkBlue}
    }

    //Line vertical
    .ant-steps-item-tail::after {
      width: 0;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      height: 0;
      width: 0;
    }
  }
  &>div:nth-child(2){
    width: 74%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    &>div:nth-child(1){
      height:80%;
    }
    @media ${device.mobileS},
      ${device.mobileM}
      ${device.mobileL} {
        width: 90%;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        width: 74%;
    }
  }
`;

export const StepsAction = styled.div`
  bottom: 0;
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:0 20%;
  backgroud-color: green;
  @media ${device.mobileS},
    ${device.mobileM}
    ${device.mobileL} {
      justify-content: space-evenly;
      flex-direction: row;
      flex-wrap: wrap;
    }
  @media ${device.tablet},
    ${device.laptop},
    ${device.laptopL}{
      justify-content: flex-end;
  }
  &>div{
    width: 50%;
    bottom: 0;
    @media ${device.mobileS},
      ${device.mobileM}
      ${device.mobileL} {
        width: 100%;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        width: 50%;
    }
  }
`
