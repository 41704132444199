import styled from 'styled-components';
import { device } from '../../../utilities/media_queries';
import { DarkBlue } from '../../../utilities/Colors';

export const MainSafeSavings = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 3%;
  margin: 0 5%;
  @media ${device.mobileS}, ${device.mobileM}, ${device.mobileL} {
    width: 100%;
    padding: 5% 3%;
    margin: 0;
  }
  @media ${device.tablet}, ${device.laptop}, ${device.laptopL} {
    width: 90%;
  }
  & > h2 {
    color: ${DarkBlue};
    font-weight: 700;
    text-align: left;
    @media ${device.mobileS}, ${device.mobileM}, ${device.mobileL} {
      font-size: 1.2rem;
    }
    @media ${device.tablet}, ${device.laptop}, ${device.laptopL} {
      font-size: 2rem;
    }
  }
  & > p {
    color: ${DarkBlue};
    font-weight: 300;
    width: '90%';
    margin-top: '20px';
  }
  & > h3 {
    color: ${DarkBlue};
    font-weight: 700;
    text-align: left;
  }
`;

export const OptionsSection = styled.div`
  width: 100%;
  height: 50%;
  overflow-y: scroll;
  margin-bottom: 5%;
`;

export const Option = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'rgba(255, 206, 0, .1)' : '#fff'};
  border: ${(props) =>
    props.selected ? '2px solid #FFCE00' : '1px solid #DBE2EA'};
  border-radius: 10px;
  padding: 1% 3%;
  margin: 2% 0;
  & > img {
    width: 45px;
    height: auto;
    margin-right: 15px;
  }
  & > span {
    & > h4 {
      color: #103d56;
      font-weight: 700;
    }
    & > p {
      color: ${DarkBlue};
      font-weight: 300;
    }
  }
  &:hover {
    background-color: rgba(255, 206, 0, 0.1);
    border: 1px solid #ffce00;
  }
`;
