import { AppActionTypes } from './app.types';

export const setAppLoading = () => ({
  type: AppActionTypes.SET_CURRENT_LOAD_STATE_TO_TRUE,
  payload: true
});

export const setAppNotLoading = () => ({
  type: AppActionTypes.SET_CURRENT_LOAD_STATE_TO_FALSE,
  payload: false
});