import styled from 'styled-components'
import {Yellow,White, DarkBlue} from '../../../utilities/Colors'
import {device} from '../../../utilities/media_queries'

export const ContainerWork = styled.div`
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 2% 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-image:url('https://aurumresources.s3.amazonaws.com/NewStyles/HowSection-01.svg');
	background-size: cover;
	width: 100%;
	@media ${device.mobileS},{
		padding-top: 60px; 
		margin-top: -30px;
	}
	@media ${device.mobileM}{
		padding-top: 55px; 
		margin-top: -30px;
	}
	@media ${device.mobileL} {
		padding-top: 50px;
		margin-top: -20px;
	}
	@media ${device.tablet},
	${device.laptop},
	${device.laptopL} {
		padding: 5% 0;
		margin-top: -20px;
	}
	&>div{
		&>h4{
		color:${DarkBlue};
		font-size:.8rem;
		font-weight: 400;
		width:70%;
		margin: 2% 15%;
		text-align: center;
			@media ${device.tablet},
			${device.laptop},
			${device.laptopL} {
				margin: 2% 15%;
				width: 70%;
			}
		}
	}
	&>h1{
		width: 100%;
		margin:0 0 0 10%;
		color:${DarkBlue};
		font-size: 3rem;
		font-weight: 700;
	}
	@media ${device.mobileS} {
		font-size:.7rem;
		&>h1{
			font-size: 1.8rem;
		}
	}
	@media ${device.mobileLM} {
		font-size:.7rem;
		&>h1{
			font-size: 1.8rem;
		}
	}
	@media ${device.mobileL} {
		font-size:.8rem;
		&>h1{
			font-size: 1.8rem;
		}
	}

`

export const ContainerSteps= styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items:center;
	width: 65%;
	margin: 0 2%;
	@media ${device.mobileS},
	${device.mobileM},
	${device.mobileL} {
		width: 90%;
		margin: 0 5%;
	}
	@media ${device.tablet},
	${device.laptop},
	${device.laptopL} {
		width: 65%;
		margin: 0 2%;
	}
	&>div{
		width: 90%;
		margin: 1%;
		display:flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		box-shadow: 0px 32px 32px #2C27381F;
		/* box-shadow:5px 10px 5px #2C273814; */
		border-radius:10px;
		background-color:${White};
		padding:0 5% 0 2%;
		cursor:pointer;
		&>p{
			font-size:.8rem;
			color:${DarkBlue};
			width: 90%;
			margin-left: 10%;
		}
	}

`

export const HowOne = styled.div`
	&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		margin: 1%;
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
`
export const HowTwo = styled.div`
&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		/* padding: 2%; */
		margin: 1%;
		/* margin:3% 1% 1% 1%; */
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
`
export const HowThree = styled.div`
	&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		/* padding: 2%; */
		margin: 1%;
		/* margin:3% 1% 1% 1%; */
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
`
export const HowFour = styled.div`
	&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		/* padding: 2%; */
		margin: 1%;
		/* margin:3% 1% 1% 1%; */
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
`
export const HowFive = styled.div`
	&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		/* padding: 2%; */
		margin: 1%;
		/* margin:3% 1% 1% 1%; */
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
	&>img{
		width: 28px;
		height: 28px;
	}
`
export const HowSix = styled.div`
	&>div{
		width: 80%;
		display: flex;
		flex-direction:row;
		align-items:center;
		&>img{
		width: 7%;
		height:auto;
		/* padding: 2%; */
		margin: 1%;
		/* margin:3% 1% 1% 1%; */
		}
		&>h2{
			padding: 2% 0;
			margin: 0 1%;
			color:${DarkBlue};
			font-weight:700;
			font-size:1.3rem;
			width:80%;
		}
	}
`
export const ImageSection = styled.div`
	width:28%;
	margin: 0 1.5%;
	align-content: center;
	@media ${device.mobileS},
	${device.mobileM},
	${device.mobileL} {
		width: 50%;
		margin 0 25%;
	}
	@media ${device.tablet},
	${device.laptop},
	${device.laptopL} {
		width:28%;
		margin 0 1%;
	}
	&>img{
		width: 100%;
		height: auto;
		padding: 5% 0;
	}
`
