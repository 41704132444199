import styled from 'styled-components'
import { DarkGray, DarkBlue, GrayWhite, Yellow, White, Gray } from '../../../../../utilities/Colors';

export const ConfirmationContainer = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  /* margin: 1%; */
  font-family: 'IBM Plex Sans', sans-serif;
  /* border: 2px solid; */
  /* &>div:nth-child(1),
    div:nth-child(3){
    display: flex;
    flex-direction: column;
    background-color: ${White};
    margin: 2% 2.5%;
    justify-content: space-evenly;
    width: 25%;
    text-align: center;
    color:${DarkBlue};
    box-shadow:5px 10px 5px #2C273814;
    border-radius:10px;
    &>p{
      width:50%;
      margin: 0 25%;
    }
    &>Button{
      width: 50%;
      margin: 2% 25%;
    }
    &>h2{
      font-size:2.3rem;
    }
    &>h3{
      padding: 2% 5%;
      color: ${DarkBlue};
    }
  }*/
  &>div:nth-child(2){
    display: flex;
    flex-direction: column;
    background-color: ${White};
    margin: 2% 5%;
    justify-content: space-evenly;
    width: 25%;
    text-align: center;
    color:${DarkBlue};
    box-shadow:5px 10px 5px #2C273814;
    &>p{
      width:50%;
      margin: 0 25%;
    }
    &>Button{
      width: 50%;
      margin: 2% 25%;
    }
    &>h2{
      color:${DarkGray};
      font-size:2.3rem;
    }
  }
`

export const InfoNext = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${White};
  margin: 2% 2.5%;
  justify-content: space-evenly;
  width: 25%;
  text-align: center;
  color:${DarkBlue};
  box-shadow:5px 10px 5px #2C273814;
  border-radius:10px;
  &>p{
      width:50%;
      margin: 2% 25%;
      padding: 1% 0;
      font-family:'IBM Plex Sans', sans-serif;
    }
`