import React from 'react';
import { connect } from 'react-redux';
import InkomLoader from '../../../assets/InkomLoader.png';
import { GeneralLoader } from './Loader.style';

const Loader = (props) => {
  return (
    <GeneralLoader {...props}>
      <img src={InkomLoader} alt="Cargando página"/>
    </GeneralLoader>
  );
};

const mapStateToProps = state => ({
  isLoading: state.app.isLoading
});

export default connect(mapStateToProps)(Loader);
