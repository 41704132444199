import React from 'react'
import {AuthContainer, StepOneLanding, AuthCont} from './AuthSec.style'
import Auth from '../../Auth/Auth'

export default function LandingAuth() {
    return (
      <AuthContainer>
          <StepOneLanding>
            <h2>LA FORMA MÁS RÁPIDA, SEGURA Y SENCILLA DE ASEGURAR TU RETIRO.</h2>
            <img src="https://aurumresources.s3.amazonaws.com/NewStyles/img-header%402x.png" alt="Inkom, planeación para el futuro"/>
          </StepOneLanding>
          <AuthCont>
            <Auth landing={true}/>
          </AuthCont>
        </AuthContainer>
    )
}
