import styled from 'styled-components';
import { Yellow, DarkBlue} from '../../../utilities';
import { device } from '../../../utilities/media_queries';

export const SSavingsContainer  = styled.div`
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 3%;
  margin: 0 5% 5% 5%;
  &>h2{
    color:${DarkBlue};
    font-weight: 700;
    text-align: left;
  }
  &>p{
    color:${DarkBlue};
  }
  &>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &>p{
      color:${DarkBlue};
      font-weight: 300;
      width:'90%';
      margin-top:'20px';
    }
    &>h3{
      color:${Yellow};
      font-weight: 700;
      text-align: left;
      font-size: .9rem;
      padding-left: 1%;
      }
  }
`;

export const SavingsForm = styled.div`
  margin: 1% 2%;
  padding: 1% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  border-bottom: 1px solid #DBE2EA;
  border-radius: 10px;
  &>img{
    margin: 1% 5%;
    width: 50px;
    height: auto;
  }
  &>div{
    width: 70%;
    text-align: left;
    padding: 1% 2%;
    &>p{
    margin: 2% 0;
    color:${DarkBlue};
    }
    &>h3{
    font-weight:700;
    color:${DarkBlue};
    }
  }

`;

export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  margin: 1% 0;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &>Button{
    margin: 3% 3%;
  }
`;