import { createSlice, createAsyncThunk, createDraftSafeSelector } from '@reduxjs/toolkit';
import { createNewUser, updateThisUser, getCurrentUser } from '../../middlewares/API/user/user.api';

const userAttr = {
  federatedId: '',
  email: '',
  name: '',
  lastname1: '',
  lastname2: '',
  birthDate: '',
  isEmployed: '',
  id_pb_user: '',
  curp: '',
  rfc: '',
  role: '',
  phone: '',
  estimatedIncome: '',
  issuingEntities: '',
  flowCompleted: '',
  advisor: '',
  afore: '',
  pension_mensual_objetivo: '',
  address: '',
  owner: '',
  userCards: [],
  userContributions: [],
  userAccounts: [],
  userTransactions: [],
  userPayments: []
};

// THUNKS
export const setCurrentUser = createAsyncThunk(
  'setCurrentUser',
  async id => {
    const response = await getCurrentUser(id);
    return response;
  }
);

export const updateCurrentUser = createAsyncThunk(
  'updateCurrentUser',
  async input => {
    const response = await updateThisUser(input);
    return response;
  }
);

export const createUser = createAsyncThunk(
  'createUser',
  async input => {
    const response = await createNewUser(input);
    return response;
  }
)
//

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentPage: 'profile',
    currentUser: userAttr,
    isLogged: false
  },
  reducers: {
    setCurrrentDashboardPage: (state, action) => {
      state.currentPage = action.payload;
    },
    checkCurrentAuthenticatedUser: (state, action) => {
      state.isLogged = action.payload;
    },
    resetState: state => {}
  },
  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
    },
    [setCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
    },
    [updateCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
    }
  }
});

export const { setCurrrentDashboardPage, resetState } = userSlice.actions

export default userSlice.reducer;

const selectSelf = (state) => state

export const userSelector = createDraftSafeSelector(
  selectSelf,
  state => state.user.currentUser
);

export const pageSelector = createDraftSafeSelector(
  selectSelf,
  state => state.user.currentPage
);
