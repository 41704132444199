export const getImageURL = name => {
  if (!name) return
  let formatname =name.toLowerCase().split(' ').join('_');
  const imagesDictionary = {
    afirme: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-afirme%402x.png',
    american_express: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-american%402x.png',
    banamex: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-citi%402x.png',
    banbajio: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-banbajio%402x.png',
    banco_azteca: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-bancoazteca%402x.png',
    bancoppel: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-bancoppel%402x.png',
    banorte_ixe: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-banorte%402x.png',
    banregio: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-banregio%402x.png',
    bbva_bancomer: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-bbva%402x.png',
    bxt: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-bxt%402x.png',
    cibanco: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-cibanco%402x.png',
    citibanamex: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-citi%402x.png',
    hsbc: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-hsbc%402x.png',
    inbursa: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-inbursa%402x.png',
    invex: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-invex%402x.png',
    liverpool: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-kiverpool%402x.png',
    mibanco: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-mibanco%402x.png',
    mifel: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-mifel%402x.png',
    banco_multiva: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-multiva%402x.png',
    santander: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-santander%402x.png',
    scotiabank: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-scotiabank%402x.png',
    vexi: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-vexi%402x.png',
    elpalaciodehierro: 'https://aurumresources.s3.amazonaws.com/NewStyles/logo-palacio%402x.png',
    sears: 'https://aurumresources.s3.amazonaws.com/NewStyles/sears.png'
  };

  return imagesDictionary[formatname];
};

export const ccFormat = value => {
  if (value == null){
    return 'Identificador Oculto'
  };
  let chars = value.split('');
  let valueAsString = String(value);
  if(chars[0] === '*') {
    return `**** **** **** ${value.replaceAll('*', '')}`
  } else if(value.length === 4) {
    return `**** **** **** ${value}`
  }
  let v = valueAsString.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  let matches = v.match(/\d{4,16}/g);
  let match = (matches && matches[0]) || '';
  let parts = [];

  let len=match.length;
  for (let i=0; i < len; i += 4) {
      parts.push(match.substring(i, i+4))
  };

  return parts.length ? parts.join(' ') : value;
}

export const toCurrency = integer => {
  return `$ ${integer}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}