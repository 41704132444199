import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useDispatch, useSelector} from 'react-redux';
import { updateCurrentUser, setCurrrentDashboardPage, userSelector } from '../../../../store/user/user.slice';

import { Slider, Modal } from 'antd';

import DashChart from '../../../layout/Charts/DashChart';
import { ContainerDashRec, Actual, Suggest, Compare, Benefit, InformationRec} from './Recommendation.style';

const Recommendation = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [recommendation, setRecommendation] = useState(null);
  const [estimatedBalance, setEstimatedBalance] = useState(null);

  const slideMarks = {
    5000: '$5,000.00',
    50000: '$50,000.00',
  };

  const slideMarksBalance = {
    1000: '$1,000.00',
    1000000: '$1,000,000.00',
  };

  const onGoalChange = (goal) => {
    recommendationBase(goal);
  };

  const onBalanceChange = (goal) => {
    setEstimatedBalance(goal);
  };

  const onPageChange =  () => {
    dispatch(setCurrrentDashboardPage('buyingSaving'));
  };

  const recommendationBase = async (expectedPension) => {
    const parsedPension = Number(expectedPension);
    const parsedUserPension = Number(user.pension_mensual_objetivo);
    const url = "https://hj4ke6dihj.execute-api.us-east-1.amazonaws.com/testing/proyeccion";

    if(!user.curp) {
      Modal.error({
        title: 'Aún no tenemos tu información completa.',
        content: "Dirígete a la sección Perfil y actualiza tu CURP",
        onOk() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage('profile'));
        },
        onCancel() {
          Modal.destroyAll();
          dispatch(setCurrrentDashboardPage('profile'));
        }
      });
      return;
    } else if(!expectedPension) {
      Modal.warn({
        title: 'Selecciona una meta para tu retiro',
        content: "En la siguiente sección podrás decidir con qué monto mensual deseas vivir tu retiro.",
        onOk() {
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        }
      });
      return;
    };

    let functionData = {
      "CURP": user.curp,
      "Saldo_AFORE": 0,
      "pension_mensual_objetivo": parsedPension,
      "tasa_i": 0.07,
      "edad_retiro": 65,
      "porcentaje_aportacion_actual": 0.025,
      "aport_mensual_actual": 300,
      "porc_aportacion_afore": 0.035,
      "increm_ingreso": 0.03,
    };

    await axios.post(url, functionData, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
    .then(async responseLambda => {
      console.log({responseLambda});
      setRecommendation(prevState => ({...prevState, ...responseLambda.data}));
      try {
        if(parsedPension !== parsedUserPension) {
          let input = {
            id: user.id,
            pension_mensual_objetivo: parsedPension
          };
          await dispatch(updateCurrentUser(input));
        }
      } catch(error) {
        console.log({error});
      }
    })
    .catch(err => console.log({err}));
  };

  useEffect(() => {
    recommendationBase(user.pension_mensual_objetivo);
  }, []);

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return (
    <InformationRec>
      <h1>PROYECCIÓN</h1>
      <ContainerDashRec>
        <h2>Meta mensual</h2>
        {
          user ?
          <Actual>
            <p>Meta mensual al momento del retiro:</p>
            <div>
              <button>{user?.pension_mensual_objetivo ? formatter.format(Number(user.pension_mensual_objetivo)) : 'No asignado'} </button>
              {/* onClick={recommendationBase} */}
              <Slider
                marks={slideMarks}
                defaultValue={user?.pension_mensual_objetivo ? Number(user.pension_mensual_objetivo) : 0}
                min={5000}
                max={50000}
                step={5000}
                trackStyle={{ backgroundColor: "#FFC200"}}
                handleStyle={{ borderColor: "#2C27381F"}}
                onChange={onGoalChange}

              />
            </div>
          </Actual>
        :
        <div>Cargando....</div>
        }
        <h2>Saldo AFORE estimado</h2>
        {
          user ?
          <Actual>
            <p>Saldo acumulado que aparece en tu Estado de cuenta:</p>
            <div>
              <button>{formatter.format(Number(estimatedBalance))} </button>
              {/* onClick={recommendationBase} */}
              <Slider
                marks={slideMarksBalance}
                defaultValue={0}
                min={1000}
                max={1000000}
                step={50000}
                trackStyle={{ backgroundColor: "#FFC200"}}
                handleStyle={{ borderColor: "#2C27381F"}}
                onChange={onBalanceChange}
              />
            </div>
          </Actual>
        :
        <div>Cargando....</div>
        }
        <h2>Nuestra recomendación</h2>
        <Suggest>
          <div>
            <p>Recomendación por porcentaje de compras</p>
            <h3> {recommendation?.porcentaje_sugerido ? `${((recommendation?.porcentaje_sugerido) * 100).toFixed(2)} %` : "0 %"}</h3>
          </div>
          <div style={{padding: "auto 5%"}}>
            <p>Aportación mensual sugerida por Inkom</p>
            <h3>{recommendation?.aportacion_mensual_sugerida ? `$ ${recommendation?.aportacion_mensual_sugerida.toFixed(2)}` : "$0.00"}</h3>
          </div>
          <div>
            <p>Actual por porcentaje de compras</p>
            <h3>0 %</h3>
          </div>
          <div>
            <p>Monto aproximado mensual de ahorro</p>
            <h3>$0.00</h3>
          </div>
        </Suggest>
        {/* <p onClick={onPageChange}>Si deseas cambiar tu esquema da click <span style={{fontFamily: "IBM Plex Sans", fontWeight:"600",color:"#FFCE00", cursor: "pointer"}}>aquí.</span></p> */}
        <h2>Estimaciones</h2>
        <Benefit>
          <DashChart recommendation={recommendation}/>
        </Benefit>
        <h2>Compara rendimientos</h2>
        <Compare>
          <div>
            <h4>Cetes directos</h4>
            <h3>6%</h3>
            <p>Información referente a cetes directos</p>
          </div>

          <div>
            <h4>Promedio AFORES</h4>
            <h3>7%</h3>
            <p>Información referente a promedio AFORES</p>
          </div>

          <div>
            <h4>Tasa pasiva bancos</h4>
            <h3>6.5%</h3>
            <p>Información referente a tasa pasiva bancos</p>
          </div>
        </Compare>
      </ContainerDashRec>
    </InformationRec>
  )
};

export default Recommendation;