import styled from 'styled-components';

export const MainAccountStatement =  styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    span {
      width: 20%;
      display: flex;
      ${'' /* flex-direction: column; */}
      text-align: left;
      ${'' /* align-items: center; */}
    }
  }
`;

export const AccountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      &>div:nth-child(1) {
        width: 100%;
        background-color: lightgray;
      }
      &>div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: 1.5px solid black;
        span {
          display: flex;
          width: 20%;
          text-align: left;
        }

      }
    }

`;

// export const TitleSection = styled.div`
//   display
// `;