import styled from 'styled-components';
import { Yellow } from '../../utilities/Colors'

export const FaqsContainer = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 2% 3%;
    padding: 2%;
    &>h1{
        text-align: center;
        color:${Yellow};
        font-weight:600;
    }
`