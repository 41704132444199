import styled from 'styled-components';
import { device } from '../../utilities/media_queries';

export const GeneralContainer = styled.section`
  display: flex;
  flex-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: auto;
@media ${device.mobileS} {

}
@media ${device.tablet} {

}
@media ${device.laptop} {

}
@media ${device.desktop} {

}
`;

export const AddAccountCotainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  /* height: 25vh; */
  @media ${device.mobileS} {

  }
  @media ${device.tablet} {

  }
  @media ${device.laptop} {

  }
  @media ${device.desktop} {

  }
`;
