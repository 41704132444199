import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { urls } from './DashboartMenu.utils';
import { SideMenuD, NavigationItems, NavigationSection } from './DashboardMenu.style';

import { setCurrrentDashboardPage, pageSelector, resetState } from '../../../../store/user/user.slice';

import { persistor } from '../../../../store/store';
import useWindowSize from '../../../../hooks/useWindowSize';

const DashboardMenu = () => {

  const dashboardPage = useSelector(pageSelector);
  const dispatch = useDispatch();
  const size = useWindowSize()
  const [isMenuOpen, setIsMenuOpen] = useState(true)

	useEffect(() => {
    dispatch(setCurrrentDashboardPage(dashboardPage));
	}, []);

	useEffect(() => {
    if (size.width < 767) {
      setIsMenuOpen(false)
    }
    if (size.width > 768) {
      setIsMenuOpen(true)
    }
	}, [size.width]);

	let history = useHistory();

	const handlePageChange = page => {
    dispatch(setCurrrentDashboardPage(page));
	};

	const handleSignOut = async () => {
		try {
      localStorage.removeItem('user')
      await dispatch(resetState());
      persistor.purge()
			await Auth.signOut();
			history.push("/");
		} catch(err) {
			alert("Hubo un error en la solicitud", err);
		};
	};

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  };

	return (
		<SideMenuD>
			<div>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Link to="/"><img  alt="Inkom. Todo Suma" src='https://aurumresources.s3.amazonaws.com/inkom/inkom_logo.png'/></Link>
          {
            size.width < 768 &&
            <img  alt="Menu" src={urls.hamburger} style={{cursor: 'pointer'}} onClick={toggleMenu}/>
          }
        </div>
        {
          isMenuOpen && (
            <NavigationSection>
              <NavigationItems>
                <h4 style={ dashboardPage === 'ahorro' ? {fontWeight: 900}: {fontWeight: 300}} onClick={() => handlePageChange('ahorro')}>
                  <img src={urls.dashboard} alt='Dashboard icon'/>Dashboard{dashboardPage === 'ahorro' && <img src={urls.selected} alt="selector menu"/>}
                </h4>
              </NavigationItems>
              <NavigationItems>
                <h4 style={ dashboardPage === 'myAccounts' ? {fontWeight: 900}: {fontWeight: 300}} onClick={() => handlePageChange('myAccounts')}>
                  <img src={urls.temporary} alt='Cuentas icon'/>Detalle Inkom{	dashboardPage === 'myAccounts' && <img src={urls.selected} alt="selector menu"/>}
                </h4>
              </NavigationItems>
              <NavigationItems>
                <h4 style={ dashboardPage === 'buyingSaving' ? {fontWeight: 900}: {fontWeight: 300}} onClick={() => handlePageChange('buyingSaving')}>
                  <img src={urls.ahorro} alt='Ahorro icon'/>Mi Ahorro{	dashboardPage === 'buyingSaving' && <img src={urls.selected} alt="selector menu"/>}
                </h4>
              </NavigationItems>
              <NavigationItems>
                <h4 style={ dashboardPage === 'recommendations' ? {fontWeight: 900}: {fontWeight: 300}} onClick={() => handlePageChange('recommendations')}>
                  <img src={urls.proyeccion} alt='Proyeccion icon'/>Proyección{	dashboardPage === 'recommendations' && <img src={urls.selected} alt="selector menu"/>}
                </h4>
              </NavigationItems>
              <NavigationItems>
                <h4 style={ dashboardPage === 'profile' ? {fontWeight: 900}: {fontWeight: 300}} onClick={() => handlePageChange('profile')}>
                  <img src={urls.perfil} alt='Perfil'/>Perfil{	dashboardPage === 'profile' && <img src={urls.selected} alt="selector menu"/>}
                </h4>
              </NavigationItems>
            </NavigationSection>
          )
        }
      </div>
      {
        isMenuOpen && (
          <NavigationItems style={{bottom: 0, marginLeft: '12px'}}>
            <h3 onClick={handleSignOut} style={{cursor: 'pointer'}}><img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon.logout.svg"/>Cerrar sesión</h3>
          </NavigationItems>
        )
      }
    </SideMenuD>
  );
};

export default DashboardMenu;