import React, { useState, useEffect } from 'react'
import { ContainerWork, ContainerSteps, ImageSection, HowOne, HowTwo, HowThree, HowFour, HowFive, HowSix} from './HowWorks.style'
import { PlusCircleOutlined  } from '@ant-design/icons';
import { howSteps, bannerImgHowWorks } from './HowWorks.utils';

export default function HowWorks() {
	const [stepSelected, setStepSelected] = useState(null);
	useEffect(() => {
		window.scrollTo(0,200)
},[])

	useEffect(() => {

	}, [stepSelected])

	const handleHowWorks = (step) => {
		if(step === stepSelected) {
			setStepSelected(null);
			return;
		}
		setStepSelected(step);
	};



  return (
		<div id='como-funciona-inkom'>
			<ContainerWork>
			<h1>CÓMO FUNCIONA</h1>
				<ContainerSteps>
					<HowOne onClick={() => handleHowWorks(1)}>
						<div>
							<img alt="StepIndex" src={stepSelected === 1 ? howSteps.one.selected : howSteps.one.nonSelected}/>
							<h2>Da de alta</h2>
						</div>
							<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="MinusSign" src={ stepSelected === 1 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 1 ?
							<p>Da de alta tu cuenta únicamente con tu correo electrónico.</p> :
							null
						}
					</HowOne>
					<HowTwo onClick={() => handleHowWorks(2)}>
						<div>
							<img alt="StepIndex" src={stepSelected === 2 ? howSteps.two.selected : howSteps.two.nonSelected}/>
							<h2>Registra</h2>
						</div>
						<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="PlusSign" src={ stepSelected === 2 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 2 ?
							<p>Registra tu información en menos de 5 minutos.</p> :
							null
						}
					</HowTwo>
					<HowThree onClick={() => handleHowWorks(3)}>
						<div>
							<img alt="StepIndex" src={stepSelected === 3 ? howSteps.three.selected : howSteps.three.nonSelected}/>
							<h2>Identifica</h2>
						</div>
						<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="PlusSign" src={ stepSelected === 3 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 3 ?
							<p>Identifica tu AFORE a través de tu CURP.</p> :
							null
						}
					</HowThree>
					<HowFour onClick={() => handleHowWorks(4)}>
						<div>
						<img alt="StepIndex" src={stepSelected === 4 ? howSteps.four.selected : howSteps.four.nonSelected}/>
							<h2>Selecciona</h2>
						</div>
						<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="PlusSign" src={ stepSelected === 4 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 4 ?
							<p>Elige el mecanismo de ahorro, qué tipo de tarjeta y el porcentaje de compras que deseas ahorrar al mes.</p> :
							null
						}
					</HowFour>
					<HowFive onClick={() => handleHowWorks(5)}>
						<div>
							<img alt="StepIndex" src={stepSelected === 5 ? howSteps.five.selected : howSteps.five.nonSelected}/>
							<h2>Aporta</h2>
						</div>
						<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="PlusSign" src={ stepSelected === 5 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 5 ?
							<p>Cada quincena o cada mes te enviaremos un mensaje para que dependiendo del porcentaje que hayas elegido, realices una aportación de ahorro a tu cuenta de AFORE. Tu ahorro lo podrás disfrutar en el plazo que hayas elegido.</p> :
							null
						}
					</HowFive>
					<HowSix onClick={() => handleHowWorks(6)}>
						<div>
							<img alt="StepIndex" src={stepSelected === 6 ? howSteps.six.selected : howSteps.six.nonSelected}/>
							<h2>Disfruta</h2>
						</div>
						<img style={{width: "28px", height: "28px", marginTop:"2%"}} alt="PlusSign" src={ stepSelected === 6 ? howSteps.controls.minus : howSteps.controls.plus}/>
						{
							stepSelected === 6 ?
							<p>Además, Inkom te da información sobre el ahorro generado por compras, así como cuanto y cuando puedes disponer de tu dinero.</p> :
							null
						}
					</HowSix>
				</ContainerSteps>
				<ImageSection>
					<img alt="HowWorks" src={bannerImgHowWorks}/>
				</ImageSection>
				<div>
					<h4>Siempre puedes modificar tus esquemas de ahorro con sólo dos clics.</h4>
					<h4>
						O si lo prefieres, pueden optar por un ahorro tradicional a través de domiciliaciones o aportaciones únicas.
						Con algunos de nuestros socios estratégicos, recibirás beneficios como descuentos, regalos o aportaciones a tu cuenta de ahorro de AFORE que no salen de tu bolsillo.
						Podrás ver nuestros socios y promociones cada vez que accedas a Inkom.
					</h4>
				</div>

			</ContainerWork>
		</div>
		)
}
