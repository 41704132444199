import styled from 'styled-components';
import {DarkBlue, GrayWhite, Green, White} from '../../utilities/Colors'
import {device} from '../../utilities/media_queries'

export const NavbarS = styled.div`
    display: flex;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 700;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:1% 0;
    background-color:${White};
    top: 0;
    width: 100%;
    margin: 0;
    position: fixed;
    
    @media ${device.mobileS} {
      width:100%;
      font-weight: 700;
      &>p{
        font-family: 'Open Sans Condensed', sans-serif;
        font-Size: .7rem;
      }
    }
    z-index: 1;
`;

export const  NavSec1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 3%;
  width:40%;
  &>a {
    &>img{
      @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL}{
        height: 3vh; 
        margin: 0;
      }
      @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL}{
        height: 5vh;
        margin-left: 5%;
      }
    }
  }
  &>p {
    margin: 0;
    color:black;
    font-Size: 1.1rem;
  }
  @media ${device.mobileS} {
      &>p{
        font-family: 'Open Sans Condensed', sans-serif;
        font-Size: .7rem;
      }
    }
    @media ${device.mobileM} {
      &>p{
        font-family: 'Open Sans Condensed', sans-serif;
        font-Size: .8rem;
      }
    }
    @media ${device.mobileL} {

      &>p{
        font-family: 'Open Sans Condensed', sans-serif;
        font-Size: .9rem;
      }
    }
    @media ${device.tablet} {
      &>p{
        font-family: 'Open Sans Condensed', sans-serif;
        font-Size: .9rem;
      }
    }
    @media ${device.laptop} {
    &>p{
      font-family: 'Open Sans Condensed', sans-serif;
      font-Size: 1.2rem;
    }
    }
    @media ${device.laptopL} {
      width: 45%;
    &>p{
      font-Size: 1.4rem;
    }
    }
    @media ${device.desktop} {
      width: 45%;
      &>p{
        font-Size: 1.8rem;
      }
  }
`;

export const NavMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    @media ${device.mobileS}{
      width: 100%;
    }
    @media ${device.mobileM},
      ${device.mobileL}{
      width: 60%;
    }
    @media ${device.tablet} {
      width: 60%;
    }
    @media ${device.laptop} {
      width: 60%;
    }
    @media ${device.laptopL} {
      width: 50%;

    }
    &>a{
      text-align: center;
      text-decoration: none;
      padding:0;
      margin: 0 2%;
      border-radius:2px;
      color: ${DarkBlue};
      font-size: 1.1rem;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL}{
          font-size:.6rem;
      }
      @media ${device.tablet} {
        font-size:.8rem;
      }
      @media ${device.laptop} {
        font-size:.9rem;
      }
    }
    /* @media ${device.mobileS} {
    font-Size: .8rem;
    margin: 0 15%;
    width:70%;
    &>a{
      width: 180px;
      margin: 3% 1%;
    }
    }
  @media ${device.mobileM} {
    font-Size: .8rem;
    margin: 0 15%;
    width:70%;
    &>a{
      width: 180px;
      margin: 3% 1%;
    }
    }
	@media ${device.mobileL} {
    font-Size: .8rem;
    margin: 0 25%;
    width:50%;
    &>a{
      width: 180px;
      margin: 3% 1%;
    }
  }
  @media ${device.tablet} {
  font-Size: .8rem;
  width: auto;
  margin: 0;
    &>a{
      width: 80px;
      margin: 3% 1%;
    }
  } */
  /* @media ${device.laptop} {
  font-Size: .9rem;
  width: auto;
  margin: 0;
		&>a{
      width: 120px;
		margin: 3% 1%;
	  }
  }
  @media ${device.laptopL} {
  font-Size: 1rem;
  width: auto;
  margin: 0;
		&>a{
		width: 130px;
		margin: 5% 1%;
    }
  } */
  /* @media ${device.desktop} {
    font-Size: 1.3rem;
    width: auto;
    margin: 0;
		&>a{
		width: 230px;
		margin: 5% 1%;
	}
} */
`;
