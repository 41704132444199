import React from 'react';
import { Popover, Button, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { SSavingsContainer, SavingsForm } from './TraditionalSavings.style';

const TraditionalSavings = () => {
  return (
    <SSavingsContainer>
      <h2>¿Cuándo quieres acceder a tu ahorro?</h2>
      <p>
      Además del ahorro cuando compras, puedes elegir de manera complementaria ahorro a través de domiciliaciones o aportaciones de única ocasión.
      </p>
      <div>
        <p>Sólo tienes que ir a la sección </p><h3> AHORRO TRADICIONAL :</h3>
      </div>
      
      <SavingsForm>
        <img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-periodica.svg" alt="Pago domiciliado"/>
        <div>
          <h3>Periódicas</h3>
          <p>Indica el monto, frecuencia y próxima fecha de pago.</p>
        </div>
        {/* <Button type="primary" onClick={handleListVisibility}>Sincronizar</Button> */}
      </SavingsForm>
      
      <SavingsForm>
        <img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-unica.svg" alt="Pago única ocasión"/>
        <div>
        <h3>Únicas</h3>
        <p>Indica el monto y la realización del pago.</p>
        </div>
        {/* <Button type="primary" onClick={() => modal_info('codi')}>Más información</Button> */}
      </SavingsForm>
    </SSavingsContainer>
  );
};
export default TraditionalSavings;