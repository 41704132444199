import { API, graphqlOperation } from 'aws-amplify';
import { createCard, updateCard } from '../../../graphql/mutations';
import { getCard } from '../../../graphql/queries';
import { getMyCards } from '../../../graphql/customqueries';

export const createOneCard = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createCard, {input}));
  return rawResponse.data.createCard;
};

export const getSpecificCard = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getCard, {id}));
  return rawResponse.data.getCard;
};

export const updateExistentCard = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updateCard, {input}));
  return rawResponse.data.updateCard;
};

export const listCards = async id => {
    const rawResponse = await API.graphql(graphqlOperation(getMyCards, {ownerId: id}));
    return rawResponse.data.listCards.items;
};
