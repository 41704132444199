import React from 'react';
import { Skeleton, Card } from 'antd';
import { BankOutlined, CreditCardOutlined, ShoppingOutlined, PlusOutlined } from '@ant-design/icons';

const { Meta } = Card;

const AccountCard = (props) => {
  const {
    type,
    title,
    description,
  } = props;

  const handleAddMedium = e => {
    props.addSpecificMedium(type);
  };

  return (
    <Card
      style={{ width: '90%', marginTop: 16, marginBottom: 16 }}
      actions={[
        <div onClick={handleAddMedium}><PlusOutlined key="add" value="add"/><br />Añadir Tarjeta</div>,
      ]}
    >
      <Skeleton avatar active>
        <Meta
          avatar={
            type === 'debit' ? <BankOutlined /> : type === 'credit' ? <CreditCardOutlined /> : <ShoppingOutlined />
          }
          title={title}
          description={description}
        />
      </Skeleton>
    </Card>
  );
};

export default AccountCard;
