import styled from 'styled-components'
import { device } from '../../../utilities/media_queries';
import { Yellow,  DarkBlue, Gray, DarkGray, GrayWhite} from '../../../utilities/Colors'

  export const TraditionalGeneral = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 5% 0;
  width: 100%;
  display: table;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin: 5% 0 5% 0;
    &>h2{
      color:${DarkGray}
    }
    @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL},
    ${device.tablet}{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 96%;
      margin: 0 2%;
    }
    @media ${device.laptop},
    ${device.laptopL},
    ${device.desktop} {
      width: 96%;
      padding: 5% 0;
      display: table;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      margin: 0 2%;
    }
  `
export const SectionOne = styled.div`
  display: table-cell;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin:2%;
  margin-Bottom:5%;
  text-align: center;
  vertical-align: middle;
  background-color: #FAFAFA;
  border-top: 8px solid ${DarkBlue};
  border-bottom: 5px solid ${DarkBlue};
  &>h3{
    color:${DarkGray};
  }
  @media ${device.mobileS} {
      width: 90%;
    }
  @media ${device.mobileM},
    ${device.mobileL} {
      width: 85%;
    }
  @media ${device.tablet} {
      width: 65%;
      margin: 2%;
    }
  @media ${device.laptop},
    ${device.laptopL}  {
      width: 30%;
      margin: 3%;
      border-top: 8px solid ${DarkBlue};
    border-bottom: 8px solid ${DarkBlue};
    }

`

export const HeadTraditional = styled.div`
  display: 'flex';
  flex-direction: 'row';
  width:'100%';
  flex-wrap: 'wrap';
  color: pink;
    &>h2{
      color:orange
      background-color: green;
    }
`

export const SectionTwo = styled.div`
  width: 60%;
  margin:2%;
  background-color: #FAFAFA;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-Bottom:5%;
  @media ${device.mobileS} {
    width: 90%;
    }
  @media ${device.mobileM},
    ${device.mobileL} {
      width: 85%;
    }
  @media ${device.tablet} {
    width: 65%;
    margin: 2%;
    // border-top: 8px solid ${DarkBlue};
    // border-bottom: 8px solid ${DarkBlue};
  }
  @media ${device.laptop},
  ${device.laptopL},
  ${device.desktop} {
    width: 60%;
    margin: 3%
    // border-top: 8px solid ${DarkBlue};
    // border-bottom: 8px solid ${DarkBlue};
  }
  &>p{
    font-family:'Open Sans Condensed', sans-serif;
  }
  &>button{
    border-radius: none;
    border:1px solid ${Yellow};
    height: auto;
    transition: .5s ease-in;
    cursor: pointer;
    margin: 3% 0;
    @media ${device.tablet} {
      font-size: .8rem;
      border-radius: none;
      }
      @media ${device.laptop} {
        font-size: .9rem;
        /* margin: 1%; */
        border-radius: none;
        /* width: 45%; */
      }
      @media ${device.laptopL} {
      font-size: 1.1rem;
      /* margin: 5% 1%; */
      /* width: 35%; */
      }
      @media ${device.desktop} {
      font-size: 1.6rem;
      /* width:38%; */
      /* margin: 4.5% 1%; */
      }
    }
`
export const PeriodSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  border-bottom: 3px solid ${GrayWhite};
  margin-top: 3%;
  &>h2{
    width: 90%;
    color:${DarkGray};
  }
`

export const DataSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  margin: 10% 5%;
  &>div{
    width:auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
    margin: 5% 5% 0 5%;
    &>h3{
      color:${DarkGray};
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL},
        ${device.tablet}{
          width:90%;
          margin: 0 5%;
        }
      @media ${device.laptop},
        ${device.laptopL},
        ${device.desktop} {
          width: 70%;
          margin: 3%
        }
    }
  }
`
export const PaymentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin:2%;
  @media ${device.tablet} {
  margin-bottom:5%;
  }
`;

export const PaypalButtonStyles = styled.div`
  background-image: 'https://yavanpics181626-dev.s3.us-east-2.amazonaws.com/public/conekta.png'
`;