import React, { useState } from 'react';
import { MainSafeSavings, OptionsSection, Option } from './Benefits.style';

const Benefits = () => {
  const [option, setOption] = useState(null);

  const handleOptionChange = (index) => {
    setOption(index);
  };

  return (
    <MainSafeSavings>
      <h2>Otros mecanismos de ahorro</h2>
      <p>
        Puedes elegir cuando podrás disponer de tu ahorro de acuerdo a tus
        necesidades.
      </p>
      <h3 style={{ margin: '20px 0' }}>
        SELECCIONA EL HORIZONTE DE TUS APORTACIONES:{' '}
      </h3>
      <OptionsSection option={option}>
        <Option selected={option === 1} onClick={() => handleOptionChange(1)}>
          <img
            alt="RetirementShort"
            src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-cortopPlazo-01.svg"
          />
          <span>
            <h4>Aportación Voluntaria No Deducible</h4>
            <p>
              Se puede utilizar como un instrumento de ahorro a corto plazo. No
              tiene beneficio fiscal. El trabajador puede disponer de estos
              recursos de 2 o 6 meses después del primer depósito o último
              retiro dependiendo del fondo que el trabajador haya elegido (para
              Multifondo 1 son 2 meses). Cuando lo retira se realiza una
              retención del 0.08% (vigente al ejercicio fiscal 2022 sobre el
              tiempo que estuvo aportado dicho monto).
            </p>
          </span>
        </Option>
        <Option selected={option === 2} onClick={() => handleOptionChange(2)}>
          <img
            alt="RetirementLong"
            src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-largoPlazo-01-01.svg"
          />
          <span>
            <h4>Aportación Voluntaria Deducible</h4>
            <p>
              Pueden ser deducibles de impuestos hasta un 10% del ingreso anual,
              sin que rebase $163,467.00. El trabajado puede retirar o disponer
              de estos recursos de 2 o 6 meses después del primer depósito o
              último retiro dependiendo del fondo que el trabajador haya
              elegido, pero se realiza una retención del 20% sobre el monto
              total a disponer. A partir de los 65 años, o en caso de obtener
              una pensión por invalidez o incapacidad podrá disponer de los
              recursos y solamente se le aplicaría una retención del 0.08%
              (vigente al ejercicio fiscal 2022 sobre el tiempo que estuvo
              aportado dicho monto).
            </p>
          </span>
        </Option>
        <Option selected={option === 3} onClick={() => handleOptionChange(3)}>
          <img
            alt="RetirementComplementary"
            src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-complementariasPlazo-01-01.svg"
          />
          <span>
            <h4>Aportación Complementaria de Retiro</h4>
            <p>
              Estas aportaciones sí pueden ser deducibles de impuestos hasta un
              10% del ingreso anual, sin que rebase $163,467.00. Esta aportación
              está diseñada para sólo permitir el retiro de los recursos una vez
              que trabajador tiene derecho a una pensión y se le aplicaría una
              retención del 0.08% (vigente al ejercicio fiscal 2022 sobre el
              tiempo que estuvo aportado dicho monto).
            </p>
          </span>
        </Option>
        <Option selected={option === 4} onClick={() => handleOptionChange(3)}>
          <img
            alt="RetirementComplementary"
            src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-complementariasPlazo-01-01.svg"
          />
          <span>
            <h4>Aportación de Ahorro a Largo Plazo</h4>
            <p>
              Cuenta con un beneficio fiscal que permite el diferimiento de
              impuestos sobre una aportación de hasta $152,000; es decir, el
              impuesto se paga al momento de disponer del recurso. Dicha
              aportación se puede retirar a partir del 5° año de permanencia y
              se efectuará una retención de impuestos del 35%. Una vez que el
              trabajador realice su declaración de impuestos, el SAT es quien
              realizará el ajuste del impuesto que deberá pagar en función de
              sus ingresos.
            </p>
          </span>
        </Option>
      </OptionsSection>
    </MainSafeSavings>
  );
};

export default Benefits;
