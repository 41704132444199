import React from 'react';
import {NavbarS, NavSec1, NavMenu} from './Navbar.style';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const toTop = ()=>{
    window.scrollTo(400,0)
  }
  return (
    <NavbarS>
      <NavSec1>
        <Link onClick={toTop} to="/"><img alt="Ir a inicio. Inkom" src='https://aurumresources.s3.amazonaws.com/inkom/inkom_logo.png'/></Link>
      </NavSec1>
      <NavMenu>
        <a href="/#acerca-de-inkom">ACERCA DE INKOM</a>
        <a href="/#elige-inkom">ELIGE INKOM</a>
        <a href="/#como-funciona-inkom">CÓMO FUNCIONA</a>
        <a href="/#contacta-a-inkom">CONTACTO</a>
      </NavMenu>
    </NavbarS>
  );
};

export default Navbar;
