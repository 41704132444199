import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { listTransactions, createNewTransaction, updateExistentTransaction } from '../../middlewares/API/transactions/transactions.api';
import { updateTransactionsToToday } from '../../middlewares/communication/transactions/transactions.communication';

const transactionsAdapter = createEntityAdapter();

// THUNKS
export const listAllTransactions = createAsyncThunk(
  'transactions/listAllTransactions',
  async (id) => {
    const response = await listTransactions(id);
    return response;
  }
);

export const createTransaction = createAsyncThunk(
  'transactions/createTransaction',
  async input => {
    const response = await createNewTransaction(input);
    return response;
  }
);

export const updateTransaction = createAsyncThunk(
  'transactions/updateTransaction',
  async input => {
    const response = await updateExistentTransaction(input);
    return response;
  }
);

export const updateTransactionsList = createAsyncThunk(
  'transactions/updateTransactionsList',
  async ({account, user}) => {
    const response = await updateTransactionsToToday(account, user);
    return response;
  }
);
//

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: transactionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listAllTransactions.fulfilled]: (state, action) => {
      transactionsAdapter.upsertMany(state, action.payload)
    },
    [createTransaction.fulfilled]: transactionsAdapter.upsertOne,
    [updateTransaction.fulfilled]: transactionsAdapter.upsertOne,
    [updateTransactionsList.fulfilled]: (state, action) => {
      transactionsAdapter.upsertMany(state, action.payload)
  }}
});

export default transactionsSlice.reducer;

export const {
  selectAll: selectAllTransactions,
  selectById: selectTransactionById,
  selectIds: selectTransactionIds
} = transactionsAdapter.getSelectors(state => state.transactions);
