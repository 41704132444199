import { API, graphqlOperation } from 'aws-amplify';
import { createAccount, updateAccount } from '../../../graphql/mutations';
import { getAccount } from '../../../graphql/queries';
import { getMyAccounts } from '../../../graphql/customqueries';

export const createNewAccount = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createAccount, {input}));
  return rawResponse.data.createAccount;
};

export const getSpecificAccount = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getAccount, {id}));
  return rawResponse.data.getAccount;
};

export const updateExistentAccount = async input => {
  const rawResponse = await API.graphql(graphqlOperation(updateAccount, {input}));
  return rawResponse.data.updateAccount;
};

export const listAccounts = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getMyAccounts, {ownerId: id}));
  return rawResponse.data.listAccounts.items;
};
