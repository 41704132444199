import React from 'react'
import { ContainerLog, LogSteps, LogSect, LogText } from './LogInto.style'
import LandingMenu from '../LandingMenu'

export default function LogInto() {
    return (
			<div id='acerca-de-inkom'>
				<ContainerLog>
					<LogText>
						<h1>ACERCA DE INKOM</h1>
					</LogText>
					<LogSteps>
						<LogSect>
							<img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-accede.svg"/>
							<div>
								<h3>Accede</h3>
								<p>Regístrate en sólo 5 minutos para la activación de tu cuenta.</p>
							</div>
						</LogSect>

						<LogSect>
							<img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-aprovecha.svg"/>
							<div>
								<h3>Aprovecha</h3>
								<p>Compra y obtén ahorro adicional o servicios con descuento.</p>
							</div>
						</LogSect>

						<LogSect>
							<img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-asegura.svg"/>
							<div>
								<h3>Asegura</h3>
								<p>Infórmate sobre las transferencias realizadas, el monto ahorrado y recomendaciones para un retiro digno.</p>
							</div>
						</LogSect>

						<LogSect>
							<img src="https://aurumresources.s3.amazonaws.com/NewStyles/icon-asegura.svg"/>
							<div>
								<h3>Ahorra</h3>
								<p>Elige como ahorrar a través de compras con tarjetas de crédito o débito, domiciliación o aportaciones únicas.</p>
							</div>
						</LogSect>

					</LogSteps>
				</ContainerLog>
			</div>
    )
}
