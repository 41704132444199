import { getAccountById } from '../../../API/Sync';
import { updateExistentAccount } from '../../API/accounts/accounts.api';

// this function will be used to update the account
export const updateAccountToToday = (account, id_user) => {
  return getAccountById(account.id_account, id_user)
  .then(async syncResponse => {
    const accountFetchedData = syncResponse.data.response[0];
    if(accountFetchedData) {
      let accountCopy = {...account}
      delete accountCopy.updatedAt
      const updatedAccount = await updateExistentAccount({
        ...accountCopy,
        balance: accountFetchedData.balance,
        number: accountFetchedData.number,
        lastFetch: Date.now().toString().slice(0,10)
      });
      return updatedAccount;
    };
    return;
  })
  .catch(err => {
    console.log({err})
    return {}
  })
};