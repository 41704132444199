import { API, graphqlOperation } from 'aws-amplify';
import { getMyContributions } from '../../../graphql/customqueries';
import { createContribution, updateContribution, deleteContribution } from '../../../graphql/mutations';
import { getContribution } from '../../../graphql/queries';

export const createNewContribution = async input => {
  const rawResponse = await API.graphql(graphqlOperation(createContribution, {input}));
  return rawResponse.data.createContribution;
};

export const getSpecificContribution = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getContribution, {id}));
  return rawResponse.data.getContribution;
};

export const updateExistentContribution = async input => {
  try {
    const rawResponse = await API.graphql(graphqlOperation(updateContribution, {input}));
    return rawResponse.data.updateContribution;
  } catch(error) {
    console.log({error})
  }
};

export const deleteUniqueContribution = async input => {
  const rawResponse = await API.graphql(graphqlOperation(deleteContribution, {input}));
  return rawResponse.data.deleteContribution;
};

export const listContributions = async id => {
  const rawResponse = await API.graphql(graphqlOperation(getMyContributions, {ownerId: id}));
  return rawResponse.data.listContributions.items;
};
