import React, {useEffect} from 'react'
import {WhyContainer, CardsContainer, CardOne, CardTwo, CardThree, CardFour} from './Why.style'

export default function Why() {
  useEffect(() => {
		window.scrollTo(0,200)
},[])
  return (
    <WhyContainer id='elige-inkom'>
      <div>
        <h1>POR QUÉ INKOM</h1>
      </div>
      <CardsContainer>
        <CardOne>
          <h2>Invierte sólo 5 minutos</h2>
          <p>Sólo registra tu nombre, correo electrónico, CURP y tarjeta(s) asociada(s).
            Luego da de alta el servicio de Paybook. Listo, comienza a construir tu futuro.</p>
        </CardOne>
        <CardTwo>
          <h2>Seguridad y privacidad de tu información</h2>
          <p>Contamos con un sistema que resguarda la privacidad de tus datos personales bajo los más altos estándares.
            La información de tus cuentas está respaldada por Paybook, PayPal y Conekta.</p>
        </CardTwo>
        <CardThree>
          <h2>Esquemas de ahorro inteligentes y flexibles</h2>
          <p>Elige el esquema de ahorro que mejor se adecúe a tus necesidades.
            Nuestro sistema de recomendaciones te da las mejores opciones para incrementar tu retiro y que tu bolsillo no lo sienta.</p>
        </CardThree>
        <CardFour>
          <h2>Beneficios adicionales</h2>
          <p>A través de nuestras alianzas estratégicas cuentas con beneficios adicionales como descuentos en servicios y compras, así como ahorro adicional.</p>
        </CardFour>
        </CardsContainer>
    </WhyContainer>
  )
}
