import styled from 'styled-components';
import { device } from '../../utilities/media_queries';
import {DarkBlue, Yellow } from '../../utilities/Colors';

//Still have to work on the sigup and login buttons styles


export const MainSignup = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  font-family: 'IBM Plex Sans', sans-serif;
  width: 90%;
  height: 100%;

  &>Button{
    margin: 25% 0;
    border-radius: 2px;
  }
  &>p{
    color:${DarkBlue}; 
    font-size:.7rem;
    text-align:left;
    margin: 1% 0;
    opacity:0.7;
    @media ${device.laptop} {
      font-size:.7rem;
    }
    @media ${device.laptopL} {
      font-size:1rem;
    }
  }

  .ant-spin-dot-item {
    background-color:${Yellow}
  }
  .ant-spin-lg .ant-spin-dot i{
    width:20px;
    height: 20px;
  }

  &>Input{
    box-shadow: 0px 4px 8px #2C27380A;
  }
  @media ${device.mobileS},
  ${device.mobileM},
  ${device.mobileL} {
    width: 100%;
    /* &>Input{
      margin:5% 0 15% 0;
    } */
    &>Button{
    margin: 10% 0;
    }
  }
  @media ${device.tablet} {
    /* &>Input{
      margin:20% 0;
    } */
    &>Button{
      margin: 30% 0 35% 0;
    }
  }
  @media ${device.laptop} {
    /* &>Input{
      margin:5% 0;
    } */
    &>Button{
      margin: 5% 0;
    }
  }
  @media ${device.laptopL} {
    /* &>Input{
      margin:9% 0;
    } */
    &>Button{
      margin: 5% 0;
    }
  }
  @media ${device.desktop} {
    
  }
`;

export const Error = styled.p`
  color: red;
  @media ${device.mobileS} {
    
  }
  @media ${device.tablet} {

  }
  @media ${device.laptop} {

  }
  @media ${device.desktop} {
    
  }
`;