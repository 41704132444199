const codes = {
  200: {
    type: 'success',
    name: 'Finish',
    description: 'La conexión se ha realizado correctamente. Se han extraído los datos',
    action: '',
  },
  201: {
    type: 'success',
    name: 'Pending',
    description: 'La conexión se ha realizado correctamente. Hemos extraído parcialmente la información, pero los datos se seguirán extrayendo en procesos de fondo.',
    action: '',
  },
  202: {
    type: 'success',
    name: 'NoTransactions',
    description: 'La conexión se ha realizado correctamente. Sin embargo, no se encontraron transacciones.',
    action: '',
  },
  203: {
    type: 'success',
    name: 'PartialTransactions',
    description: 'La conexión se ha realizado correctamente. Sin embargo, más de una cuenta no tiene transacciones.',
    action: '',
  },
  204: {
    type: 'success',
    name: 'Incomplete',
    description: 'La conexión se ha realizado correctamente. Sin embargo, los datos descargados están incompletos.',
    action: '',
  },
  206: {
    type: 'success',
    name: 'NoAccounts',
    description: 'La conexión se ha realizado correctamente. Sin embargo, no había cuentas vinculadas a las credenciales proporcionadas.',
    action: '',
  },
  401: {
    type: 'action_needed',
    name: 'Unauthorized',
    description: 'La institución no aceptó el valor de uno de los campos de autenticación dados. Verifique la información y vuelva a intentarlo.',
    action: '',
  },
  403: {
    type: 'action_needed',
    name: 'Invalidtoken',
    description: 'El valor del token introducido introducido no fue aceptado por la institución o se alcanzó el tiempo de vencimiento.',
    action: '',
  },
  405: {
    type: 'action_needed',
    name: 'Locked',
    description: 'La institución ha bloqueado la credencial proporcionada, comuníquese con su institución y asegúrese de que su conexión al sitio funcione correctamente antes de volver a intentarlo.',
    action: '',
  },
  406: {
    type: 'action_needed',
    name: 'Conflict',
    description: 'La institución no permite tener más de un usuario conectado. Si ha iniciado sesión en su institución utilizando otro dispositivo, asegúrese de cerrar la sesión antes de intentarlo de nuevo.',
    action: '',
  },
  408: {
    type: 'action_needed',
    name: 'UserAction',
    description: 'La institución requiere atención por parte del usuario. Le recomendamos que inicie sesión en la institución para confirmar que todo funciona correctamente. Tras la confirmación, inténtelo de nuevo.',
    action: '',
  },
  409: {
    type: 'action_needed',
    name: 'WrongSite',
    description: 'Las credenciales proporcionadas parecen ser válidas pero pertenecen a un sitio diferente de la misma organización.',
    action: '',
  },
};

export default codes;
