import styled from 'styled-components';
import { device } from '../utilities/media_queries';
import {Orange, Yellow, DarkBlue} from '../../components/utilities/Colors';

export const MainAuth = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  width: 80%;
  border-radius:5px;
  box-shadow: 5px 0px 5px 5px #D3D3D3;
  @media ${device.mobileS},
    ${device.mobileM},
    ${device.mobileL}{
    width: 100%;
  }
  @media ${device.tablet} {
    margin: 0;
  }
  @media ${device.laptop} {
    width: 80%;
    margin: 0;
  }
  @media ${device.desktop} {
    width: 80%;
    margin: 0;
  }
`;


export const AuthPart = styled.div`
  display: flex;
  width: 80%;
  margin-top:10%;
  height:25%;
  align-items: start;
  flex-direction: column;
  justify-content: center;

  &>div{
    height: 45%;
    text-align: left;
    align-content: flex-start;
    flex-direction: column;
    justify-content:space-between;

    &>a{
      color:${Yellow};
      font-size:.7rem;
      font-weight:600;
      @media ${device.laptop} {
      font-size:.7rem;
    }
    @media ${device.laptopL} {
      font-size:1rem;
    }
    }
    &>p{
      color:${DarkBlue};
      font-size:.7rem;
      margin: 0;
      @media ${device.laptop} {
      font-size:.7rem;
      }
      @media ${device.laptopL} {
        font-size:1rem;
      }
    }
  }

  &>h1{
    color:${DarkBlue};
    margin: 1% 0;
    font-weight:700;
    /* height: 55%; */
  }


  @media ${device.mobileS} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.desktop} {

  }
`;

export const AuthPartTwo = styled.div`
  height: 70%;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  color:${Yellow};
  text-align: left;
`

export const Par = styled.p`
  color: ${DarkBlue};
  font-size:.7rem;
  text-align:left;
  margin: 1% 0;
  opacity:0.7;
  @media ${device.laptop} {
    font-size:.7rem;
  }
  @media ${device.laptopL} {
    font-size:1rem;
  }
`;