import { Modal } from 'antd';

const alert = (props) => {
  const {type, setSuccesses= () => {}} = props;
  let title = '';
  let content = '';
  let action = setSuccesses;

  switch (type) {
    case 'codeConfirmation':
      title= 'Aún no has validado tu email';
      content= 'Hemos enviado un nuevo código a tu correo.';
      break;
    case 'userDoesNotExists':
      title= 'Usuario no registrado';
      content= 'Por favor regístrate para disfrutar de los beneficios que Inkom te ofrece';
      break;
    case 'passwordResetRequired':
      title= 'Tu contraseña expiró';
      content= 'Por favor, restablécela para continuar disfrutando de los beneficios que Inkom te ofrece';
      break;
    case 'passwordWrong':
      title= 'Intenta de nuevo';
      content= 'La combinación de correo y contraseña que nos proporcionas es incorrecta';
      break;
    case 'disabledUser':
      title= 'Lo sentimos';
      content= 'Tu cuenta se encuentra inhabilitada por favor mándanos un correo a contacto@inkom.mx para brindarte apoyo con esta situación';
      break;
    case 'incompleteData':
      title= 'Ingresa tus datos completos';
      content= 'Llena los campos de correo y contraseña.';
      break;
      case 'incompleteDataRecovery':
        title= 'Ingresa tu correo';
        break;
    case 'incompleteDataRecoveryCode':
      title= 'Ingresa tus datos completos';
      content= 'Llena los campos de correo y contraseña.';
      break;
    case 'noLoggedUser':
      title= 'Inicia sesión para poder aprovechar los beneficios que Inkom te ofrece.'
      break;
    case 'passwordRecovery':
      title= 'Hemos enviado un código de verificación a tu correo'
      break;
    case 'passwordRecoverySuccess':
      title= 'Felicidades'
      content= 'Has cambiado de forma exitosa tu contraseña'
      break;
    case 'passwordRecoveryFailedCode':
      title= 'Lo sentimos'
      content= 'El código que proporcionaste es incorrecto. Verifica esta información.'
      break;
    case 'passwordIsTooWeak':
      title= 'Contraseña muy débil'
      content= 'Asegúrate que ésta sea de al menos ocho dígitos, contenga una mayúscula, un número y un caracter especial.'
      break;
    case 'passwordsDontMatch':
      title= 'Asegúrate que las contraseñas coincidan'
      break;
    case 'tooManyAttempts':
      title= 'Demasiados intentos fallidos.'
      content='Inténtalo de nuevo en 15 minutos'
      break;
    case 'oneStepMissing':
      title= 'Acción no permitida'
      content='No puedes cambiar tu password sin antes haber confirmado tu correo. Para recuperar'
      break;
    default:
      title= 'Esto nos da mucha pena';
      content= 'Algo extraño ocurrió. Por favor, intenta ingresar de nuevo.';
      break;
  };
  Modal.warning({
    title,
    content,
    onOk() {
      action({ spin: true })
      Modal.destroyAll();
    },
    onCancel() {
      action({ spin: true })
      Modal.destroyAll();
    }
  });
};

export default alert;
