import { AppActionTypes } from './app.types';

const INITIAL_STATE = {
  isLoading: false
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case AppActionTypes.SET_CURRENT_LOAD_STATE_TO_TRUE:
      return {
        ...state,
        isLoading: action.payload
      };
    case AppActionTypes.SET_CURRENT_LOAD_STATE_TO_FALSE:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state;
  }
};

export default appReducer;