import React, { useState, useEffect } from 'react';
import { AccountContainer, MainAccountStatement } from './PaymentDetails.style';

const PaymentDetails = ({ data }) => {
  const [ paymentProviderResponse, setPaymentProviderResponse ] = useState(null);
  const [ paymentDateAndTime, setPaymentDateAndTime ] = useState(null);
  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  useEffect(() => {
    setPaymentProviderResponse(JSON.parse(data.paymentData.paymentProviderResponse))
  }, []);

  useEffect(() => {
    if (paymentProviderResponse) {
      const dateDateParsed = handleDateAndTime(paymentProviderResponse.date, paymentProviderResponse.time);
      setPaymentDateAndTime(dateDateParsed);
    }
  }, [paymentProviderResponse]);

  const handleDateAndTime = (date, time) => {
    let dateParsed = date.split('/').map(element => Number(element));
    let timeParsed = time.split(':').map(element => Number(element));
    const allDate = new Date(dateParsed[2], dateParsed[1] - 1, dateParsed[0], timeParsed[2], timeParsed[1], timeParsed[0]);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'America/Mexico_City' };

    return allDate.toLocaleDateString('es-MX', options)
  };


// cc_mask: "553253XXXXXX0777"
// cc_number: "0777"
// cc_type: "CREDITO/SANTANDER/MasterCard"
// cd_error: ""
// cd_response: "00"
// date: "05/01/2022"
// foliocpagos: "501953700"
// id_url: "9T5RNDHX"
// mitOperationId: "4f7d119b-2da9-4a1e-b2f1-7eabb25f75b6"
// nb_error: ""
// time: "21:49:46"

  return (
    <MainAccountStatement>
      <br></br>
      <AccountContainer>
        <div>
          {/* <div style={{width: "100%"}} ><span ></span></div> */}
          {
            paymentProviderResponse && (
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{width: 'auto'}}>Folio de pago</span>
                <span>{paymentProviderResponse.foliocpagos}</span>
              </div>
            )
          }
          {/* <div style={{width: "100%"}} ><span ></span></div> */}
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{width: 'auto'}}>Monto a ahorrar correspondiente a gastos</span>
            <span>{formatter.format(data.paymentData.amount)}</span>
          </div>
          {/* <div style={{width: "100%"}} ><span ></span></div> */}
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{width: 'auto'}}>Método de pago</span>
            {
              paymentProviderResponse && (
                <span>{paymentProviderResponse.cc_mask.match(/.{1,4}/g).join(' ')}</span>
              )
            }
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{width: 'auto'}}></span>
            {
              paymentProviderResponse && (
                <span>{paymentProviderResponse.cc_type}</span>
              )
            }
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{width: 'auto'}}>Fecha de pago</span>
            <span>{paymentDateAndTime}</span>
          </div>
        </div>
        <br></br>
        <div>
          <div style={{width: "100%", display: 'flex', justifyContent: 'space-between'}}>
            <span style={{width: 'auto'}}><b>Monto realmente ahorrado</b></span>
            <span><b>{formatter.format(data.paymentData.amountPayed)}</b></span>
          </div>
        </div>
      </AccountContainer>
    </MainAccountStatement>
  )
}

export default PaymentDetails;