import React, { useState, useEffect } from 'react';
import {Line} from 'react-chartjs-2';
import { DarkBlue, Yellow2 } from '../../utilities';
import { DashContainer, DashTypes, DashDescription, DashControl } from './DashChart.style';


const DashChart = (props) => {
	const [ageArray, setAgeArray] = useState([]);
	const [pensionData, setPensionData] = useState({});
	const [fondoData, setFondoData] = useState({});
	const [graphKind, setGraphKind] = useState(1);
	let data, options;

	const handleGraphType = graph => {
		setGraphKind(graph);
	};

	const managePensionData = (act, recom) => {
		let pensionAgeArray = [];
		let pensionActQtyArray = [];
		let pensionRecomQtyArray = [];
		for(const key in act) {
			pensionAgeArray.push(key);
			pensionActQtyArray.push(act[key].toFixed(2));
		};

		for(const key in recom) {
			pensionRecomQtyArray.push(recom[key].toFixed(2));
		};

		setAgeArray(prevState => pensionAgeArray);
		setPensionData(prevState => (
			{
				...prevState,
				act: pensionActQtyArray,
				recom: pensionRecomQtyArray
			}));
	};

	const manageFondoData = (act, recom) => {
		let fondoActQtyArray = [];
		let fondoRecomQtyArray = [];
		for(const key in act) {
			fondoActQtyArray.push(act[key].toFixed(2));
		};

		for(const key in recom) {
			fondoRecomQtyArray.push(recom[key].toFixed(2));
		};
		setFondoData(prevState => (
			{
				...prevState,
				act: fondoActQtyArray,
				recom: fondoRecomQtyArray
			}));
	};

	useEffect(() => {
		const pension_act = props.recommendation?.INCREMENTO_pension_BEE_act;
		const pension_recom = props.recommendation?.INCREMENTO_pension_BEE_recom;
		const fondo_act = props.recommendation?.INCREMENTO_fondo_BEE_act;
		const fondo_recom = props.recommendation?.INCREMENTO_fondo_BEE_recom;
		managePensionData(pension_act, pension_recom);
		manageFondoData(fondo_act, fondo_recom);
	}, [props.recommendation]);

	useEffect(() => {
	}, [graphKind, ageArray, pensionData, fondoData]);

	data = {
		datasets: [
			{
				label: 'Fondo con Recomendación',
				fill: true,
				backgroundColor: Yellow2,
				fontFamily:'IBM Plex Sans',
				data: graphKind == "1" ? fondoData.recom : pensionData.recom ? pensionData.recom : []
			},
			{
				label: 'Fondo proyectado',
				fill: true,
				backgroundColor: DarkBlue,
				fontFamily:'IBM Plex Sans',
				data: graphKind == "1" ? fondoData.act : pensionData.act ? pensionData.act : []
			},
		]
	};

	options = {
		responsive: true,
		scaleLabel: {
			function(label){return  '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");},
		},
		scales: {
			x: {
				display: true,
				gridLines: {
					display: false
				},
			},
			y: {
				display: true,
			},
			xAxes: [
				{
					display: true,
					gridLines: {
						display: false
					},
					labels: ageArray ? ageArray : [],
						scaleLabel: {
							display: true,
							labelString: 'Edad',
							fontColor:DarkBlue,
							fontSize:"15",
							fontFamily:'IBM Plex Sans',
							lineHeight:"1",
						},
					ticks: {
						fontSize:"11",
					}
				}
			],
			yAxes: [{
				ticks: {
					fontSize:"11",
					userCallback: function(value, index, values) {
						// Convert the number to a string and splite the string every 3 charaters from the end
						value = value.toString();
						value = value.split(/(?=(?:...)*$)/);

						// Convert the array to a string and format the output
						value = value.join(',');
						return '$ ' + value + ".00";
				}
				},
				type: 'linear',
				display: true,
				position: 'left',
				id: 'y-axis-1',
				gridLines: {
					display: false
				},
				labels: {
					show: true

				},
				scaleLabel: {
					display: true,
					labelString: 'Cantidad',
					fontColor:DarkBlue,
					fontSize:"15",
					fontWeight:700,
					fontFamily:'IBM Plex Sans',
					lineHeight:"1",
					maxRotation: 90,
					minRotation: 90,
					autoSkip: false,
				}
			},
			{
				type: 'linear',
				display: false,
				position: 'right',
				id: 'y-axis-2',
				gridLines: {
					display: false
				},
			}]
		}
	};

return(
	<DashContainer>
		<DashTypes>
			<DashControl onClick={() => handleGraphType(1)} selected={graphKind == 1}>SALDO TOTAL</DashControl>
			<DashControl onClick={() => handleGraphType(2)} selected={graphKind == 2}>MENSUALIDAD</DashControl>
		</DashTypes>
		<Line data={data} options={options}/>
	</DashContainer>
	)
};

export default DashChart;


