import styled from 'styled-components';
import { DarkBlue, Green, BabyBlue, BabyYellow, Black, GrayLight2, Yellow, White} from '../../../utilities/Colors'
import { device } from '../../../utilities/media_queries';

export const InformationRec = styled.div`
  width: 96%;
  display: flex;
  flex-direction: column;
  margin: 0 2%;
  font-family: 'IBM Plex Sans', sans-serif;
  &>h1{
    color:${DarkBlue};
    text-align: left;
    font-size: 3rem;
    font-weight:700;
    margin: 3% 0;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
      font-size:1.6rem;
      margin: 0 2%;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL},{
      font-size:3rem;
      margin: 0;
    }
  }
`
export const ContainerDashRec = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  width: 100%;
  padding: 1% 0;
  &>h2{
    border-left: 5px solid ${Yellow};
    padding-left: 2%;
    color:${DarkBlue};
    text-align: left;
    width: 90%;
    font-weight: 700;
    font-Size: 1.7rem;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
        margin:5% 0;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL} {
        margin:2% 0;
    }
  }
  &>p{
    text-align: center;
    margin: 2% 0;
    color:${DarkBlue};
  }
`

export const Actual = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
  width: 80%;
  padding: 2%;
  justify-content: flex-start;
  background-color: ${White};
  border-radius:15px;
  box-shadow:5px 10px 5px #2C273814;
@media ${device.mobileS},
  ${device.mobileM},
  ${device.mobileL} {
  font-size:.9rem;
  margin: 0;
  width: 100%;
}
@media ${device.tablet},
  ${device.laptop},
  ${device.laptopL},{
  font-size:1.2rem;
  margin: 0;
  width: 80%;
}
  &>p{
    color:${Black};
    text-align:left;
    width: 90%;
  }
  &>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL} {
      margin: 3% 0%;
      padding: 5% 0;
    }
    @media ${device.tablet},
      ${device.laptop},
      ${device.laptopL} {
      margin: 1% 0;
      padding: 0;
    }
    &>button{
      background-color: white;
      border-radius: 5px;
      border:1px solid ${GrayLight2};
      color:${DarkBlue};
      cursor: pointer;
      font-size:.8rem;
      height: auto;
      min-width: 20%;
      transition:.5s ease-in;
        @media ${device.mobileS},
          ${device.mobileM},
          ${device.mobileL} {
          width: 50%;
          margin: 1% 25%;
          padding: 1% 0;
        }
        @media ${device.tablet},
          ${device.laptop},
          ${device.laptopL},{
          width: 20%;
          margin: 0;
          padding: 1% 0;
        }
      }
      &>button:hover{
        background-color: ${Yellow};
        border-radius: none;
        color:white;
        border:1px solid ${DarkBlue};
        transition:.5s ease-out;
      }
    .ant-slider{
      width:60%;
      padding-right: 10%;
      margin: 5% 2%;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL} {
        width: 80%;
        margin: 1% 10%;
      }
      @media ${device.tablet},
        ${device.laptop},
        ${device.laptopL},{
          width:60%;
        }
      }
    }
`

export const Compare = styled.div`
  display: flex;
  flex-direction: row;
  width:80%;
  background-color: ${White};
  border-radius: 15px;
  box-shadow:5px 10px 5px #2C273814;
  padding: 1%;
  margin: 1% 0 2% 0;
    @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL},
        ${device.tablet}{
        width: 100%;
      }
    @media ${device.laptop},
    ${device.laptopL},{
      width:80%;
    }
  &>div{
    width: 30%;
    margin:1% 1.5%;
    text-align: center;
    &>h3{
      color:${DarkBlue};
      font-weight: 900;
      font-size: 1.5rem;
    }
    &>p{
      color:${DarkBlue};
      font-weight: 300;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL},
        ${device.tablet}{
        font-size: .7rem;
      }
      @media ${device.laptop},
        ${device.laptopL},{
        font-size: .8rem;
      }
    }
    &>h4{
      color:${DarkBlue};
      font-size: 1rem;
      font-weight: 300;
      @media ${device.mobileS},
        ${device.mobileM},
        ${device.mobileL},
        ${device.tablet}{
        font-size: .8rem;
      }
      @media ${device.laptop},
        ${device.laptopL},{
        width: 80%;
      }
    }
  }
  &>div:nth-child(1){
    background-color:${Green};
  }
  &>div:nth-child(2){
    background-color:${BabyBlue};
  }
  &>div:nth-child(3){
  background-color:${BabyYellow};
  }
`

export const Suggest = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1% 0 2% 0;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL},
      ${device.tablet}{
      width: 100%;
      }
    @media ${device.laptop},
      ${device.laptopL}{
      width:80%;
    }
  &>div{
    display: flex;
    flex-direction: column;
    background-color:${White};
    width: 24%;
    border-radius: 15px;
    padding: 2% 1%;
    box-shadow:5px 10px 5px #2C273814;
    text-align: center;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL},
      ${device.tablet} {
        width: 46%;
        margin: 2%;
    }
    @media 
      ${device.laptop},
      ${device.laptopL},{
        width: 24%;
        margin: 0;
    }
    &>h3{
      color: ${DarkBlue};
      font-Size: 1.5rem;
    }
    &>p{
      font-size:.9rem;
      color:${DarkBlue};
      padding: 0 1%;
    }
  }
`

export const Benefit = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 1% 0 2% 0;
  padding: 0;
  height: auto;
  width:90%;
  background-color: ${White};
  border-radius: 15px;
  box-shadow:5px 10px 5px #2C273814;
    @media ${device.mobileS},
      ${device.mobileM},
      ${device.mobileL},
      ${device.tablet}{
        width: 100%;
        margin: 2% 0;
    }
    @media ${device.laptop},
      ${device.laptopL},{
        width: 80%;
        font-Size: 1.2rem;
        margin: 2% 0;
    }
    @media ${device.desktop} {
      font-Size: 1.6rem;
    }
`