import React, { useState, useEffect } from 'react';
import { Radio, Input, Tooltip, Select, Spin, notification, message, InputNumber} from 'antd';
import { TraditionalGeneral, SectionOne, SectionTwo, PeriodSection, DataSection,HeadTraditional } from './TraditionalSaving.style';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userSelector, updateCurrentUser } from '../../../../store/user/user.slice';
import { getContributionById, uniqueScheduledContribution } from '../../../../store/contributions/contributions.slice';
import { createContribution, updateContribution } from '../../../../store/contributions/contributions.slice';
import { Modal } from 'antd';

import Button from '../../../UI/Button';
import { generateEncryptedPaymentLink } from '../../../../API/MIT';
import { setAppLoading, setAppNotLoading } from '../../../../store/app/app.actions';
import UpdateData from '../../../layout/UpdateData';
import { createMitOperation, updateMitOperation } from '../../../../store/mitOperations/mitOperations.slice';

const  TraditionalSaving = ({ kind, setHasBeenUpdated, handleOk, onCancel, setAppIsLoading, setAppIsNotLoading }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const scheduledContribution = useSelector(uniqueScheduledContribution);

  const [ isLoading, setIsLoading ] = useState(false);
  const [scheduled, setScheduled] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModalLink, setPaymentModalLink] = useState(null);
  const [lastContributionCreated, setLastContributionCreated] = useState(null);
  const [phoneProvidedData, setPhoneProvidedData] = useState({
    isValid: false,
    value: null
  });
  const [notModified, setNotModified] = useState({
    ammount: true,
    periodicity: true,
    goal: true
  });
  const [contributionForm, setContributionForm] = useState({
    ammount: "",
    periodicity: "",
    goal: ""
  });

  const { Option } = Select;

  useEffect(() => {
    handleScheduled(() => {
      return kind === 'direct_debit' ? true : false;
    });
  }, [kind]);

  useEffect(() => {
    console.log('Payment created')
  }, [paymentModal, paymentModalLink])

  const close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
    onCancel()
    handleOk()
  };


  const openNotification = (contributionData) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button onClick={() => notification.close(key)}>
        Entendido
      </Button>
    );
    notification.open({
      message: 'Contribución única creada',
      description:
        `Tu aportación única por la cantidad de ${toCurrency(contributionData)} fue creada con éxito. Ve a la pestaña de tu ahorro para visualizarla.`,
      btn,
      key,
      onClose: close,
    });
  };

  const handleContributionChange = (e, field) => {
    let contFormCopy = contributionForm;
    if(!e.target){
      setNotModified(prevState => ({...prevState, [field]: false}));
      setContributionForm({...contributionForm, [field]: e});
    } else {
      if(e.target.name=== "ammount") {
        e.persist()
      }
      setNotModified(prevState => ({...prevState, [e.target.name]: false}));
      contFormCopy[e.target.name] = e.target.value;
      setContributionForm(prevState => ({...prevState, ...contFormCopy}));
    };
  };

  const handleScheduled = isScheduled => {
    setScheduled(isScheduled);
  };

  const updateUniqueContribution = () => {
    let input = {
      id: scheduledContribution.id,
      goal: contributionForm.goal ? contributionForm.goal : scheduledContribution.goal,
      scheduled: scheduled,
      periodicity: 'Mensual',
      referenceDate: {
        kind: (new Date()).toISOString(),
        dateData: (new Date()).toISOString(),
      },
      ammount: contributionForm.ammount ? Number(contributionForm.ammount) : Number(scheduledContribution.ammount),
      paymentMedium: contributionForm.paymentMedium,
      type: "traditional"
    };
    try {
      dispatch(updateContribution(input));
      setHasBeenUpdated(prevState => !prevState)
      onCancel()
      handleOk()
    } catch(error) {
      console.log({error})
      message.error('Ocurrió un error, vuelve a intentarlo.');
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    let phoneData = phoneNumber.split(' ').join('')
    if(phoneData.length === 10) {
      return Number(phoneData)
    };
    phoneData = phoneData.slice(phoneData.length - 10);
    return Number(phoneData)
  };

  const registerContribution = async () => {
    setIsLoading(true);
    let input = {
      goal: contributionForm.goal,
      scheduled: scheduled,
      periodicity: 'Mensual',
      referenceDate: {
        kind: (new Date()).toISOString(),
        dateData: (new Date()).toISOString(),
      },
      ammount: contributionForm.ammount,
      paymentMedium: contributionForm.paymentMedium,
      type: "traditional",
      ownerId: user.id,
    };
    try {
      let createdContribution = await dispatch(createContribution(input));
      setIsLoading(false);
      if(!scheduled) {
        setLastContributionCreated(createdContribution.payload)
        startPaymentFlow(createdContribution.payload);
      }
    } catch(error) {
      setIsLoading(false);
      onCancel()
      console.log({error});
    }
  };

  const startPaymentFlow = async (contribution) => {
    setAppIsLoading();
    if (!user.phone) {
      const input = {
        id: user.id,
        phone: formatPhoneNumber(phoneProvidedData.value)
      }
      try {
        await dispatch(updateCurrentUser(input));
        message.success('Información actualizada exitosamente.');
        handleOk();
      }catch(error) {
        console.log({errorWhileUpdatingUsersPhone: error});
        // message.error('No pudimos registrar tu teléfono en tu perfil, pero no te preocupes, tu pago continua.');
      }
    }
    try {
      let mitOperationInput = {
        ownerId: user.id,
        contributionId: contribution.id,
        paymentId: null,
        mitOperationStatus: '1',
        reference: contribution.id.replaceAll('-', '')
      }
      let createdMitOperation = await dispatch(createMitOperation({...mitOperationInput}))
      const linkResponse = await generateEncryptedPaymentLink({
        ...contribution,
        mitOperationId: createdMitOperation.payload.id,
        amount: Number(contributionForm.ammount).toFixed(2),
        mail: user.email,
        phone: user.phone ? user.phone : formatPhoneNumber(phoneProvidedData.value),
        statement: {period: 'AportacionUnica'},
        userId: user.id
      });
      setAppIsNotLoading();
      await dispatch(updateContribution({
        id: contribution.id,
        mitLink: linkResponse.url
      }))
      await dispatch(updateMitOperation({
        id: createdMitOperation.payload.id,
        rawDataRequest: linkResponse.rawDataRequest
      }))
      setPaymentModalLink(prevState => linkResponse.url);
      openPaymentModal(true);
    } catch(error) {
      console.log({errorWhileMakingMITPayment: error});
      setAppIsNotLoading();
    }
  }

  const openPaymentModal = async (status) => {
    if(!status) {
      if(lastContributionCreated.id) {
        await dispatch(getContributionById(lastContributionCreated.id))
      }
      onCancel();
      handleOk();
    }
    setPaymentModal(prevState => status);
  }

  const toCurrency = integer => {
    return `$ ${integer}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return (
    <TraditionalGeneral>
    {
        paymentModal && paymentModalLink ?
        <Modal
          title="Pago"
          visible={paymentModal && paymentModalLink}
          bodyStyle={{height: '70vh'}}
          footer={[]}
          onCancel={() => openPaymentModal(false)}
        >
          <iframe title="MIT" src={paymentModalLink} frameBorder="0" seamless="seamless" style={{width:'100%',height:'100%'}}/>
        </Modal> : null
      }
      <SectionOne>
        <h3 style={{width: "80%", margin: '2% auto'}}>Tipo de aportación a programar.</h3>
          <div>
          <HeadTraditional>
          {
            scheduled ?
              <h2>Domiciliación
                <Tooltip title="Montos fijos que ahorrarás de forma mensual.">
                  <QuestionCircleOutlined style={{margin:"0 2%"}}/>
                </Tooltip>
              </h2> :
              <h2>Aportación única
                <Tooltip title="Montos únicos que puedes agregar a tu cuenta de ahorro.">
                  <QuestionCircleOutlined style={{margin:"0 2%"}}/>
                </Tooltip>
              </h2>
          }
          </HeadTraditional>
        </div>
        {/* <Radio.Group style={{ display: "flex", flexDirection: "row", width:"100%", flexWrap: "wrap"}} name="periodicitySchema" onChange={handleScheduled}>
          <Radio style={{width:"96%", margin:"2%"}} value={true}>
            <h2>Domiciliación
              <Tooltip title="Montos fijos a la quincena o mes que ahorrarás.">
                <QuestionCircleOutlined style={{margin:"0 2%"}}/>
              </Tooltip>
            </h2>
          </Radio>
          <Radio style={{width:"96%", margin:" 2%"}} value={false}>
            <h2>Aportación única
              <Tooltip title="Montos únicos que puedes agregar a tu cuenta de ahorro.">
                <QuestionCircleOutlined style={{margin:"0 2%"}}/>
              </Tooltip>
            </h2>
          </Radio>
        </Radio.Group> */}
      </SectionOne>
      <SectionTwo>
        <DataSection>
          <div >
            <h3>Monto</h3>
            <InputNumber
              style={{width: '100%'}}
              placeholder="$0.00"
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              value={scheduled && scheduledContribution && notModified.ammount ? scheduledContribution.ammount : contributionForm.ammount}
              name="ammount"
              onChange={e => handleContributionChange(e, "ammount")}
            />
          </div>
        </DataSection>
        <PeriodSection>
          <h2>Retira tu ahorro
            <Tooltip title="Puedes elegir cuando podrás disponer de tu ahorro de acuerdo a tus necesidades.">
              <QuestionCircleOutlined style={{margin:"0 2%"}}/>
            </Tooltip>
          </h2>
          <Radio.Group
            style={{
              display: "flex",
              width: "80%",
              fontFamily:"Open Sans Condensed",
              margin:"5% 0",
              textAlign:"center"
            }}
            name="goal"
            value={scheduled && scheduledContribution && notModified.goal ? scheduledContribution.goal : contributionForm.goal}
            onChange={e=> handleContributionChange(e, "goal")}
          >
            <Radio style={{margin:"0 3%", width: "30%"}} value="corto">
              <p style={{fontFamily: 'IBM Plex Sans',fontSize: "1.1rem"}}>Corto</p>
            </Radio>
            <Radio style={{margin:"0 3%", width: "30%"}} value="largo">
              <p style={{fontFamily: 'IBM Plex Sans', fontSize: "1.1rem"}}>Largo</p>
            </Radio>
            <Radio style={{margin:"0 3%", width: "30%"}} value="retiro">
              <p style={{fontFamily: 'IBM Plex Sans',fontSize: "1.1rem"}}>Al retiro</p>
            </Radio>
          </Radio.Group>
        </PeriodSection>
        {
          !user.phone && <UpdateData setPhoneProvidedData={setPhoneProvidedData}/>
        }
        {
          isLoading ?
          <Spin style={{color:"#EF6F02"}} size="large" hidden={!isLoading}/> :
          <Button
            disabled={
              (!scheduled && !scheduledContribution) &&
              (!contributionForm.ammount ||
              !contributionForm.goal ||
              (!user.phone && !phoneProvidedData.isValid))
            }
            onClick={
              scheduled && scheduledContribution ?
              updateUniqueContribution :
              registerContribution
            }
          >{
            scheduled && scheduledContribution ?
            "Editar aportación domiciliada" :
            "Registrar Aportación"
          }</Button>
        }
      </SectionTwo>
    </TraditionalGeneral>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    setAppIsLoading: () => dispatch(setAppLoading()),
    setAppIsNotLoading: () => dispatch(setAppNotLoading()),
  }
};

export default connect(null, mapDispatchToProps)(TraditionalSaving);
